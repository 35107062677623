.Duration {
    display: flex;
    flex-wrap: wrap;
}

.DurationLabel {
    display: flex;
    align-items: center;
    margin-bottom: 2px;

    input {
        width: 48px;
        padding: 0;
        text-align: center;
    }
    span {
        margin: 0px 10px 0px 5px;
    }
}