@import "../../../basics-scss/mixins";

.loader {
  &.masterReport {
    height: calc(100% - 153px);
  }

  .preloader {
    background: url("/assets/img/page-loader.gif") no-repeat center;
    width: 64px;
    background-size: 64px 64px;
    height: 64px;
  }
}

.commonLoader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.headerText {
  font-family: $fontFamilyHeadline;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: #6E728F;
  margin-top: 30px;
}

.loadingText {
  font-family: $fontFamilyMain;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #6E728F;
  margin-top: 10px;
  max-width: 282px;
}

.defaultLoadingText {
  font-family: $fontFamilyHeadline;
  text-align: center;
  font-size: 28px;
  line-height: 34px;
  color: #cacfdd;
  max-width: 500px;
}
