@import "../../../../basics-scss/mixins";

.row {
  @include clearFix;
  margin: 0 -8px;
}

.col {
  float: left;
  width: 100%;
  padding: 0 8px;

  @include forMedia(md) {
    width: 100%!important;
  }
}

.field {

}

.title{
  margin: 32px 0 24px 0;
  font-weight: 600;
  color: #334D6E;
}