@import "../../../basics-scss/mixins";

.Container {
    @include clearFix;
    box-sizing: content-box;
    @include forMedia(xs){
        padding: 0 16px;
    }
}

.Tree {
    float: left;
    width: 25%;
    padding-right: 12px;

    @include forMedia(md){
        padding-right: 8px;
    }

    @include forMedia(sm){
        width: auto;
        display: none;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: $zIndexModal;
        background: $colorNeutral000;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        padding: 0 16px;

        &.active {
            display: block;
        }
    }
}

.WrapLoader {
    max-width: 66.6%;

    @include forMedia(sm){
        max-width: none;
    }
}

.Results {
    float: left;
    width: 75%;
    padding-left: 12px;

    @include forMedia(md){
        padding-left: 8px;
    }

    @include forMedia(sm){
        padding: 0;
        width: 100%;
    }
}

.Cards {
    @include forMedia(xs){
        margin: 0 -16px;
    }
}

.Breadcrumbs {
    font-size: 12px;
    font-family: $fontFamilyMain;
    line-height: 16px;
    text-transform: uppercase;
    color: #334D6E;
}

.Breadcrumb {
    display: inline-block;
    vertical-align: top;
}

.BreadcrumbIcon {
    display: inline-block;
    vertical-align: top;
    margin: 0 8px;
    @include svgIcon(16px, 16px, #6E728F);
}

.Heading {
    max-width: 66.6%;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    @include forMedia(sm){
        padding: 24px 0 0;
    }

    @include forMedia(sm){
        max-width: none;
    }
}

.SearchIcon {
    margin-right: 8px;
    @include svgIcon(24px, 24px, $colorNeutral800);
    cursor: pointer;
    flex: 0 0 24px;
}

.NoResults {
    clear: both;
    height: 80vh;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    font-family: $fontFamilyMain;
    font-size: $fontSizeLabel;
    line-height: $lineHeightLabel;
    color: $colorNeutral600;
    max-width: 66.6%;

    @include forMedia(sm){
        max-width: 100%;
    }


    .NoResultsIcon {
        @include svgIcon(56px, 56px, $colorNeutral090);
        margin-bottom: 16px;
    }

    .NoResultsText {
        margin-bottom: 8px;
        font-weight: 600;
    }
}

.MoreLoader {
    height: 56px;
    background: url("/assets/img/page-loader.gif") no-repeat center;
    background-size: 56px 56px;
}

.Footer {
    margin: 24px 0;
    text-align: center;

    .FooterEndText {
        overflow: hidden;
        position: relative;
        font-size: $fontSizeBody;
        line-height: $lineHeightBody;
        color: $colorNeutral090;
        white-space: nowrap;

        &:before,
        &:after {
            content: '';
            display: inline-block;
            position: relative;
            top: -1px;
            width: 50%;
            height: 1px;
            vertical-align: middle;
            background: $colorNeutral080;
        }

        &:before {
            left: -8px;
            margin-left: -50%;
        }
        &:after {
            left: 8px;
            margin-right: -50%;
        }
    }
}

.MoreBtn {
    @include forMedia(sm){
        width: 100%;
    }
}

.Kebab {
    @include svgIcon(24px,24px,$colorNeutral800);
    flex: 0 0 24px;
    margin-left: 8px;
    display: none;
    cursor: pointer;

    @include forMedia(sm){
        display: block;
    }
}

.TreeHeader {
    border-bottom: 1px solid $colorNeutral080;
    padding: 12px 16px;
    align-items: center;
    display: none;
    margin: 0 -16px 16px;

    @include forMedia(sm){
        display: flex;
    }
}

.TreeClose {
    margin-left: auto;
}

.Freeze {
    overflow: hidden;
}

.TreeHeading {
    font-family: $fontFamilyMain;
    color: $colorNeutral600;
    font-size: $fontSizeTitle;
    line-height: $lineHeightTitle;
    font-weight: 600;
    text-transform: uppercase;
}

.Skeleton {
    overflow: hidden;
    position: relative;
    max-height: 90vh;
    margin-top: 10px;
}

.SkeletonBlank {
    border-radius: 4px;
    background: $colorNeutral077;
}

.SkeletonActCont {
    margin: 0 0 0 16px;

}

.SkeletonActInf {
    @extend .SkeletonBlank;
    height: 20px;
    margin-bottom: 20px;

    display: block;
    transition: all 200ms ease-in;

    &.SkeletonActEl0, &.SkeletonActEl3 {
        max-width: 168px;
    }

    &.SkeletonActEl1 {
        max-width: 112px;
    }

    &.SkeletonActEl2, &.SkeletonActEl4 {
        max-width: 80px;
    }
}

.SkeletonHide {
    display: none;
}

.progressBar {
    width: 100%;
    height: 4px;
    margin-bottom: 2px;

    .hideBar {
        transition: opacity 0.2s 300ms ease;
        opacity: 0;
        display: none;
    }

    .progressBarLine {
        height: 4px;
        background: $colorBlue300;
        transition: all 200ms ease-in;
    }

    @include forMedia(sm){
        width: auto;
        margin: -16px 0 12px -16px;
    }
}
