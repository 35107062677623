@import "../../../../../basics-scss/mixins";

.color {
    display: flex;
    position: relative;
    flex: 1;
}

.colorInput {
    flex: auto;
}

.colorButton {
    flex: 0 0 32px;
    margin-left: 4px;
}

.colorFrame {
    border: 1px solid $colorNeutral080;
    border-radius: 4px;
    width: 24px;
    height: 24px;
}

.colorPicker {
    box-shadow: $boxShadowCard!important;
    border: none!important;
}