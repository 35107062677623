@import "/basics-scss/mixins";

.menuItem {
    padding: 4px 12px 5px;
    cursor: pointer;
    color: $colorNeutral800;
    &.hover {
        background-color: $colorNeutral070;
    }
}
.menu {
    @include dropDown;
    @include customScrollbar;
    max-height: 250px;
    overflow-y: auto;
    padding: 8px 0;
}
.Choice {
    flex: 0 1 220px;
    margin-right: 4px;
    position: relative;
    .container {
        display: flex;
        .input {
            flex: auto;
            border-radius: 4px 0 0 4px;
            input {
                border-radius: 4px 0 0 4px;
                &:hover {
                    z-index: $zIndexLow;
                }
                &:focus {
                    z-index: $zIndexMiddle;
                }
            }
        }
        .button {
            flex: 0 0 32px;
            margin-left: -1px;
            border-radius: 0 0 0 0;
            position: relative;
            &:last-child {
                border-radius: 0 4px 4px 0;
            }
            &:hover {
                z-index: $zIndexLow;
            }
            &:focus {
                z-index: $zIndexMiddle;
            }
        }
        .icon {
            @include svgIcon(16px,16px);
        }
    }

}

.MobileChoice {
    margin: 0 0 16px;
}
.MobileSave {
    width: 100%;
}