@import "../../../../../basics-scss/mixins";

.HTMLInput {
    flex: 1;
    textarea {
        width: 100%;
        height: 100px;
        padding: 5px 8px;
        line-height: $lineHeightBody;
        resize: vertical;
        font-size: $fontSizeInput;
        color: $colorNeutral800;
        background-color: $colorNeutral010;
        background-image: none;
        border: 1px solid $colorNeutral080;
        border-radius: 4px;
        &[readonly] {
            @include readonlyInputStyles;
        }
    }
}
.frame {
    background: $colorNeutral000;
    padding: 6px 12px;
    line-height: $lineHeightBody;
    border: solid 1px $colorNeutral080;
    height: 320px;
    overflow-y: scroll;
    border-radius: 4px;
    @include customScrollbar;
}

.readOnly {
    @include readonlyInputStyles;
    em {
        font-style: italic;
    };
    s {
        text-decoration: line-through;
    };
}
