@import "../../basics-scss/mixins";

.Modal {
    @include animateFadeIn($transDurationPopup);
    z-index: $zIndexModal;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    // пожалуйста, никаких стилей отвечающих за внешний вид. этот компонент - только обертка
}

.Freeze {
    overflow: hidden;
}