@import "../../basics-scss/mixins";

.RelatedLists {
    margin: 40px 0 15px;

    .head {
        display: flex;
    }

    .TabsHeadItem {
        border: 1px solid #ccc;
        border-radius: 5px 5px 0 0;
        height: 40px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover {
            background: #ccc;
        }
    }

    .TabsHeadItemActive {
        @extend .TabsHeadItem;
        background: #aaa;
    }

    .TabsItem {
        //min-height: 294px;
        padding-bottom: 0;
    }
}
.bgWhite {
    background: $colorNeutral000!important;
}
.tabLoader {
    height: 260px;
    background: url("/assets/img/page-loader.gif") no-repeat center;
    background-size: 64px 64px;
}