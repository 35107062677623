@import "../../../../../basics-scss/mixins";

.Input {
    flex: 1;

    &.warningInput {
        button{
            border-color: $colorRed300;
        }
    }
}
