.field {
    margin-bottom: 20px;
}

.content {
    overflow: hidden;
    h2 {
        margin-top: 0;
    }
}

.filters {
    float: left;
    width: 180px;
}

.charts {
    margin-left: 200px;
}


