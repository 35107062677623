@import "../../../basics-scss/mixins";

.content {
    min-width: 60px;
    margin: -6px -8px;
}

.contentBadge {
    display: inline-flex;
    align-items: center;
    height: 32px;
    border-radius: 4px;
    padding: 0 8px;
    @include transition();
    cursor: pointer;

    &:hover {
        background: $colorNeutral070;
        .gradient {
            background: linear-gradient(to right, rgba(242, 242, 242, 0) 0%, rgba(242, 242, 242, 0.7) 50%, $colorNeutral070 100%);
        }
    }

    &.active {
        background: $colorBlue010;
        .gradient {
            background: linear-gradient(to right, rgba(231, 240, 254, 0) 0%, rgba(231, 240, 254, 0.7) 50%, $colorBlue010 100%);
        }
    }
}

.container {
    display: inline-block;
    white-space: nowrap;
}

.containerWrap {
    position: relative;
    overflow: hidden;
    max-width: 280px;
}

.gradient {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 13px;
    z-index: $zIndexLow;
}

.tooltip {
    max-width: 300px;
    word-wrap: break-word;
}

.HintTitle{
    font-weight: 700;
}