@import "../../../../basics-scss/mixins";

.WorkflowList {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.WorkflowList::-webkit-scrollbar {
  width: 13px;
}

.WorkflowList::-webkit-scrollbar-track {
  background-color: #F4F7FD;
}

.WorkflowList::-webkit-scrollbar-button{
  display: none;
}

.WorkflowList::-webkit-scrollbar-thumb {
  background-color: rgba(110, 114, 143, 0.5);
  border-radius: 6px;
  border: 3px solid transparent;
  background-clip: content-box;
}

.WorkflowListMenu {
  margin: 8px 0;
  user-select: none;
}

.WorkflowListMenuItem {
  display: flex;
  flex-flow: row nowrap;
  padding: 8px;
  font-family: $fontFamilyMain;
  font-size: 13px;
  cursor: pointer;
  line-height: 18px;
  color: #334d6e;
  &:hover {
    color: #004AAD;
    background-color: #E8F0FE;
  }
}

.Icon {
  padding: 1px;
  margin: 0 8px 0 0;
}

.IconCheckedOutWorkflow{
  @include svgIcon(16px,16px, #FDAC41);
}

.IconWorkflow{
  @include svgIcon(16px,16px, #00CFDD);
}