@import "../../../basics-scss/mixins";

.HeaderPanel {
    display: flex;
    flex-flow: row nowrap;
    height: 42px;
    padding: 0;
    box-sizing: border-box;
    background: $colorNeutral000;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: $zIndexMiddle;
}

.LeftPanel{
    display: flex;
    flex: 1 1 auto;
    flex-flow: row wrap;
    justify-content: flex-start;
    overflow: hidden;
}

.Home{
    user-select: none;
    cursor: pointer;
    width: 42px;
    height: 42px;
    padding: 12px;
    @include svgIcon(18px,18px,#e31450);
}

.Tab{
    display: flex;
    flex-flow: row wrap;
    user-select: none;
    padding: 0 12px;
    height: 42px;
    color: $colorNeutral090;
}

.TabError{
    background: #FF5C5C;
    color: $colorNeutral000;
}

.TabCompleted{
    background: #57EBA3;
    color: $colorNeutral800;
}

.TabCancelled{
    background: #DFE1E5;
    color: $colorNeutral800;
}

.TabRunning{
    background: #0F8AF9;
    color: $colorNeutral000;
}

.TabCurrent{
    color: $colorNeutral800;
}

.TabName{
    padding: 12px 3px 6px 3px;
    line-height: 18px;
    cursor: pointer;
    height: 100%;
}

.TabNameCurrent{
    border-bottom: 2px solid #E31450;
    font-weight: 600;
}

.IconX {
    margin: 16px 0 14px 8px;
    width: 12px;
    height: 12px;
    cursor: pointer;
    @include svgIcon(12px,12px);
}

.UserPanel{
    display: flex;
    position: relative;
    flex: 1 1 auto;
    flex-flow: row nowrap;
    justify-content: flex-end;
    user-select: none;
    padding: 0 18px;
}

.UserIcon {
    margin: 9px 4px 10px 4px;
    height: 23px;
    width: 23px;
    user-select: none;
    @include svgIcon(23px,23px);
}

.Username{
    user-select: none;
    padding: 12px 0 12px 4px;
    height: 18px;
    font-weight: 600;
    color: $colorNeutral800;
    cursor: pointer;
}

.UserMenuIcon {
    margin: 18px 18px 16px 4px;
    width: 9px;
    height: 9px;
    cursor: pointer;
    @include svgIcon(9px,9px,$colorNeutral800);
}

.Icon {
    margin: 12px;
    height: 18px;
    width: 18px;
    cursor: pointer;
    @include svgIcon(18px,18px,$colorNeutral090);
}

.Menu {
    position: absolute;
    background: $colorNeutral000;
    width: 163px;
    padding: 7px 0;
    box-shadow: $boxShadowModal;
    border-radius: 4px;
    z-index: $zIndexMiddle;
    right: 100px;
    top: 40px;

    li {
        height: 26px;
        padding: 4px 12px 4px 12px;
        line-height: 18px;
        color: $colorNeutral800;
        cursor: pointer;
        &:hover {
            background: #E8F0FE;
        }
    }
}

.Link{
  display: block;
  color: $colorNeutral800;
  width: 100%;
}
