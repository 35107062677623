.Base {
    display: flex;
    flex-wrap: nowrap;
}
.ButtonsContainer {
    flex: auto;
    height: 36px;
    position: relative;
    text-align: right;
}
.ButtonsContent {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    &:before {
        content: '';
        display: inline-block;
        width: 1px;
        height: 32px;
        margin: 2px;
    }
    & > * {
        margin: 2px;
    }
}
.Burger {
    flex: 0 0 32px;
    margin: 2px;
}