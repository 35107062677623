@import "../../basics-scss/mixins";

.Main {
  height: 100vh;
  padding-top: $headerHeight;
  margin-top: -$headerHeight;
  flex: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.MainIntro {
  padding: 10px;
  margin: 0 auto;
  text-align: center;
  max-width: 420px;
}
