.Row {
    display: flex;
    margin: 0 0 12px;
    &:last-child {
        margin-bottom: 0;
    }
}

.Fields {
    flex: auto;
    margin-right: 4px;
    display: flex;
    position: relative;
    align-items: flex-start;
}

.Field {
    flex: 1 1 29%;
    display: flex;
    align-items: flex-start;
    margin-right: 8px;

    > span {
        flex: 1 1 5%;
        margin: 0 5px;
    }

    > div {
        flex: 1 1 49%;
    }
}

.Remove {
    min-width: 32px;

    .Button {
        margin: 0 0 0 4px;

        &:first-child {
            margin-left: 0;
        }
    }
}

.Select {
    width: 100%;
    margin: 0 2px;

    &:first-child {
        margin-left: 0;
    }
}

.BlockTitle {
    font-size: 14px;
    margin-bottom: 10px;
    color: #707070;
}

.BlockText {
    color: #6e728f;
    left: -28px;
    position: absolute;
}
