@import "../../basics-scss/mixins";

.All {
    position: relative;
}
.Actions {
    margin-top: 60px;
    & > * {
        margin-right: 5px;
    }
}
.ActionsTop {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    .Title {
        margin-right: 16px;
    }
    .Button {
        margin-right: 4px;
    }
}
.Bottom {
    max-width: 348px;
}

.Text {
    font-size: $fontSizeBody;
    line-height: $lineHeightBody;
    b {
        font-weight: 600;
    }
}
.Buttons {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;

    & * {
        margin-left: 8px;
    }
}
