@import "../../../basics-scss/mixins";

.CondSelectButton {
    width: 100%;
    height: 32px;
    padding: 0 20px 0 8px;
    font-size: $fontSizeInput;
    line-height: 1;
    color: $colorNeutral800;
    background-color: $colorNeutral010;
    background-image: none;
    border: 1px solid $colorNeutral080;
    border-radius: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    text-align: left;
    @include transition();

    &.placeholder {
        color: $colorNeutral090;
    }

    &:hover {
        background-color: $colorNeutral060;
    }

    &:focus {
        background-color: $colorNeutral000;
        border-color: $colorBlue150;
        box-shadow: inset 0 0 1px 1px $colorBlue150;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        background: url("/assets/img/select-arrow.svg?inline") no-repeat 0 0;
        background-size: 100% 100%;
        width: 16px;
        height: 16px;
        top: 50%;
        margin-top: -8px;
        right: 8px;
    }

    &:disabled {
        @include readonlyInputStyles;
    }
}

.CondSelectBlock {
    border-radius: 4px;
    background: $colorNeutral000;
    box-shadow: $boxShadowModal;
    z-index: $zIndexMiddle;
    overflow: hidden;
    width: 440px;
    @include forMedia(sm){
        width: 100%;
    }
}

.CondSelectBlockHead {
    border-bottom: 1px solid $colorNeutral080;
}

.CondSelectBlockFilter {
    padding: 16px 16px 8px;
    display: flex;

    .button {
        margin-right: 8px;
        flex: 0 0 32px;
        z-index: $zIndexLow;
    }
    .input {
        flex: auto;
        width: 100%;
        height: 32px;
        padding: 0 30px 0 8px;
        font-size: $fontSizeInput;
        color: $colorNeutral800;
        background: $colorNeutral010 url("/assets/img/icons/search.svg?inline") no-repeat right 8px center;
        background-size: 16px auto;
        border: 1px solid $colorNeutral080;
        border-radius: 4px;
        @include transition();

        &:hover {
            background-color: $colorNeutral060;
            border-color: $colorNeutral090;
        }

        &:focus {
            background-color: $colorNeutral000;
            border-color: $colorBlue150;
            box-shadow: inset 0 0 1px 1px $colorBlue150;
        }
    }
}

.CondSelectBlockBody {
    overflow: hidden;
    position: relative;
    width: 440px;
    height: 320px;
    @include forMedia(sm){
        width: 100%;
    }
}

.Position {
    position: relative;
}

.Selected {
    color: $colorNeutral800;
    font-weight: 600;
}

.CondSelectBlockList {
    width: 440px;
    height: 320px;
    overflow-y: auto;
    position: absolute;
    left: 0;
    top: 0;
    background: $colorNeutral000;
    @include customScrollbar;
    @include forMedia(sm){
        width: 100%;
    }

    li {
        line-height: 32px;
        width: 100%;
        height: 32px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 16px;
        cursor: pointer;
        position: relative;
        color: $colorNeutral800;
    }
}

.Hovered {
    background: $colorNeutral070;
}

.TextContent {
    flex: 1;
    margin-right: 4px;

    font-size: 14px;
    line-height: 20px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.FieldContainer {
    @extend .ItemContainer;
    width: calc(100% - 32px);
}

.Inner {
    display: flex;
    flex-direction: row;

    &::before {
        content: ' ';
        background-image: url("/assets/img/icons/connector-2.svg?inline");
        display: inline-flex;
        align-items: center;
        width: 14px;
        background-repeat: no-repeat;
        margin-inline-end: -12px;
        margin-inline-start: 22px;
    }

    &:last-child::before {
        content: ' ';
        background-image: url("/assets/img/icons/connector-1.svg?inline") !important;
        display: inline-flex;
        align-items: center;
        width: 14px;
        background-repeat: no-repeat;
        margin-inline-end: -12px;
        margin-inline-start: 22px;
    }
}

.NotFound {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $fontSizeBody;
    line-height: $lineHeightBody;
    color: $colorNeutral600;
}

.RemModel {
    @extend .ItemContainer;
    flex-wrap: wrap;
    align-items: center;

    &:hover {
        background: $colorNeutral070;
    }
}

.ItemContainer {
    display: flex;
    flex-direction: row;
}

.ColumnTitleContainer {
    @extend .ItemContainer;
    flex-wrap: wrap;

    .BaseIcon {
        padding-left: 0;
    }
}


.CondSelectBlockChildren {
    .CondSelectBlockList {
        &.hidden {
            left: 100%;
        }
    }
}

.slideIn {
    animation-name: slideIn;
    animation-duration: .3s;
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.slideOut {
    animation-name: slideOut;
    animation-duration: .4s;
}

@keyframes slideOut {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(100%);
    }
}

.BaseIcon {
    display: flex;
    align-items: center;
    padding: 0 8px;

    @include svgIcon(16px,16px);
}

.DWAIcon {
    @extend .BaseIcon;

    padding: 0;
    margin-right: 4px;
}

.ExpandIcon {
    @extend .BaseIcon;

    padding: 0;
    margin-right: 8px;
}

.ArrowIcon {
    @extend .BaseIcon;

    position: absolute;
    right: 0;
    width: 32px;
    height: 32px;

    &:hover {
        background: $colorNeutral070;
        cursor: pointer;
        border-radius: 4px;
    }

    @include svgIconColor($colorNeutral800);
}

.CondTabsContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 4px 1px;
    padding-left: 16px;
    padding-bottom: 8px;

    .CondTab {
        cursor: pointer;
        background: $colorBlue070;
        padding: 2px 8px;

        color: $colorBlue500;
        font-weight: 600;
        font-size: $fontSizeLabel;
        line-height: $lineHeightLabel;

        &:hover {
            color: $colorBlue500;
            background: $colorBlue100;
        }

        &:first-child {
            border-bottom-left-radius: 4px;
            border-top-left-radius: 4px;
        }
        &:last-child {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }

        &.ActiveTab {
            background: $colorBlue300;
            color: $colorNeutral000;
        }
    }
}

.CondSelectBlockPath {
    color: $colorNeutral600;
    font-size: $fontSizeBody;
    padding: 8px 16px;
    margin-top: -8px;
    .container {
        height: 24px;
        line-height: 24px;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}

.ConditionField {
    width: 1px;
    overflow: hidden;
}

.tooltip {
    max-width: 300px;
    word-wrap: break-word;
}
