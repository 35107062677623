@import "../../../../../basics-scss/mixins";

.ImageThumbnail {
    width: 100px;
    height: 100px;
    border: 1px solid $colorNeutral080;
    border-radius: 4px;
    background: no-repeat center;
    background-size: cover;
    margin-left: 8px;
    cursor: pointer;
    &:hover {
        border-color: $colorNeutral090;
    }
    &:active {
        border-color: $colorNeutral800;
    }
}
.Wrap {
    display: flex;
}