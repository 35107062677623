@import "../../basics-scss/mixins";

.LdapContainer {
    display: flex;
    width: 100%;
    height: auto;
    position: relative;
    overflow-y: auto;

    .RightBlock {
        flex: 1 1 50%;
    }

    .LeftBlock {
        flex: 1 1 25%;
    }
}

.Ldap {
    margin-top: 32px;
}


.TableAttribute {
    font-family: $fontFamilyMain;
    font-size: $fontSizeBody;
    line-height: $lineHeightBody;
    width: 100%;
}

.AttrRow{
    display: flex;
    &:nth-child(odd) {
        background-color: $colorNeutral020;
    }
    & div {
        min-width: 248px;
        color: $colorNeutral800;
        word-break: break-all;
    }
}

.AttrName{
    width: 30%;
    padding: 20px 12px 20px 40px;
}

.AttrValue{
    width: 70%;
    padding: 20px 40px 20px 12px;
}

.LdapInput {
    flex: 1;
    margin-right: 32px;
}

.LinkWrap {
    position: relative;
}

.LinkInner {
    font-size: $fontSizeLabel;
    line-height: $lineHeightLabel;
    position: relative;
}

.Arrow {
    position: absolute;
    line-height: 0;
    left: -22px;
    top: 2px;

    @include svgIcon(16px, 16px, $colorNeutral800);

    &.activeArrow {
        transform: rotate(0deg) !important;
    }
}

.Item {
    & > .Submenu {
        overflow: hidden;
        max-height: 9999px;
        transition: max-height 200ms cubic-bezier(1, 0, 1, 0);
    }
}

.Link {
    position: relative;
    cursor: pointer;
    display: block;
    padding: 6px 20px 6px 80px;
    color: $colorNeutral800;

    .Arrow {
        transform: rotate(-90deg);
    }

    &:hover {
        background: $colorNeutral070;
    }

    &.active {
        background-color: $colorBlue010;
    }
}

.Submenu {
    overflow: hidden;
    max-height: 0;
    transition: max-height 200ms cubic-bezier(0, 1, 0, 1);

    .Link {
        padding-left: 120px;
    }

    .Submenu {
        .Link {
            padding-left: 136px;
        }

    }
}

.FilterStyles {
    display: flex;
    .FieldSearch {
        margin-left: 4px;
    }
}

.Selected {
    font-weight: 600;
    color: $colorNeutral800;
}