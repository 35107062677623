@import "../../../basics-scss/mixins";

.GroupRow {
  background-color: $colorNeutral010!important;
  cursor: pointer;
  &:hover {
    background-color: $colorNeutral070!important;
    .GroupRowOpenList {
      visibility: visible;
    }
  }
  &:active {
    background-color: $colorBlue010!important;
  }

  td {
    padding-top: 8px!important;
    padding-bottom: 8px!important;
  }
}

.GroupShowMore {
  height: 48px;
  background-color: $colorBlue070!important;
  cursor: pointer;

  &:hover {
    background-color: $colorNeutral070!important;
  }
}

.GroupShowMoreBlock {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 27px;
  font-size: 14px;
  font-weight: 600;
  color: $colorBlue500;
}

.GroupShowMoreIcon {
  margin: 0 8px 0 8px;
  @include svgIcon(16px, 16px, $colorBlue500);
}

.GroupRowValues {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
}

.GroupRowField {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $colorNeutral600;
}

.GroupRowIcon {
  margin: 0 0 0 8px;

  @include svgIcon(16px, 16px, $colorNeutral800);
}

.GroupColumnTitle {
  cursor: pointer;
  padding: 6px 8px;
  font-size: $fontSizeLabel;
  line-height: $lineHeightLabel;
  color: $colorNeutral600;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 280px;
}

.GroupRowValue {
  margin: 0 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 600px;
}

.GroupRowCount {
  color: $colorNeutral090;
  font-size: $fontSizeLabel;
  line-height: $lineHeightLabel;
  font-weight: 600;
}

.GroupRowOpenList {
  display: flex;
  align-items: center;
  visibility: hidden;
}

.VerticalDelimeter {
  margin: 0 24px;
  width: 1px;
  background: $colorNeutral080;
  height: 40px;
}

.Loading {
    position: relative;
    background: $colorNeutral000 url("/assets/img/page-loader.gif") no-repeat center;
    background-size: 56px 56px;
    height: 200px;
}

.LoadingCol {
  padding: 0!important;
}