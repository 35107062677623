@import "../../basics-scss/mixins";

.widget {
    z-index: $zIndexMiddle;
    background: $colorNeutral000;
    width: 250px;
    min-width: 250px;
    display: block;
    position: fixed;
    list-style:none;
    right: -250px;
    transition: right .2s;
    overflow: hidden;
    visibility: hidden;
    border-radius: 4px;

    .headerWidget {
        border-bottom: 1px solid $colorNeutral080;

        .title {
            font-weight: 600;
            color: $colorNeutral800;
            font-size: 15px;
            padding-top: 10px;
            padding-left: 15px;
            padding-bottom: 10px;
            display: inline-block;
        }

        .cross {
            float: right;
            padding-top: 10px;
            padding-right: 15px;
        }
    }

    .bodyWidget {
        margin: 10px;

        input {
            border-radius: 4px;
            width: 100%;
            height: 32px;
            padding: 0 8px;
            font-size: $fontSizeInput;
            color: $colorNeutral800;
            background-color: $colorNeutral000;
            background-image: none;
            border: 1px solid $colorNeutral080;
        }
    }
}
.box {

}
.boxContent {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 32px;
}
.boxCol {
    flex: 1;
    max-width: 300px;
}
.boxList {
    flex: auto;
    overflow-y: auto;
    padding: 0;
    @include customScrollbar;
}
.boxListWrap {
    display: flex;
    flex-direction: column;
    height: 340px;
    border: 1px solid $colorNeutral080;
    background: $colorNeutral000;
    border-radius: 4px;
}
.boxSearch {
    flex: 0 0 57px;
    padding: 16px;
    border-bottom: 1px solid $colorNeutral080;
    .input {
        position: relative;
        input {
            @include stringInputStyles;
        }
    }
    .icon {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        @include svgIcon(16px,16px,$colorNeutral090);
        &.hover {
            cursor: pointer;
            &:hover {
                @include svgIconColor($colorNeutral800);
            }
        }

    }
}
.boxItem {
    display: block;
    cursor: default;
    line-height: 1;
    user-select: none;
    color: $colorNeutral800;

    &.has_sub {
        font-weight: 600;
    }

    &.active {
        background: $colorBlue010!important;
    }

    &.widgetItem {
        color: $colorTeal500;
    }

    .text {
        display: inline-block;
        vertical-align: top;
        padding: 4px 3px 4px 8px;
    }

    .icon {
        display: inline-flex;
        vertical-align: top;
        align-items: center;
        justify-content: center;
        margin-left: 3px;
        width: 15px;
        height: 15px;
        border-radius: 3px;
        position: relative;
        top: 4px;
        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }
    .arrow {
        @include svgIcon(16px,16px,$colorNeutral800);
        &.active {
            transform: rotate(180deg);
        }
    }
}
.boxSpacer {
    display: block;
    cursor: default;
    user-select: none;
    height: 22px;
}
.dragAvatar {
    padding: 0 8px;
    position: fixed;
    z-index: $zIndexModal;
    pointer-events: none;
}
.boxSub {
    padding-left: 8px;
}
.boxButtons {
    margin: 0 16px;
    box-sizing: content-box;
    padding-top: 26px;
    height: 340px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &.last {
        justify-content: flex-start;
        margin-right: 0;
    }
    .item {
        margin-top: 8px;
        &:first-child {
            margin-top: 0;
        }
    }
}
.boxButtonsRight {
    font-weight: 600;
    color: $colorNeutral800;
    font-size: 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid red;
}
.btnText {
    min-width: 115px;
    padding: 0 5px;
}
.boxTitle {
    @include formLabel;
}
.splits {
    margin-top: auto;
}
.noResults {
    padding: 12px 8px;
    text-align: center;
    color: $colorNeutral600;
}
.categoryTitle {
    text-transform: uppercase;
    font-size: 11px;
    line-height: 15px;
    font-weight: 600;
    color: #A9ACBE;
    padding: 4px 3px 4px 8px;
    margin-top: 12px;
    &:first-child {
        margin-top: 0;
    }
}