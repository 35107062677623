@import "../../basics-scss/mixins";

.Wrap {
    min-height: 100%;
    padding: $headerHeight 0 0 $sidebarWidth;
    @include transition(padding-left, $transDurationPopup);

    &.pinnedAside {
        padding-left: $pinOffsetLeft;
        @include forMedia(sm){
            padding-left: 0;
        }
    }

    @include forMedia(sm){
        transition: none;
        padding: $headerHeightMobile 0 0;
    }
}
.Main {

}