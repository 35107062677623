@import "../../basics-scss/mixins";

.Filter {
  background: $colorNeutral000;
  border-bottom: 1px solid $colorNeutral080;
  border-top: 1px solid $colorNeutral080;
  padding: 24px;
}

.FilterWrap {
  margin: 0 -16px;
  overflow: hidden;
  max-height: 0;
  transition: max-height 200ms cubic-bezier(0, 1, 0, 1);

  &.active {
    max-height: 9999px;
    transition: max-height 200ms cubic-bezier(1, 0, 1, 0);
  }
}

.FilterWrapContent {
  padding: 8px 0;
}

.FilterControls {
  padding-bottom: 24px;
  position: relative;
  margin-bottom: 24px;

  &:after {
    content: '';
    position: absolute;
    display: block;
    background: $colorNeutral080;
    height: 1px;
    right: -40px;
    left: -60px;
    bottom: 0;
  }
}

.FilterControlsContainer {
  display: flex;
  justify-content: space-between;
}

.Column {
  flex: 0 1;
  display: flex;
  align-items: center;
  margin-right: 16px;
  .Button {
    margin-left: 4px;
  }
  &:nth-child(2){
    flex-grow: 1;
  }
}

.ClearAll {
  margin-left: auto;
}

.FilterControlsSep {
  display: inline-block;
  vertical-align: top;
  width: 68px;
  height: 24px;
  margin: 0 5px;
}

.FilterConditions  {
  padding: 0 0 8px;
  border-bottom: 1px solid $colorNeutral080;
}

.FilterOrCondition {
  border-top: 1px solid $colorNeutral080;
  margin-top: 24px;
}

.FilterSorts {
  border-bottom: 1px solid $colorNeutral080;
  padding-bottom: 8px;

  & > div {
    max-width: 848px;
  }
}

.FilterSortLabel {
  color: $colorNeutral800;
  padding: 14px 0 13px;
}

.FilterMainLabel {
  font-size: $fontSizeBody;
  color: $colorNeutral800;
  white-space: nowrap;
  margin-right: auto;
  padding-right: 4px;
}

.FilterLabel {
  font-size: $fontSizeBody;
  margin: 24px 0 16px;
  color: $colorNeutral800;
}

.SubFilterLabel {
  font-size: $fontSizeBody;
  color: $colorNeutral800;
  padding: 15px 0 13px;
}

.SaveToolbar {
  margin-top: 24px;
  display: flex;
  align-items: center;

  span {
    color: $colorNeutral800;
    font-size: $fontSizeBody;
  }

  .input {
    flex: 0 1 338px;
    margin: 0 12px 0 20px;
  }
}


.BorderBottom {
  border-bottom: 1px solid $colorNeutral080;
}


.Buttons {
  margin-top: 24px;
  .Button {
    margin: 0 2px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.FilterSearch {
  flex: 0 1 220px;
  margin-left: auto;
  display: flex;
  .input {
    flex: auto;
    border-radius: 4px 0 0 4px ;
  }
  .button {
    flex: 0 0 32px;
    margin-left: -1px;
    border-radius: 0 0 0 0;
    position: relative;
    &:last-child {
      border-radius: 0 4px 4px 0;
    }
    &:hover {
      z-index: $zIndexLow;
    }
  }
  .icon {
    line-height: 0;
  }
}

.Mobile {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: $colorNeutral000;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.MobileTop {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    margin: 0;
    max-width: 87%;
  }
}

.MobileFilter {
  margin: 0;
  padding: 16px;
  .FilterControls {
    margin-bottom: 16px;
    padding-bottom: 16px;
  }
  .FilterControlsContainer {
    flex-direction: column;
  }
  .Column {
    flex: auto;
    margin: 0 0 16px;
    display: block;
    .Button {
      margin: 0 8px 0 0;
    }
    &:first-child {
      order: 3;
      margin: 16px -16px -8px;
      padding: 0 16px;
      border-top: 1px solid $colorNeutral080;
      .Button {
        margin-bottom: 8px;
      }
    }
  }
  .ClearAll {
    margin: 0;
  }
  .FilterMainLabel {
    text-transform: uppercase;
    font-size: $fontSizeTitle;
    line-height: $lineHeightTitle;
    margin: 22px 0;
    color: $colorNeutral600;
    font-weight: 600;
  }
  .FilterLabel {
    text-transform: uppercase;
    font-size: $fontSizeTitle;
    line-height: $lineHeightTitle;
    margin: 16px 0;
    color: $colorNeutral600;
    font-weight: 600;
  }
  .FilterConditions {
    margin: 0 -16px;
    padding: 0 16px;
  }
  .FilterOrCondition {
    margin: 16px -16px 0 -16px;
    padding: 0 16px;
  }
  .FilterSorts {
    margin: 0 -16px;
    padding: 0 16px;
  }
  .Buttons {
    .Button {
      width: 100%;
      margin: 16px 0 0;
      &:first-child {
        margin-top: 0;
      }
    }
  }
}

.FilterGroupRow {
  position: relative;
  display: flex;
  align-items: flex-start;
}

.FilterGroupLabel {
  font-size: $fontSizeBody;
  margin: 24px 0 16px;
  color: $colorNeutral800;
  flex: 1 1;
  align-items: flex-start;
  margin-right: 8px;

  &:nth-child(1){
    flex-shrink: 1.2;
  }

  &:nth-child(2){
    flex-shrink: 1.5;
  }
}

.FilterGroupSpace {
  width: 32px;
}

.MoreControls {
  margin-left: 4px;
}

.DropdownItems {
  background: #FFFFFF;
  border-radius: 4px;
  padding: 8px 0;
  box-shadow: $boxShadowCard;
}

.DropdownItem {
  padding: 6px 16px;
  font-size: 14px;
  line-height: 20px;
  color: $colorNeutral800;
  cursor: pointer;

  &:hover {
    background: $colorNeutral070;
  }
}
