// z-index
$zIndexLow: 10;
$zIndexMiddle: 20;
$zIndexHigh: 30;

$zIndexFixedPanel: 50; // actionsPanel,  table(list) fixed head, table(list) fixed horizontal scrollbar
$zIndexAside: 60; // left sidebar & right sidebar(listBox menu)
$zIndexHeader: 70; // header & sp header
$zIndexCellEditor: 75; // list cell editor
$zIndexModal: 80; // modal windows
$zIndexPopover: 90; // popovers
$zIndexContextMenu: 90; // context menu
$zIndexDropDown: 90; // dropDowns
$zIndexMessages: 100; // info messages