@import "../../../basics-scss/mixins";

.item {
    font-size: 14px;
    line-height: 1;
    display: inline-block;
    vertical-align: top;
    margin: 0 30px 0 0;
    @include linkStyles;
    &[disabled] {
        pointer-events: none;
        color: #a6a7b5;
    }
}

.ContextMenu {
    background: $colorNeutral000;
    border-radius: 4px;
    box-shadow: $boxShadowModal;
    max-width: 150px;
    min-width: 100px;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    z-index: $zIndexContextMenu;

    li {
        position: relative;
        padding: 7px 30px 8px 20px;
        cursor: pointer;
        color: $colorNeutral800;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
            background: $colorNeutral070;
        }
    }
}
