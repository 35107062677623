@import "../../../../../basics-scss/mixins";

.Checkbox {
    display: inline-block;
    vertical-align: middle;
    line-height: 0;

    input {
        position: absolute;
        opacity: 0;

        &:checked + .icon {
            background: $colorBlue300;
            border-color: $colorBlue500;

            .Check {
                visibility: visible;
            }
        }

        &:focus + .icon {
            border: 2px solid $colorBlue150;
        }
    }

    .icon {
        justify-content: center;
        align-items: center;
        cursor: pointer;
        display: inline-flex;
        border-radius: 4px;
        border: 1px solid $colorNeutral080;
        width: 16px;
        height: 16px;
        text-align: center;
        user-select: none;
        background: $colorNeutral010;
        @include transition();

        .Check {
            visibility: hidden;
        }

        &:hover {
            background: $colorNeutral060;
        }

        &:active {
            background: $colorNeutral050;
            border-color: $colorNeutral090;
        }
    }

    .Check {
        @include svgIcon(10px, 8px, $colorNeutral000);
    }

    .disabled {
        cursor: default;
        background: $colorNeutral080!important;
        border-color: $colorNeutral080!important;

        .Check {
            @include svgIconColor($colorNeutral600);
        }
    }

    &.warningInput {
        input {
            border-color: $colorRed400;
        }
    }
}