@import "../../../../basics-scss/mixins";

.FilterItems {
  display: flex;
  margin: 8px 0 16px;

  @include forMedia(sm){
    flex-direction: column;
    margin: 16px 0 0;
    display: none;
    &.active {
      display: flex;
    }
  }
}

.FilterItem {
  margin-right: 8px;
  flex: 0 1 312px;

  &:last-child {
    margin-right: 0;
  }

  @include forMedia(sm){
    flex: 0 0 32px;
    margin: 0 0 16px 0;

    &:last-child {
      margin: 0;
    }
  }
}

.FilterTrigger {
  display: none;
  cursor: pointer;

  @include forMedia(sm){
    display: flex;
    justify-content: space-between;
  }
}

.FilterTriggerText {
  color: $colorNeutral800;
  font-size: $fontSizeBody;
  line-height: $lineHeightBody;
  font-weight: 600;
}

.FilterTriggerIcon {
  @include svgIcon(20px,20px,$colorNeutral800);
  &.active {
    transform: rotate(180deg);
  }
}

.FilterWrap {
  @include forMedia(sm){
    padding: 16px;
    background: $colorNeutral000;
    box-shadow: $boxShadowCardTaskboard;
    border-radius: 4px;
    margin: 16px 0;
  }
  @include forMedia(xs){
    border-radius: 0;
  }
}