.multiPivot  {
    width: 100%;

    table {
        margin: auto;
        text-align: left;
        border-collapse: separate;
        border: solid 1px #dfe1e5;
        border-radius: 4px;
        border-spacing: 0;
        font-size: 14px;
        font-weight: normal;

        th {
            padding: 15px;
        }

        td {
            padding: 15px;
            vertical-align: middle;
        }

        span {
            white-space: nowrap;
            display: block;
         }
    }
}
.colCategory, .rowCategory, .colHeader, .rowHeader {
    padding: 15px;
    font-size: 14px;
    color: #97a0af;
}

.colCategory, .rowCategory {
    font-weight: 600;
}

.colCategory, .colHeader, th.totalColTd  {
    border-bottom: solid 1px #dfe1e5;
    border-right: none !important;
    vertical-align: middle;
}

.rowCategory, .rowHeader {
    border-right: solid 1px #dfe1e5;
}

.totalRow, .totalColTd {
    font-size: 14px;
    font-weight: 600;
    color: #17181d;
}

.leftBorder {
  border-left: solid 1px #dfe1e5;
}

.totalRow th, .totalRow td {
    border-top: solid 1px #dfe1e5;
}

.totalRow th {
    padding: 7px 33px 7px 15px !important;
    text-align: right;
}

.totalRow td {
    padding: 7px 15px !important;
}

.firstRow {
    &:nth-child(1n+2) {
        th, td {
            border-top: solid 1px #dfe1e5;
        }
    }
}

.dataRow {
    &:nth-child(odd) {
        td {
            background-color: #eff5fa;
        }
    }
}

.emptyCell {
    border-bottom: solid 1px #dfe1e5;
    border-right: solid 1px #dfe1e5;
 }

