@import "../../../basics-scss/mixins";

.input_container {
    position: absolute;
    top: 36px;
    display: flex;
    z-index: $zIndexLow;
    width: 100%;
    padding: 0 31px 0 0;

    input {
        flex: auto;
        width: 100%;
        height: 24px;
        padding: 0 6px;
        font-size: 12px;
        line-height: 1.42857;
        color: $colorNeutral800;
        background-color: $colorNeutral000;
        background-image: none;
        border-radius: 3px 0 0 3px;
        border: 1px solid $colorNeutral080;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

        &[readonly] {
            cursor: not-allowed;
            background-color: #e6e8ea;
        }
    }
}
.list {
    max-height: 250px;
    width: 100%;
    overflow-y: auto;
    padding: 8px 0;
    border-radius: 4px;
    box-shadow: $boxShadowModal;
    background-color: $colorNeutral000;
    @include customScrollbar;

    .item {
        white-space: nowrap;
        padding: 0 12px 0;
        line-height: 24px;
        cursor: pointer;
        color: $colorNeutral800;
        overflow: hidden;
        text-overflow: ellipsis;
        &.active {
            background-color: $colorNeutral070;
        }
    }

}
.listContainer {
    position: absolute;
    top: 36px;
    display: flex;
    z-index: $zIndexLow;
    left: 0;
    right: -44px;
}