@import "../../basics-scss/mixins";

.all {
    position: relative;
}
.actions {
    margin-top: 60px;
    & > * {
        margin-right: 5px;
    }
}
.actionsTop {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    .title {
        margin-right: 60px;
    }
    .button {
        margin-right: 8px;
    }
}
.headline {
    font-family: $fontFamilyHeadline;
    font-size: 22px;
    font-weight: 600;
    color: $colorNeutral800;
    margin: 0 0 32px 0;
}
.bottom {
    max-width: 400px;
}
