@import "../../basics-scss/mixins";

.Debugger {
    position: fixed;
    z-index: $zIndexModal;
    width: 700px;
    left: 20px;
    bottom: 0;
    font-size: 10px;
    background: $colorNeutral000;
    border: 1px solid #000;
}

.DebuggerTabsTitle {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: #eee;
}

.DebuggerTabsTitleItem {
    display: flex;
    align-items: center;
    padding: 0 5px;
    border: 1px solid;
    border-color: transparent #000 transparent #ccc;
    cursor: pointer;
    max-width: 150px;
    height: 50px;
    font-size: 10px;
    text-align: center;
    word-break: break-all;
    white-space: normal;
}

.DebuggerTabsTitleItemActive {
    background: $colorNeutral000;
}

.DebuggerTabs {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 700px;
}

.DebuggerTabsItem {
    width: 700px;
    display: none;
}

.DebuggerTabsItemActive {
    display: block;
}

.DebuggerHeader {
    padding: 0 20px;
    font-weight: bold;
    cursor: pointer;
}

.DebuggerURL {
    height: 30px;
}

.DebuggerBody {
    overflow-y: auto;
    max-height: 500px;
    width: 100%;
    padding: 0 20px;
    transition: max-height 100ms linear;
    @include customScrollbar;
}

.DebuggerLink {
    position: absolute;
    top: 1px;
    right: 20px;
    @include svgIcon(auto,12px,#17181d);
}


.DebuggerCollapsed {
    .DebuggerBody {
        overflow: hidden;
        max-height: 0;
    }
}

.DebuggerTable {
    width: 100%;

    th {
        vertical-align: middle;
        border-bottom: 1px solid #aaa;
        padding: 3px 0 4px;
    }

    td {
        vertical-align: middle;
        padding: 3px 5px;
        border: 1px solid $colorNeutral000;
    }
}

.DebuggerRow {
    @for $i from 0 through 10 {
        &Nesting#{$i} {
            background-color: darken(#fff, $i * 5);
        }
    }
}

.DebuggerRowProgress {
    position: relative;
    height: 6px;
    background: #278efc;
}