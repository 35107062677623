@import "../../../../basics-scss/mixins";


.menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: $zIndexDropDown;
    display: none;
    float: left;
    min-width: 160px;
    padding: 8px 0;
    margin: 8px 0 0;
    list-style: none;
    font-size: 13px;
    text-align: left;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 0 4px 0 rgba(0, 0, 0, 0.25);
    background-clip: padding-box;

    &.active {
        display: block;
    }

    .item > * {
        cursor: pointer;
        display: block;
        padding: 0 12px;
        line-height: 26px;
        height: 26px;
        color: #334d6e;
        white-space: nowrap;
        position: relative;

        &:hover {
            text-decoration: none;
            color: #334d6e;
            background-color: #e8f0fe;
        }
    }

    .split {
        background-color: #dfe1e5;
        height: 1px;
        margin: 4px 0;
    }

    .icon {
        display: inline-block;
        vertical-align: top;
        width: 11px;
        height: 11px;
        margin-right: 8px;
        position: relative;
        top: 8px;
        @include svgIcon(100%,auto,#334d6e);
    }
}

