@import "../../basics-scss/mixins";

.Row {
    margin: 0 0 16px;
    position: relative;
}

.Fields {
    display: flex;
    align-items: flex-start;
}

.Field {
    flex: 1 1;
    display: flex;
    align-items: center;
    margin-right: 8px;
    position: relative;

    &:nth-child(1){
        flex-shrink: 1.2;
    }

    &:nth-child(2){
        flex-shrink: 1.5;
    }

    > span {
        margin: 0 5px;
    }

    > div {
        flex: 1 1 49%;
    }
}

.Remove {
    white-space: nowrap;
    text-align: right;

    .ButtonSpacer {
        display: inline-block;
        width: 32px;
        height: 32px;
    }

    .Button {
        margin: 0 0 0 4px;

        &:first-child {
            margin-left: 0;
        }
    }

    &.sorting {
        margin-left: 0;
    }
}

.Select {
    width: 100%;
    margin: 0;
}

.BlockTitle {
    font-size: 14px;
    margin-bottom: 10px;
    color: #707070;
}

.BlockText {
    color: $colorNeutral600;
    font-size: $fontSizeLabel;
    line-height: 32px;
    margin-right: 8px;
}

.MobileRow {


    .Fields {
        flex-direction: column;
        align-items: normal;
    }
    .Field {
        flex: auto;
        margin: 0 0 16px;
        > div {
            flex: auto;
        }
    }
    .Remove {
        margin: 0;
        text-align: left;
    }
    .BlockText {
        position: static;
        width: auto;
        margin: 0 0 16px;
        text-align: left;
        font-weight: 600;
        font-size: $fontSizeLabel;
        line-height: $lineHeightLabel;
    }
}
