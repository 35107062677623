@import "../../basics-scss/mixins";

.wrap {
    display: inline-block;
    vertical-align: top;
    position: relative;
}
.menu {
    @include dropDown;
    max-width: 250px;
    min-width: 200px;
}
.item {
    position: relative;
    padding: 7px 30px 8px 20px;
    cursor: pointer;
    color: $colorNeutral800;
    & > .menu {
        position: absolute;
        left: 100%;
        top: 0;
        visibility: hidden;
    }
    &.active {
        background: $colorNeutral070;
        & > .menu {
            visibility: visible;
            @include animateFadeIn();
        }
    }
}
.text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}
.arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 34px;
    height: 34px;
    top: 0;
    right: 0;
    @include svgIcon(16px,16px);
}
.disabled {
    color: #cccccc!important;
    cursor: default!important;
    background: $colorNeutral000!important;

    svg path {
        fill: $colorNeutral090!important;
    }
}
.selected {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    color: #757575;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menuIcon {
    @include svgIcon(18px,18px);
}

.Check {
    line-height: 0;
    @include svgIcon(14px,14px,$colorNeutral800);
}

.MobilePopup {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: $colorNeutral000;
}

.MobileHeader {
    border-bottom: 1px solid $colorNeutral080;
    padding: 16px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
}

.MobileButtonChevron {
    @include svgIcon(24px,24px,$colorNeutral800);
    flex: 0 0 24px;

    & + .MobileTitle {
        margin-left: 8px;
    }
}

.MobileHeaderButton {
    flex: 0 0 32px;
}

.MobileTitle {
    flex: auto;
    text-transform: uppercase;
    color: $colorNeutral600;
    font-size: $fontSizeBody;
    line-height: $lineHeightBody;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 8px 0 0;
}

.MobileMenu {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    top: 64px;
}

.MobileItem {
    padding: 20px 16px;
    border-bottom: 1px solid $colorNeutral080;
    display: flex;
    align-items: center;
}

.MobileText {
    color: $colorNeutral800;
    font-size: $fontSizeBody;
    line-height: $lineHeightBody;
    font-weight: 600;
    flex: auto;
}

.MobileCheck {
    @include svgIcon(24px,24px,$colorGreen500);
    flex: 0 0 24px;
}