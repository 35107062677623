@import "../../basics-scss/mixins";

.DelegationContainer {
  max-width: 424px;
  border-bottom: 1px solid $colorNeutral080;
  margin-bottom: 16px;
}

.DelegationTitleContainer {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  @include formLabel;

  .DelegationIcon {
    @include svgIcon(16px, 16px, $colorPurple300);
  }

  .DelegationTitle {
    margin-left: 4px;
  }
}

.DelegationUsers {
  margin-bottom: 12px;

  .DelegationUser {
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 4px;

    @include linkStyles();

    &:not(:first-child):before {
      content: "•";
      display: inline-block;
      color: $colorNeutral090;
      margin-right: 8px;
    }
  }
}