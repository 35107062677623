@import "../../../../basics-scss/mixins";

.LinkEditor {
  position: absolute;
  z-index: 100;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  max-width: 300px;
  width: 100%;
  opacity: 0;
  padding: 8px;
  background: $colorNeutral000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08), 0px 1px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  transition: opacity 0.5s;
}