@import "../../../basics-scss/mixins";

.Control{
  padding: 4px 0;
  line-height: 18px;
  color: $colorNeutral800;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ButtonContainer{
  padding: 4px 0;
  display: flex;
  justify-content: center;
  width: 100%;
}

.Icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  user-select: none;
  @include svgIcon(24px,24px,#6E728F);
}
