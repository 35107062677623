@import "../../basics-scss/mixins";

.searchMain {
  padding-right: 252px;

  @include forMedia(sm) {
    padding-right: 0;
  }

  .resultsMain {
    padding-right: 16px;

    .item {
      display: block;
      padding-right: 16px;
      margin: 16px 0;

      &:last-child {
        margin-bottom: 0;
      }

      &:first-child {
        margin-top: 0;
      }

      @include forMedia(sm) {
        padding-right: 0;
      }
    }

    .resultsBtn {
      @include linkStyles;
    }

    .title {
      color: $colorNeutral600;
      font-size: $fontSizeCaption;
      line-height: $lineHeightCaption;
      margin-bottom: 4px;
    }

    .OneTitle {
      margin-bottom: 24px;

      @include forMedia(sm) {
        margin-bottom: 16px;
      }
    }

    .split {
      display: inline-block;
      vertical-align: top;
      margin: 0 8px;
    }
  }

  .Footer {
    margin: 24px 0;
    text-align: center;

    .FooterEndText {
      overflow: hidden;
      position: relative;
      font-size: $fontSizeBody;
      line-height: $lineHeightBody;
      color: $colorNeutral090;
      white-space: nowrap;

      &:before,
      &:after {
        content: '';
        display: inline-block;
        position: relative;
        top: -1px;
        width: 50%;
        height: 1px;
        vertical-align: middle;
        background: $colorNeutral080;
      }

      &:before {
        left: -8px;
        margin-left: -50%;
      }

      &:after {
        left: 8px;
        margin-right: -50%;
      }
    }
  }

  .linkWrap {
    position: relative;
  }
}

.MoreLoader {
  height: 56px;
  background: url("/assets/img/page-loader.gif") no-repeat center;
  background-size: 56px 56px;
}

.record {
  word-break: break-word;
  margin-bottom: 24px;

  @include forMedia(sm) {
    margin-bottom: 16px;
  }

  .LinkWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    .BtnIcon {
      @include svgIcon(16px, 16px, $colorNeutral800);
      margin-right: 6px;
    }

    .Link {
      font-size: $fontSizeLabel;
      line-height: $lineHeightLabel;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @include linkStyles;
    }
  }

  .descriptionItems {
    font-size: $fontSizeBody;
    line-height: $lineHeightBody;
  }

  .SubItems {
    margin-top: 6px;

    font-size: $fontSizeCaption;
    line-height: $lineHeightCaption;
  }

  .ItemWrapper {
    color: $colorNeutral600;

    display: inline-block;
    vertical-align: top;
    margin: 0 16px 4px 0;

    .ItemValue {
      color: $colorNeutral800;
    }
  }
}

.matching {
  background: $colorBlue070;
  color: $colorNeutral800;
  font-weight: bold;
}

.EmptyMessage {
  color: $colorNeutral600;
  font-size: $fontSizeLabel;
  line-height: $lineHeightLabel;
  margin-top: 6px;
}

.searchMenu {
  width: 268px;
  position: fixed;
  top: $headerHeight;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  border-left: 1px solid $colorNeutral080;
  overflow-y: auto;
  @include customScrollbar;

  @include forMedia(sm) {
    display: none;
  }

  .link {
    cursor: pointer;
    display: flex;
    align-items: center;
    min-height: 40px;
    color: $colorNeutral800;
    padding: 0 16px;

    &:hover {
      background: $colorNeutral070;
    }

    &:active {
      background: $colorBlue010;
    }

    &.noHover {
      cursor: default;
      background: none;
    }

    &.disableItem {
      color: $colorNeutral090;
    }
  }

  .subItemLink {
    padding-left: 40px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 23px;
      top: 20px;
      border-radius: 50%;
      width: 2px;
      height: 2px;
      background: $colorNeutral800;
    }
  }

  .ChosenCategory {
    background: $colorBlue010;
  }

  .SearchIcon {
    margin-right: 8px;
    @include svgIcon(16px, 16px, $colorNeutral800);
  }

  .ArrowIcon {
    margin-right: 8px;
    @include svgIcon(16px, 16px, $colorNeutral090);
  }

  .label {
    flex: auto;
    position: relative;
    height: 20px;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .item.active {
    & > .linkWrap > .link {
      //color: $colorNeutral800;

      .label {
        font-weight: 600;
      }

      .ArrowIcon {
        transform: rotate(90deg);
        @include svgIconColor($colorNeutral800);
      }
    }

    & > .submenu {
      overflow: hidden;
      max-height: 9999px;
      transition: max-height 200ms cubic-bezier(1, 0, 1, 0);
    }
  }
}

.progressBar {
  width: 100%;
  height: 4px;
  margin-bottom: 12px;

  .progressBarLine {
    height: 4px;
    background: $colorBlue300;
    transition: all 200ms ease-in;
  }

  .hideBar {
    transition: opacity 0.2s 300ms ease;
    opacity: 0;
  }
}

.QuickButtonOffset {
  margin-right: 268px;
}

.Skeleton {
  overflow: hidden;
  position: relative;
  max-height: 90vh;
  margin-top: 10px;

  &:after {
    @include animatedShadow(2s);
  }
}

.SkeletonBlank {
  border-radius: 4px;
  background: $colorNeutral077;
}

.SkeletonActCont {
  margin: 0 0 0 16px;

}

.SkeletonActInf {
  @extend .SkeletonBlank;
  height: 20px;
  margin-bottom: 20px;

  display: block;
  transition: all 200ms ease-in;

  &.SkeletonActEl0, &.SkeletonActEl3 {
    max-width: 168px;
  }

  &.SkeletonActEl1 {
    max-width: 112px;
  }

  &.SkeletonActEl2, &.SkeletonActEl4 {
    max-width: 80px;
  }
}

.SkeletonHide {
  display: none;
}
