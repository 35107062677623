@import "../../basics-scss/mixins";
@import "../../basics-scss/grid";

$headerHeight: 70px;

.Header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $headerHeight;
    display: flex;
    align-items: center;
    padding: 0 0 0 14px;
    background: #fff;
    border-bottom: 2px solid #f3f4f4;
    z-index: $zIndexMiddle;
}

.HeaderLogo {
    width: 241px;
    height: 70px;
    flex: 0 0 241px;
}

.HeaderInfo {
    flex: 0 0 40%;
}

.HeaderTitle {
    height: 32px;
    font: 23px 'Open Sans';
}

.HeaderControls {
    flex: 1;
    display: flex;
}

.PortalSelectWrapper {
    display: flex;
    flex: 0 0 255px;
    align-items: center;
}

.PortalSelect {
    margin-right: 5px;
    margin-left: 5px;;
    flex: 1;
}

.BreadcrumbsItems {
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    a {
        cursor: pointer;
    }
}

.IconChevronRight {
    margin: 0 4px 0 2px;
    width: 18px;
    height: 24px;
}

@keyframes loadRotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.ButtonLoader {
    width: 18px;
    height: 18px;
    background: transparent url("/assets/img/loader-grey.svg?inline") no-repeat center;
    background-size: 18px auto;
    animation: loadRotate .5s infinite;
}

.HeaderControlsRemoveButton {
    cursor: pointer;
    border: 1px solid #6e728f;
    background: #fff;
}

.View {
    position: relative;
    left: 250px;
    top: $headerHeight;
    width: calc(100vw - 270px);
    height: calc(100vh - 70px);
}

.ViewLoading {
    position: relative;
    left: 250px;
    background: transparent url("/assets/img/page-loader.gif") no-repeat center;
    background-size: 64px 64px;
    width: calc(100vw - 270px);
    height: calc(100vh - 70px);
}

.ViewIsEmpty {
    font-size: 30px;
    padding: 50px 0 0 0;
    color: #ccc;
    text-align: center;
    height: 100%;
    width: 100%;
}

.Container {
    position: relative;
    max-width: 100%;
    min-height: 130px;
    border: 1px dashed #ddd;
    border-radius: .50em;
    box-sizing: border-box;

    &:hover {
        border: 1px solid #2347dd;

        .ContainerInfo {
            display: block;
        }
    }

    //* {
    //    pointer-events: none;
    //}

    &.ActiveElement {
        border: 1px solid #dd361e;

        .ContainerInfo {
            display: block;
        }
    }
}

.InnerEmptyContainer {
    width: 80%;
    margin: 0 auto;
    text-align: center;
}

.InnerEmptyContainerText {
    margin: 20px 0 10px;
    font-size: 16px;
    color: #334d6e;
    user-select: none;
}

.AddContainerButton {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 2px;
    border: 1px solid #334d6e;
    cursor: pointer;
}

.ContainerTemp {
    background: rgba(0, 0, 0, .1);
}

.ContainerIsLoaded {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 18px;
    height: 18px;
    background: transparent url("/assets/img/loader-grey.svg?inline") no-repeat center;
    background-size: 18px auto;
    animation: loadRotate .5s infinite;
}

.ContainerInfo {
    position: absolute;
    left: 0;
    top: 0;
    padding: 6px 12px;
    border-radius: 0 0 10px 0;
    color: #2F324F;
    z-index: $zIndexMiddle;
    font-size: 10px;
    background: repeating-linear-gradient(135deg, rgba(0, 0, 0, 0.1) 0, #fff 1px, #fff 3px, rgba(0, 0, 0, 0.1) 4px);
}

.Row {
    position: relative;
    min-height: 150px;

    &.ActiveElement {
        border: 1px dashed #dd361e;
    }
}

.RowTemp {
    background: rgba(0, 0, 0, .1);
}

.Columns {
    border: 1px dashed #ddd;

    &.ActiveElement {
        border: 1px dashed #dd361e;
    }
}

.WidgetInstance {
    display: block;

    &.ActiveElement {
        border: 1px dashed #dd361e;
    }
}

.WidgetInstanceTemp {
    display: block;
    height: 30px;
    background: #ccc;
}

.LeftPanel {
    display: flex;
    flex: 0 0 248px;
    flex-flow: column nowrap;
    justify-content: flex-start;
    width: 248px;
    box-sizing: border-box;
    background: #fff;
    position: fixed;
    top: 70px;
    z-index: $zIndexLow;
    height: 100%;
    border-right: 1px solid #334d6e1a;
}

.Tabs {
    display: flex;
    flex-flow: row nowrap;
    padding: 12px 4px 6px 4px;
    width: 100%;
}

.BurgerIcon {
    margin: 0 8px 0 8px;
    cursor: pointer;
    user-select: none;
    width: 18px;
    height: 18px;

    svg {
        width: 18px;
        height: 18px;
    }
}

.Tab {
    display: flex;
    margin: 0 8px 0 8px;
    cursor: pointer;
    user-select: none;
    font: 13px 'Open Sans';
    color: #a9acbe;
    height: 18px;
}

.ActiveTab {
    padding: 0 8px 0 8px;
    cursor: pointer;
    user-select: none;
    font: 13px 'Open Sans';
    color: #17181d;
    height: 18px;
}

.Search {
    display: flex;
    position: relative;
    height: 40px;
    padding: 8px 0 7px 12px;
    border: none;
    border-bottom: 1px solid #DFE1E5;
    border-top: 2px solid #f3f4f4;
    color: #A9ACBE;
    user-select: none;

    & .SearchInput {
        border: none;
        color: #A9ACBE;
        height: 20px;
        width: 88%;
    }
}

.IconSearch {
    position: absolute;
    left: 224px;
    top: 11px;
    width: 12px;
    height: 12px;

    svg {
        width: 12px;
        height: 12px;
    }
}

.CloseButton {
    position: fixed;
    z-index: $zIndexMiddle;
    left: 211px;
    bottom: 60px;
    height: 21px;
    width: 22px;
    cursor: pointer;
    user-select: none;
    background: #FFFFFF;
    border: 1px solid #98a5b6;
    border-radius: 50%;

    svg {
        height: 18px;
        width: 18px;
    }
}

.OpenButton {
    @extend .CloseButton;
    left: 10px;
}

.List {
    width: 100%;
    overflow-y: auto;
}

.List::-webkit-scrollbar {
    width: 13px;
}

.List::-webkit-scrollbar-track {
    background-color: #F4F7FD;
}

.List::-webkit-scrollbar-button {
    display: none;
}

.List::-webkit-scrollbar-thumb {
    background-color: rgba(110, 114, 143, 0.5);
    border-radius: 6px;
    border: 3px solid transparent;
    background-clip: content-box;
}

.ListMenuTitle {
    background: #2F324F;
    color: #fafafa;
    height: 22px;
    line-height: 22px;
    padding: 0 0 0 20px;
}

.ListMenu {
    user-select: none;

    li {
        padding: 8px 0 8px 21px;
        border-bottom: 1px solid #334d6e1a;
        position: relative;
        cursor: pointer;
        line-height: 18px;
        font: 13px 'Open Sans', sans-serif;
        color: #334d6e;

        &:hover {
            color: #004AAD;
        }
    }
}

.Icon {
    position: absolute;
    left: -24px;
    width: 16px;
    height: 16px;
    transform: rotate(90deg);

    svg {
        width: 20px;
        height: 16px;
        fill: #6e728f;
    }
}

.LayoutsItem {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 18px;
    cursor: pointer;
    border-bottom: 1px solid rgba(51, 77, 110, 0.1);
}

.LayoutsItemsBlock {
    height: 27px;
    border: 1px solid rgba(51, 77, 110, 0.5);
    text-align: center;
    font-size: 12px;
    line-height: 25px;

    &:first-of-type {
        border-radius: 5px 0 0 5px;
    }

    &:last-of-type {
        border-radius: 0 5px 5px 0;
    }

    &:only-child {
        border-radius: 5px 5px 5px 5px;
    }

    @for $i from 1 through 12 {
        &-#{$i} {
            flex: $i;
        }
    }
}

.LayoutsPopup {
    position: absolute;
    z-index: $zIndexContextMenu;
    background: $colorNeutral000;
    box-shadow: $boxShadowModal;
    transform: translate(-50%, 0);
    width: 200px;
}