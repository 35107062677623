@import "../../basics-scss/mixins";

.container {
    padding: 16px;
    position: relative;

    @include forMedia(sm) {
        padding: 16px;
    }
}
