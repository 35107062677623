@import "../../basics-scss/mixins";

.BtnQuickReturn {
  position: fixed;
  right: 16px;
  z-index: $zIndexMessages;
  transition: 100ms ease-out;

  @include forMedia(sm){
    right: 16px;
  }

  &.Active {
    bottom: 40px;
  }

  &.Leave {
    bottom: -40px;
  }
}
