@import "../../../basics-scss/mixins";

.Select {
    margin-bottom: 8px;
    width: 300px;
}

.SelectWrap {
    margin-bottom: 16px;
}

.Label {
    @include formLabel;
}

.Input {
    @include stringInputStyles;
}

.Buttons {
    margin-top: 24px;
    text-align: right;
    & > * {
        margin-left: 8px;
    }
}
