@import "../../../basics-scss/mixins";

.base {
    display: block;
    position: relative;
    height: 48px;
    background: $colorNeutral010;
    border: 1px solid $colorNeutral080;
    border-radius: 4px;
    cursor: text;
    @include transition();
    .placeholder {
        color: $colorNeutral600;
        font-size: $fontSizeInput;
        line-height: 19px;
        z-index: 1;
        position: absolute;
        left: 16px;
        top: 14px;
        @include transition(all);
    }
    .input {
        height: 20px;
        width: 100%;
        border: none;
        color: $colorNeutral800;
        font-size: $fontSizeInput;
        cursor: text;
        background: transparent;
        opacity: 0;
        line-height: 1;
        padding: 0;
        @include transition(all);
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active  {
            -webkit-box-shadow: 0 0 0 30px $colorNeutral010 inset !important;
            background: transparent;
        }
        &::-ms-reveal,
        &::-ms-clear {
            display: none;
        }
        &.typePassword {
            padding-right: 20px;
        }
    }
    .inputWrap {
        position: absolute;
        left: 16px;
        top: 20px;
        right: 16px;
    }
    &:hover {
        background: $colorNeutral060;
        .input {
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active  {
                -webkit-box-shadow: 0 0 0 30px $colorNeutral060 inset !important;
                background: transparent;
            }
        }
    }
    &.focus {
        border-color: $colorBlue150;
        box-shadow: inset 0 0 0 1px $colorBlue150;
        background: $colorNeutral000;
        .input {
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active  {
                -webkit-box-shadow: 0 0 0 30px $colorNeutral000 inset !important;
                background: transparent;
            }
        }
    }
    &.focus,
    &.notEmpty {
        .placeholder {
            font-size: $fontSizeCaption;
            line-height: $lineHeightCaption;
            top: 5px;
        }
        .input {
            opacity: 1;
        }
    }
    &.error {
        border-color: $colorRed300;
        box-shadow: inset 0 0 0 1px $colorRed300;
    }
    &.disabled {
        background-color: $colorNeutral050;
        border-color: $colorNeutral050;
        cursor: default;
        box-shadow: none;
        .placeholder {
            color: $colorNeutral090;
        }
        .input {
            cursor: default;
            color: $colorNeutral600;
            -webkit-text-fill-color: $colorNeutral600;
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active  {
                -webkit-box-shadow: 0 0 0 30px $colorNeutral050 inset !important;
                background: transparent;
            }
        }
    }
}
.errMessage {
    color: $colorRed500;
    font-size: $fontSizeCaption;
    line-height: $lineHeightCaption;
}

.passBtn {
    @include svgIcon(16px,16px);
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}