@import "../../../basics-scss/mixins";

.CellEditor {
    position: fixed;
    z-index: $zIndexPopover;
    min-width: 303px;

    &.smallWidth {
        min-width: 112px;
    }
    textarea {
        width: 287px;
        height: 80px;
    }
    .content {
        padding: 8px;
        background: $colorNeutral000;
        box-shadow: $boxShadowCard;
        border-radius: 4px;
    }
    .controls {
        white-space: nowrap;
        margin-top: 8px;
        text-align: right;
    }
    .button {
        margin-left: 8px;
        box-shadow: $boxShadowCard;
    }
}

.CellEditorOverlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: $zIndexPopover;
}
