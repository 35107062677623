@import "../../../../basics-scss/mixins";

.NavContainer {
  flex: 0 0 280px;
  border: 1px solid #D5D8DD;
  border-left: none;
  position: relative;
  @include customScrollbar;

  @include transition(min-width, $transDurationPopup);
  @include transition(max-width, $transDurationPopup);

  &.Hide {
    max-width: 1px;
    overflow: hidden;
    border-right: none;
  }

  &.Show {
    overflow-y: auto;
    max-width: 280px;
  }

  &.NoScroll {
    overflow: visible;
  }

  &.FullSize {
    margin: 0;
    max-height: unset;
  }
}

.MobileNavContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $colorNeutral000;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  .MobileHeader {
    padding: 14px 16px 13px;
    border-bottom: 1px solid $colorNeutral080;
    display: flex;
  }

  .MobileTitle {
    text-transform: uppercase;
    font-size: $fontSizeTitle;
    line-height: $lineHeightTitle;
    color: $colorNeutral600;
    font-family: $fontFamilyMain;
    font-weight: 600;
    padding: 6px 0 0;
    margin: 2px 2px 2px 0;
  }

  .MobileClose {
    margin: 2px 2px 2px auto;
  }
}

.OpenButton {
  position: absolute;
  top: calc(50% - 16px);
  left: 0;
  z-index: $zIndexLow;
  background: $colorNeutral000;
  border-radius: 0 4px 4px 0;
  padding: 2px;
  cursor: pointer;
  box-shadow: $boxShadowCard;
  @include svgIcon(16px,16px,$colorNeutral800);

  &:hover {
    @include svgIconColor($colorBlue300);
    box-shadow: $boxShadowCardHover;
  }

  &.Revert {
    transform: scale(-1, 1);
    left: 259px;
  }
}

.NotFound {
  padding: 10px 16px;
  font-size: $fontSizeInput;
  line-height: $lineHeightBody;
  color: $colorNeutral600;
  min-width: 280px;
}

.SubLink {
  color: red;
  .Dot {
    border-radius: 2px;
    width: 2px;
    height: 2px;
    padding: 2px;
    margin-right: 16px;
    margin-left: 16px;
    background: $colorNeutral800;
  }
}

.LinkWrap {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 16px;

  font-weight: 400;
  font-size: $fontSizeInput;
  line-height: $lineHeightBody;

  &:hover {
    color: $colorNeutral800;
    background: $colorNeutral070;
    cursor: pointer;

    @include forMedia(sm){
      background: none;
    }
  }

  &.LinkActive {
    background: $colorBlue010;
  }
}

.LinkText {
  display: block;
  color: $colorNeutral800;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
