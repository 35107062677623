@import "../../basics-scss/mixins";

.States {
    border: 1px solid $colorNeutral080;
    border-radius: 4px;
    position: absolute;
    font-family: $fontFamilyMain;
    overflow: hidden;
    max-width: 100%;
}
.StatesWrap {
    position: relative;
    height: 34px;
    margin: 0 0 8px;
}
.Wrapper {
    position: relative;
    overflow: hidden;
    margin: 0 13px;
}
.List {
    white-space: nowrap;
    padding: 6px 0;
    position: relative;
    display: inline-block;
    vertical-align: top;
}
.SliderIsOn {
    .List {
        cursor: move;
    }
    .Wrapper {
        margin: 0 30px;
    }
}
.ListItem {
    display: inline-block;
    vertical-align: top;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    padding: 0;
    color: $colorNeutral090;
    user-select: none;
    position: relative;
    &.current {
        color: $colorBlue500;
    }
    &.complete {
        color: $colorNeutral600;
    }
}
.Controls {

}
.Arrow {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    position: absolute;
    top: 50%;
    margin-top: -15px;
    z-index: 5;
    &:first-child {
        left: 0;
    }
    &:last-child {
        right: 0;
    }
    @include svgIcon(24px,24px,$colorNeutral090);

    &:hover {
        @include svgIconColor($colorNeutral800);
    }
}
.Overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: $zIndexModal;
    cursor: move;
}
.TransitionLeft {
    transition: left .5s ease-in;
}
.IconCheck {
    @include svgIcon(20px,20px,$colorNeutral600);
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
}
.IconArrow {
    @include svgIcon(16px,16px,$colorNeutral080);
    display: inline-block;
    vertical-align: top;
    margin: 2px 16px;
}
.Skeleton {
    &:after {
        @include animatedShadow(2s);
    }
}
.SkeletonItem {
    background: $colorNeutral077;
    border-radius: 4px;
    height: 16px;
    width: 79px;
    display: inline-block;
    vertical-align: top;
    position: relative;
    top: 2px;
}