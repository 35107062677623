@import "mixins";

html, body {
    padding: 0;
    margin: 0;
    height: 100%;
}

body {
    font-size: $fontSizeBody;
    line-height: $lineHeightBody;
    font-family: $fontFamilyMain;
    width: 100%;
    height: 100%;
    color: $colorNeutral800;
    background-color: $colorNeutral000;
    overflow-y: scroll; // скролл нужен для корректного позиционирования выпадающих меню на элементах, расположенных внутри фиксированной панели

    &:before {
        content: "";
        height: 100%;
        float: left;
        width: 0;
        margin-top: -32767px;
    }

    @include forMedia(sm){
        overflow-x: hidden;
    }
}

* {
    box-sizing: border-box;
}

a {
    color: $colorBlue500;
    text-decoration: none;

    img {
        padding: 0;
        margin: 0;
        outline: none;
    }
}

h1 {
    font-family: $fontFamilyHeadline;
    font-size: 28px;
    margin: 0 0 32px;
    font-weight: 600;
}

h2 {
    font-family: $fontFamilyHeadline;
    font-weight: 400;
    font-size: 22px;
    margin: .67em 0;
}

h3 {
    font-size: 1.17em;
    margin: 1em 0
}

h4 {
    font-size: 1em;
    margin: 1.33em 0
}

h5 {
    font-size: .83em;
    margin: 1.67em 0
}

h6 {
    font-size: .75em;
    margin: 2.33em 0
}