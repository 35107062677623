.searchMain {
    flex: 1 1 auto;
    display: flex;
    overflow: hidden;

    .resultsMain {
        flex: 1 1 auto;
        overflow: auto;

        .item {
            display: block;
            font-size: 15px;
            padding: 13px 10px 13px 10px;
        }

        .title {
            border-bottom: 1px solid #cfd4d8;
            font-weight: 700;
            margin-bottom: 10px;
            padding-bottom: 10px;
            padding-left: 10px;
        }

        .record {
            margin: 10px 10px 10px 10px;

            .link {
                font-size: 15px;
                font-weight: 400;
                margin-bottom: 500px;
            }

            .description {
                font-size: 13px;
                color: #71767e;
            }
        }
    }

    .linkWrap {
        position: relative;
    }

    .menu {
        flex: 0 0 auto;
        overflow: auto;
        width: 300px;
        background-color: #fbfcfd;
        //border-right: 1px solid #dfe1e5;

        header {
            font-size: 15px;
            color: #828890;
            padding: 10px 0 10px 20px;
            text-align: left;
            border-bottom: 1px solid #cfd4d8;
        }

        .link {
            cursor: pointer;
            display: block;
            padding: 13px 60px 13px 10px;
            line-height: 1;
            color: #334d6e;
        }

        .label {
            margin-left: 5px;
            display: inline-block;
        }


    }
}

.title.active {
    .arrow {
        transform: rotate(90deg);

        * {
            fill: #17181d
        }
    }
}

.title {
    .arrow {
        display: inline-block;
        line-height: 0;
        margin: 10px;
    }
}
