@import "../../../../basics-scss/mixins";

.Card {
    background: $colorNeutral000;
    box-shadow: $boxShadowCardTaskboard;
    border-radius: 4px;
    padding: 24px 24px 20px 24px;
    margin-bottom: 24px;

    @include forMedia(md) {
        margin-bottom: 16px;
    }

    @include forMedia(xs){
        padding: 16px;
        border-radius: 0;
    }
}

.Header {
    margin-bottom: 18px;
    display: flex;

    .Icon {
        @include svgIcon(20px, 20px, $colorBlue300);
        margin-right: 8px;
    }

    .Link {
        font-size: $fontSizeH4;
        font-weight: 600;
        line-height: $lineHeightH4;
        white-space: nowrap;
        overflow:hidden;
        text-overflow: ellipsis;
        @include linkStyles;
    }
}

.Footer {
    margin-top: 14px;
    padding-top: 8px;
    border-top: 1px solid $colorNeutral080;
}

.Description {
    overflow-x: auto;
}

.DescriptionItem {
    display: inline-block;
    vertical-align: top;
    margin: 0 16px 4px 0;
    color: $colorNeutral600;

    .ItemValue {
        color: $colorNeutral800;
    }
}

.SubItems {
    font-size: $fontSizeCaption;
    line-height: $lineHeightCaption;
}

.matching {
    background: $colorBlue070;
    color: $colorNeutral800;
    font-weight: bold;
}


