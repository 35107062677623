@import "../../basics-scss/mixins";

.menu {
    background: $colorNeutral000;
    border-radius: 4px;
    box-shadow: $boxShadowModal;
    max-width: 250px;
    min-width: 200px;
    z-index: $zIndexContextMenu;
    position: absolute;
    left: 0;
    top: 100%;
    font-size: $fontSizeBody;
    line-height: $lineHeightBody;
    padding: 8px 0;
}

.item {
    position: relative;
    padding: 6px 16px;
    cursor: pointer;
    color: $colorNeutral800;
    display: flex;
    & > .menu {
        left: 100%;
        top: -8px;
        visibility: hidden;
    }
    &:hover {
        background: $colorNeutral070;
        & > .menu {
            visibility: visible;
        }
    }
}

.arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 30px;
    height: 16px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    @include svgIcon(16px,16px);
}
