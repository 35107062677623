@import "../../../basics-scss/mixins";

.CommentBlock {
    display: flex;
}

.CommentBlockSlider {
    border-radius: 4px;
}

.CommentBlockTextarea textarea {
    border: 1px solid $colorNeutral080;
    border-top: 1px solid $colorNeutral010;
    box-sizing: border-box;
    border-radius: 0 0 4px 4px;
    min-height: 50px;
    font-size: $fontSizeInput;
    line-height: 20px;
    padding: 5px 8px;
    color: $colorNeutral800;
    &:hover {
        background: $colorNeutral010;
    }
    &:focus {
        background: $colorNeutral000;
    }
}

.CommentBlockButtonWrap {
    margin-left: 8px;
    flex: 0 0 32px;
    align-self: flex-end;
}

.CommentBlockTextAreaWrap {
    flex: auto;
}
