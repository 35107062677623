@import "../../../basics-scss/mixins";

.EmptyItem {
    background: #FCFCFD;
    border: 2px dashed #D5D8DD;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;

    &.CardOnEnter {
        background: #FCFCFD;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16), 0 4px 4px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        border: 2px dashed #D5D8DD;

        div {
            visibility: hidden;
        }
    }

    .EmptyText {
        pointer-events: none;
        margin: 0 64px 40px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #ABB1BA;
    }

    .AddEmptyItemIcon {
        pointer-events: none;
        margin: 44px 156px 12px;
        @include svgIcon(40px, 40px, #ABB1BA);
    }
}

.Item {
    margin: 8px 0;
    box-shadow: $boxShadowCardTaskboard;
    border-radius: 8px;
    padding: 24px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;

    &:hover {
        box-shadow: $boxShadowCardHover;
    }

    &.CardOnEnter {
        background: #FCFCFD;
        box-shadow: $boxShadowCard;
        border-radius: 8px;
        border: 2px dashed #D5D8DD;

        div {
            visibility: hidden;
        }
    }
}

.ItemHeader {
    margin: 0 0 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ItemOpenedAt {
        color: #ABB1BA;
    }
}

.ItemDescription {
    margin: 7px 0 10px;
    color: #2E3238;
    max-height: 60px;
    overflow: hidden;
    word-wrap: break-word;
    text-overflow: ellipsis;
    font-weight: 600;
}

.ItemTags {
    margin: 10px 0 13px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.ItemAttachments {
    margin: 13px 0 9px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #5C6470;
}

.ItemDelimeter {
    margin: 9px 0;
    height: 1px;
    width: 100%;
    background: #D5D8DD;
}

.ItemUsers {
    margin: 9px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ItemDueDateIcon {
        margin-right: 8px;
        @include svgIcon(22px, 22px, #ABB1BA);
    }

    .ItemDueDate {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #ABB1BA;
    }
}

.DisabledItem {
    margin: 8px 0;
    border: 1px solid $colorNeutral080;
    box-shadow: none;
    border-radius: 8px;
    padding: 24px;
    font-weight: normal;
    font-size: $fontSizeLabel;
    line-height: $lineHeightLabel;

    &.CardOnEnter {
        background: #FCFCFD;
        box-shadow: $boxShadowCard;
        border-radius: 8px;
        border: 2px dashed #D5D8DD;

        div {
            visibility: hidden;
        }
    }

    &:hover {
        background: $colorNeutral060;
    }

    &:active {
        background: $colorNeutral080;
    }
}

.DisabledEmptyItem {
    background: $colorNeutral010;
    border-radius: 8px;
    padding: 68px 48px;
    cursor: pointer;

    .EmptyText {
        font-size: $fontSizeLabel;
        line-height: $lineHeightLabel;
        font-weight: 600;
        text-align: center;
        color: $colorNeutral090;
    }
}
