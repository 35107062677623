.notification {
    position: relative;
    overflow: hidden;
    border-radius: 3px;
    border: 1px solid transparent;
    padding: 5px;

    &.red {
        background-color: #FAE6E6;
        border-color: #F7D8D8;
        color: #9D0010;
    }

    &.blue {
        background-color: #E6F0FA;
        border-color: #CEE3F9;
        color: #004AAD;
    }

    & + .notification {
        margin-top: 5px;
    }
}
.content {
    margin: 3px 0 3px 5px;
}
.close {
    float: right;
    cursor: pointer;
    line-height: 0;
    margin: 5px;
}
