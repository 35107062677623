@import "../../basics-scss/mixins";

.Popup {
    position: absolute;
    background: $colorNeutral000;
    border-radius: 4px;
    box-shadow: $boxShadowCard;
    z-index: $zIndexPopover;

    &.animated {
        @include animateFadeIn();
    }

    @include forMedia(sm) {
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        visibility: visible!important;
    }
}

.PopupTriangle {
    position: absolute;
    z-index: $zIndexPopover;
    @include tooltip-triangle('left');

    &.animated {
        @include animateFadeIn();
    }

    @include forMedia(sm) {
        display: none;
    }
}

.PopupTitle {
    margin: 0;
    padding: 12px 24px;
    background-color: $colorNeutral010;
    border-bottom: 1px solid $colorNeutral080;
    border-radius: 4px 4px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include forMedia(sm) {
        padding: 16px;
        border-radius: 0;
        background-color: transparent;
    }
}
.PopupText {
    padding: 0 14px 0 0;
    font-weight: 600;
    font-size: $fontSizeTitle;
    line-height: $lineHeightTitle;
    color: $colorNeutral800;
    max-width: 306px;

    @include forMedia(sm) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $colorNeutral600;
        text-transform: uppercase;
    }
}

.PopupLink {
    @include forMedia(sm){
        display: none;
    }
}

.PopupContent {
    padding: 24px;
    max-height: 530px;
    overflow-y: auto;
    border-radius: 0 0 4px 4px;
    @include customScrollbar;

    @include forMedia(sm){
        border-radius: 0;
        padding: 8px 16px 48px;
        max-height: none;
        position: absolute;
        top: 64px;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.preloader {
    position: relative;
    background: $colorNeutral000 url("/assets/img/page-loader.gif") no-repeat center;
    background-size: 64px 64px;
    height: 200px;
}

.PopupMobClose {
    display: none;
    @include forMedia(sm){
        display: flex;
    }
}

.PopupMobLink {
    position: absolute;
    display: none;
    left: 16px;
    right: 16px;
    bottom: 16px;
    button {
        width: 100%;
        box-shadow: $boxShadowCard;
    }
    @include forMedia(sm){
        display: block;
    }
}