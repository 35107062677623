@import "../../../basics-scss/mixins";

.Breadcrumbs {
  display: flex;
  align-items: flex-start;

  a {
    margin: 0 10px 0 8px;
    cursor: pointer;
  }
}

.BreadcrumbsList {
  padding: 4px 0 4px 3px;
  li {
    font-size: $fontSizeBody ;
    margin: 0 1px 0;
    display: inline-block;
    vertical-align: top;
    max-width: 200px;
    height: 24px;
    line-height: 24px;

    a {
      margin: 0;
    }

    &:nth-of-type(n + 2) {
      position: relative;
    }
  }

  @include forMedia(sm){
    white-space: nowrap;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.BreadcrumbsLink {
  color: $colorNeutral600;
  cursor: pointer;
  text-decoration: none;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    color: $colorBlue300;
  }

  &.ForRemove {
    color: $colorNeutral090;
    text-decoration: line-through;
  }
}

.BreadcrumbsChevron {
  cursor: pointer;
  text-decoration: none;
  margin: 0;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include svgIcon(12px,12px,$colorNeutral600);

  &:hover {
    @include svgIconColor($colorBlue300);
  }
}

.BreadcrumbsChevronDisabled {
  text-decoration: none;
  margin: 0;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include svgIcon(12px,12px,$colorNeutral600);
}

.BreadcrumbsLinkDisabled {
  color: $colorNeutral600;
  text-decoration: none;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.BreadcrumbsOperatorDisabled {
  text-decoration: none;
  margin: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Filter {
  flex: 0 0 32px;
}

.chevronRight {
  line-height: 0;
  @include svgIcon(12px,12px);
}
