@import "/basics-scss/mixins";

.container {
    position: relative;
}
.input {
    @include stringInputStyles();
    text-align: left;
    position: relative;
    padding: 0;
    line-height: 30px;

    &[disabled],
    &[readonly] {
        .inputChevron {
            @include svgIconColor($colorNeutral090);
        }
    }

    &.IsWarning {
        border-color: $colorRed300;

        &:hover {
            background-color: $colorNeutral060;
        }

        &:focus {
            background-color: $colorNeutral000;
            border-color: $colorBlue150;
            box-shadow: inset 0 0 1px 1px $colorBlue150;
        }
    }

    &.placeholder {
        color: $colorNeutral090;
    }
}
.inputText {
    display: block;
    position: absolute;
    top: 0;
    left: 8px;
    right: 20px;
    height: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.inputChevron {
    @include svgIcon(16px,16px,$colorNeutral800);
    position: absolute;
    right: 8px;
    top: 7px;
}
.menuItem {
    padding: 6px 16px;
    cursor: pointer;
    color: $colorNeutral800;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: $fontSizeBody;
    line-height: $lineHeightBody;

    &.active {
        background-color: $colorNeutral070;
    }

    &.selected {
        background-color: $colorBlue010;
    }
}
.menu {
    @include dropDown;
    @include customScrollbar;
    max-height: 250px;
    overflow-y: auto;
    padding: 8px 0;
}
