@import "../../../basics-scss/mixins";

.ButtonsPanel{
  display: flex;
  position: relative;
  flex: 1 0 auto;
  justify-content: center;
  flex-flow: row nowrap;
  align-items: flex-end;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.01em;
  padding: 14px 0;
  height: 68px;
}

.ButtonCancel{
  display: flex;
  width: 136px;
  height: 32px;
  background: rgba(255, 255, 255, 0.45);
  border: 1px solid $colorNeutral080;
  box-sizing: border-box;
  border-radius: 3px;
  margin: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.ButtonSave{
  margin: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}
