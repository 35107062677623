@import "../../../../basics-scss/mixins";

.ListSorting {

}

.ListSortingItem {
  font-weight: 600;
  font-size: $fontSizeLabel;
  line-height: $lineHeightLabel;
  padding: 8px 0;
  white-space: nowrap;
  color: $colorNeutral600;

  &:first-child {
    padding-left: 8px;
  }

  &:last-child {
    padding-right: 8px;
  }
}

.Title {
  display: inline-flex;
  vertical-align: top;
  cursor: pointer;
  padding: 6px 8px;
  border-radius: 4px;
  &:hover {
    background: $colorNeutral070;
  }
  &:active {
    background: $colorBlue010;
  }
}

.Icon {
  margin-left: 8px;
  position: relative;
  top: 3px;
  @include svgIcon(16px, 16px, $colorNeutral600);
}
