@import "../../../../basics-scss/mixins";

.Message {
    font-size: $fontSizeCaption;
    line-height: $lineHeightCaption;
    margin-top: 4px;
    color: $colorNeutral600;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    &.error {
        .Icon {
            @include svgIconColor($colorRed500);
        }
    }

    &.info {
        .Icon {
            @include svgIconColor($colorBlue500);
        }
    }

    &.warning {
        .Icon {
            @include svgIconColor($colorOrange500);
        }
    }

    &.success {

    }
}

.Icon {
    margin-right: 4px;
    @include svgIcon(16px, 16px);
}
