@import "../../basics-scss/mixins";

.map {
    position: relative;
    border: 1px solid lightgray;
    background: #FAFAFA no-repeat center;
}

.preloader {
    position: relative;
    border: 1px solid lightgray;
    background: #FAFAFA url("/assets/img/page-loader.gif") no-repeat center;
    background-size: 64px 64px;
    height: 200px;
}

.container {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
}

.node {
    position: absolute;
    z-index: $zIndexLow;
    padding: 3px;
    &.red .icon {
        border-color: #FF0700;
    }
    &.orange .icon {
        border-color: #FFD500;
    }
    &.blue .icon {
        border-color: #278efc;
    }
    &.green .icon {
        border-color: #30BF39;
    }
    &.gray .icon {
        border-color: #808080;
    }
}

.icon {
    background: $colorNeutral000;
    border-radius: 4px;
    border: 3px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    font-size: 20px;

    @include svgIconColor($colorNeutral800);
}

.description {
    position: absolute;
    left: 60px;
    top: 5px;
    width: 160px;
}

.title {
    line-height: 1;
    color: #000000;
    display: flex;
    align-items: center;
    min-height: 26px;
    a {
        @include linkStyles;
    }
}

.category {
    font-weight: bolder;
    color: #000000;
    margin-bottom: 2px;
    a {
        color: #000000;
    }
}
