@import "/basics-scss/mixins";
.wrap {
    position: absolute;
    right: 24px;
    top: 24px;
    @include forMedia(xs){
        right: 16px;
        top: 16px;
    }
}

.container {
    position: relative;
}

.title {
    line-height: 20px;
    height: 20px;
    color: $colorNeutral800;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
}

.helpIcon {
    display: inline-block;
    margin-left: 10px;
    vertical-align: top;
    @include svgIcon(20px,20px,$colorNeutral800);
}

.helpText {
    font-size: $fontSizeBody;
    line-height: $lineHeightBody;
    color: $colorNeutral800;
    p {
        margin: 0 0 8px;
        &:last-child {
            margin: 16px 0 0;
        }
    }
    a {
        @include linkStyles;
    }
}

.helpContent {
    max-height: 500px;
    overflow-y: auto;
    padding: 16px;
    width: 272px;
    @include dropDown;
    @include customScrollbar;
}