@import "../../basics-scss/mixins";

.Main{
  background-color: #E8E8E8;
}

.PanMode{
  cursor: grab;
}

.PanModeGrabbing{
  cursor: grabbing;
}

.ContextMenu {
  position: absolute;
  background: $colorNeutral000;
  width: 163px;
  padding: 7px 0;
  box-shadow: $boxShadowModal;
  border-radius: 4px;
  z-index: $zIndexContextMenu;
  user-select: none;

  li {
    height: 26px;
    padding: 4px 12px 4px 12px;
    line-height: 18px;
    color: $colorNeutral800;
    cursor: pointer;
    &:hover {
      background: $colorNeutral070;
    }
  }
}