@import "../../../../basics-scss/mixins";

.Divider {
  border-top: 1px solid $colorNeutral080;
  min-width: 1px;
  width: 1px;
  height: 32px;
  background-color: $colorNeutral080;
  cursor: default;
  margin: 0 4px;
}

.Toolbar {
  padding: 3px 4px;
  height: 40px;
  display: flex;
  align-items: center;
  background: $colorNeutral000;
  border: 1px solid $colorNeutral080;
  border-bottom: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  overflow: auto;

  &.RemoveLeftBorder {
    border-left: unset;
    border-top-left-radius: initial;
    border-top-right-radius: initial;

    @include forMedia(sm){
      border-left: 1px solid $colorNeutral080;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }
}

.ToolbarItem {
  margin-right: 4px;
  margin-left: 4px;

  .Active {
    background: $colorBlue010;

    &:hover{
      border-color: transparent;
    }
  }
}

.PopupToolbar {
  display: flex;
  margin-bottom: 1px;
  background: $colorNeutral000;
  align-items: center;
  padding: 4px 0;
  position: absolute;
  z-index: 10;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  opacity: 0;
  box-shadow: $boxShadowCard;
  border-radius: 4px;
  transition: opacity 0.5s;
  height: 40px;
}

.KebabMenu {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  gap: 8px;
  background: $colorNeutral000;
  box-shadow: $boxShadowCard;
  border-radius: 4px;
}

.Badge {
  margin: 0 4px;
}
