@import "../../../basics-scss/mixins";

.selectCheck {
    margin-right: 8px;
    margin-left: 7px;
}

.selectAction {
    display: inline-block;
    vertical-align: top;
    max-width: 250px;
    min-width: 200px;
    height: 20px;
    padding: 0 20px 0 8px;
    font-size: $fontSizeInput;
    color: $colorNeutral800;
    background: $colorNeutral010 url("/assets/img/select-arrow.svg?inline") no-repeat right 8px center;
    border: 1px solid $colorNeutral080;
    border-radius: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    //line-height: 30px;
    &::-ms-expand {
        display: none;
    }

    &[disabled] {
        color: #86888c;
        cursor: not-allowed;
        background-color: #dfe1e5;
        border-color: #dfe1e5;
    }
}

.wrap {
    display: flex;
    align-items: center;
    padding: 12px 0;
    line-height: 0;
}

.text {
    padding-left: 2px;
    font-size: $fontSizeBody;
    line-height: 19px;
    color: $colorNeutral800;
}