@import "../../basics-scss/mixins";

.OtherUserBlock {
  animation: slideOutRight $transDurationPopup $transTiming;
  width: 0;
  opacity: 0;
  overflow: hidden;
  transition: all $transTiming $transDurationPopup;
}

@keyframes slideInRight {
  from {
    transform: translateX(32px);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes dissolve {
  from {
    box-shadow: 0 0 0 0 #FF9F19;
  }

  17% {
    box-shadow: 0 0 0 2px #FF9F19;
  }

  34% {
    box-shadow: 0 0 0 6px rgba(255, 159, 25, 0.6), 0 0 0 2px #FF9F19;
  }

  50% {
    box-shadow: 0 0 0 10px rgba(255, 159, 25, 0.6), 0 0 0 6px rgba(255, 159, 25, 0.6), 0 0 0 2px #FF9F19;
  }

  66% {
    box-shadow: 0 0 0 6px rgba(255, 159, 25, 0.6), 0 0 0 2px #FF9F19;
  }

  83% {
    box-shadow: 0 0 0 2px #FF9F19;
  }

  to {
    box-shadow: 0 0 0 0 #FF9F19;
  }
}


@keyframes slideOutRight {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(32px);
  }
}

@keyframes slideInRightMore {
  from {
    transform: translateX(-10px) scale(0);
  }

  to {
    transform: translateX(0) scale(1);
  }
}

@keyframes slideOutRightMore {
  from {
    transform: translateX(0) scale(1);
  }

  to {
    transform: translateX(-10px) scale(0);
  }
}

.SlideInRight {
  animation: slideInRight $transDurationPopup $transTiming;
  width: auto;
  opacity: 1;
  overflow: visible;
}

.OtherUserContainer {
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  min-height: 32px;
  cursor: pointer;
  gap: 8px;
  padding: 4px;
  display: flex;

  &:hover {
    background: $colorNeutral070;
  }

  &:active {
    background: $colorBlue010;
  }

  &:focus {
    padding: 2px;
    border: 2px solid $colorBlue150;
  }

  &.Selected {
    padding: 2px;
    border: 2px solid $colorBlue150;
  }
}

.MoreBlock {
  padding: 2px 4px;
  gap: 8px;
  margin-left: -16px;

  min-width: 25px;
  height: 24px;
  background: $colorBlue100;
  border-radius: 16px;

  width: 0;
  opacity: 0;
  overflow: hidden;
}

.MoreBlockShow {
  width: auto;
  opacity: 1;
  overflow: visible;
  transition: all $transTiming $transDurationPopup;
}

.SlideInRightMore {
  width: auto;
  opacity: 1;
  overflow: visible;
  animation: slideInRightMore $transDurationPopup $transTiming, dissolve .8s $transTiming .5s 2;
  transition: all $transTiming $transDurationPopup;
}

.MoreText {
  font-style: normal;
  font-weight: 400;
  font-size: $fontSizeTitle;
  line-height: $lineHeightTitle;
  align-items: center;
  color: $colorNeutral800;
}

.DropDown {
  @include dropDown;
}
.List {
  min-width: 280px;
  max-height: 250px;
  overflow-y: auto;
  padding: 8px 0;
  @include customScrollbar;
}

.MenuHeader {
  margin: 0 16px 8px;
  padding-bottom: 8px;
  font-weight: 600;
  font-size: $fontSizeLabel;
  line-height: $lineHeightLabel;
  border-bottom: 1px solid $colorNeutral080;
  color: $colorNeutral800;

  @include forMedia(sm){
    display: none;
  }
}

.Ava {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background: no-repeat center;
  background-size: cover;
  overflow: hidden;
  @include svgIcon(24px,24px);
}

.Dissolve {
  opacity: 1;
  overflow: visible;
  animation: dissolve .8s $transTiming .5s 2;
}

.Width0 {
  width: 0;
}

.Width32 {
  width: 32px;
}

.Width49 {
  width: 49px;
}

.Width56 {
  width: 56px;
}