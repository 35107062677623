@import "../../../basics-scss/mixins";

.Link {
    display: block;
    @include svgIcon(16px,16px,$colorNeutral090);
    padding: 1px 0 1px 1px;

    &:hover {
        @include svgIconColor($colorBlue300);
    }
}

.LinkWrap {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    z-index: $zIndexLow;
}

.Text {
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    padding-right: 20px;
    min-height: 19px;
}