.Template {

}

.TemplateControls {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #aaa;

  .Button {
    margin: 0 2px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.TemplateControlsSep {
  display: inline-block;
  vertical-align: top;
  width: 2px;
  height: 24px;
  background-color: #D0D0D0;
  border-right: 1px solid #AAA;
  margin: 0 5px;
}

.TemplateConditions  {

}

.TemplateSorts {
  padding: 10px;
}

.TemplateSortLabel {
  color: #278efc;
}

.TemplateLabel {
  font-size: 14px;
  margin-bottom: 10px;
  color: #707070;
}

.SubTemplateLabel {
  font-size: 14px;
  margin-bottom: 10px;
  color: #707070;
  padding: 10px 0;
  border-top: 1px solid #D0D0D0;
}

.SaveToolbar {
  padding: 10px 10px;
  display: flex;
  align-items: center;

  input {
    width: 250px;
    margin: 0 5px;
  }
}

.BorderBottom {
  border-bottom: 1px solid #D0D0D0;
}

.Row {
  display: flex;
  margin: 0 -2px 10px;
  padding: 10px;
}

.Fields {
  flex: 3;
  margin-right: 5%;
  display: flex;
  position: relative;
  align-items: center;
}

.Buttons {
  flex: .8;

  .Button {
    margin: 0 2px;

    &:first-child {
      margin-left: 0;
    }
  }
}
