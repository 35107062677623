@import "../../basics-scss/mixins";

.Tabs {
  margin: 24px 0 16px;
}

.RelatedTabs {

}

.TabsHead {
  position: relative;
  z-index: $zIndexLow;
  margin-bottom: -1px;
}

//Для скриптов, codeMirror не успевает прогрузится в табах
.TabsInactive {
  position: relative;
  visibility: hidden;
  overflow: hidden;
  height: 0;

  // отсупы как у активной вкладки .TabsItem 16px(padding) + 1px(border).
  // нужно для корректных расчётов размеров полей Textarea
  padding: 0 17px;
}

.TabsHeadItem {
  padding: 0 24px;
  height: 32px;
  font-size: $fontSizeLabel;
  line-height: 30px;
  display: inline-block;
  vertical-align: top;
  color: $colorNeutral600;
  border-radius: 4px 4px 0 0;
  position: relative;
  user-select: none;
  border: 1px solid transparent;
  border-bottom: 1px solid $colorNeutral080;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  @include transition(background-color);

  &:not(.TabsHeadItemActive) {
    cursor: pointer;
    &:hover {
      @include transition();
      background: $colorNeutral070;
      border: 1px solid $colorNeutral070;
      border-bottom: 1px solid $colorNeutral080;
    }
  }
  @include forMedia(xs){
    padding: 0 12px;
  }
}

.TabsHeadItemActive {
  font-weight: 600;
  border: 1px solid $colorNeutral080;
  border-bottom: none;
  color: $colorBlue500;
  background: $colorNeutral000;
  z-index: $zIndexLow;
}

.TabsItem {
  padding: 16px;
  background: $colorNeutral000;
  border: 1px solid $colorNeutral080;
  border-radius: 4px;
  position: relative;
}

.TabsItemFirst {

}

.Required {
  > span {
    margin-right: 5px;
    color: $colorRed500;
  }
}

.Warning {
  color: $colorRed500;
  > span {
    color: $colorRed500;
  }
}

.SectionWidgetHeading {
  margin-top: 32px;
}
