@import "../../../basics-scss/mixins";

.LeftPanel {
    display: flex;
    flex: 0 0 274px;
    flex-flow: column nowrap;
    justify-content: flex-start;
    width: 274px;
    padding: 42px 0 0 0;
    box-sizing: border-box;
    background: $colorNeutral000;
    position: fixed;
    z-index: $zIndexLow;
    height: 100%;
    border-right: 1px solid #DFE1E5;
}

.UpperPanel{
    display: flex;
    flex-flow: row nowrap;
    border-top: 1px solid $colorNeutral080;
}

.BurgerIcon{
    margin: 12px 0 6px 12px;
    cursor: pointer;
    user-select: none;
    width: 18px;
    height: 18px;
    @include svgIcon(18px,18px);
}

.Tabs{
    display: flex;
    flex-flow: row nowrap;
    padding: 12px 4px 6px 4px;
    width: 100%;
    justify-content: space-evenly;
}

.Tab{
    display: flex;
    margin: 0 4px;
    user-select: none;
    color: #a9acbe;
}

.ActiveTab{
    display: flex;
    padding: 0 4px;
    user-select: none;
    color: $colorNeutral800;
}

.SelectableTab{
    cursor: pointer;
}

.Search{
    display: flex;
    position: relative;
    border-bottom: 1px solid $colorNeutral080;
    border-top: 1px solid $colorNeutral080;
    color: #A9ACBE;
    flex: 0 0 34px;
    user-select: none;
    input {
        border: none;
        color: $colorNeutral800;
        width: 85%;
        height: 18px;
        position: absolute;
        top: 6px;
        left: 12px;
        @include placeholder();
    }
}

.IconSearch{
    position: absolute;
    left: 251px;
    top: 11px;
    width: 12px;
    height: 12px;
    @include svgIcon(12px,12px);
    user-select: none;
    cursor: pointer;
}

.CloseButton{
    position: fixed;
    z-index: $zIndexLow;
    left: 278px;
    top: 60px;
    height: 18px;
    width: 18px;
    cursor: pointer;
    user-select: none;
    background: $colorNeutral000;
    box-shadow: $boxShadowCard;
    border-radius: 4px;
    @include svgIcon(18px,18px);
}

.OpenButton{
    position: fixed;
    z-index: $zIndexLow;
    left: 4px;
    top: 63px;
    height: 18px;
    width: 18px;
    cursor: pointer;
    user-select: none;
    background: $colorNeutral000;
    box-shadow: $boxShadowCard;
    border-radius: 4px;
    @include svgIcon(18px,18px);
}

.Menu {
    user-select: none;
    position: absolute;
    background: $colorNeutral000;
    width: 163px;
    padding: 7px 0;
    box-shadow: $boxShadowModal;
    border-radius: 4px;
    z-index: $zIndexMiddle;
    left: 15px;
    top: 80px;

    li {
        padding: 4px 12px 4px 12px;
        line-height: 18px;
        color: $colorNeutral800;
        cursor: pointer;
        &:hover {
            background: #E8F0FE;
        }
    }
}