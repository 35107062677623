.Workflow {
  display: flex;
  overflow: hidden;
}

.Welcome{
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  background: #FBFCFC url("/assets/img/workflow/workflow_background.jpg") no-repeat center top;
  align-items: center;
  min-height: 800px;
}