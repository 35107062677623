@import "../../../../basics-scss/mixins";

.menu {
    //font-size: 14px;
}

.linkWrap {
    position: relative;
    display: flex;
    padding-right: 16px;
    align-items: center;

    &:hover {
        color: $colorNeutral800;
        background: $colorNeutral070;

        .linkBtn {
            display: flex;
        }
    }
}

.item {
    &.active {
        & > .linkWrap > .link {
            color: $colorNeutral800;
            font-weight: 600;
            .arrow {
                transform: rotate(90deg);
                * {
                    fill: $colorNeutral800;
                }
            }
            &.itemSingle {
                font-weight: 400;
            }
        }


        & > .submenu {
            overflow: hidden;
            max-height: 9999px;
            transition: max-height 200ms cubic-bezier(1, 0, 1, 0);
        }
    }

    &.SubMenu {
        padding-left: 22px;
    }
}

.parent {

}

.link {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px 8px 10px 41px;
    line-height: 20px;
    color: $colorNeutral800;
    flex: auto;

    &.itemSingle {
        padding-left: 16px;
    }

    @include forMedia(sm){
        &:hover {
            background: none!important;
        }
        & + * > * {
            opacity: 1 !important;
        }
    }
}

.linkBtn {
    cursor: pointer;
    flex: 0 0 16px;
    @include svgIcon(16px,16px,$colorNeutral090);
    display: none;

    &:last-child {
        margin-left: 8px;
    }

    &.active {
        display: flex;
        @include svgIconColor($colorBlue300);
    }
}


.linkIcon {
    flex: 0 0 16px;
    margin-right: 8px;
    @include svgIcon(16px,16px,$colorNeutral800);
}

.linkText {
    flex: auto;
}

.linkLabel {
    position: absolute;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.linkContainer {
    position: relative;
    min-height: 20px;
    line-height: 20px;
    flex: auto;
}

.submenu {
    overflow: hidden;
    max-height: 0;
    transition: max-height 200ms cubic-bezier(0, 1, 0, 1);

    .item.active {
        & > .linkWrap > .link {
            color: $colorNeutral800;
            font-weight: 400;
        }

        & > .submenu {
            overflow: hidden;
            max-height: 9999px;
            transition: max-height 200ms cubic-bezier(1, 0, 1, 0);
        }
    }
}

.arrow {
    position: absolute;
    line-height: 0;
    left: 22px;
    top: 16px;
}

.noResults {
    color: $colorNeutral600;
    font-size: $fontSizeBody;
    line-height: $lineHeightBody;
    padding: 26px 16px;
}