@import '../../basics-scss/mixins';

.Loader {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $colorNeutral000;
}
.Content {
    padding: 10px;
}
.LoaderIcon {
    width: 64px;
    height: 64px;
    background: url("/assets/img/page-loader.gif") no-repeat 0 0;
    background-size: 100% 100%;
}