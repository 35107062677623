@import "../../../basics-scss/mixins";

.Select {
    position: relative;
    padding-right: 48px;
    margin-bottom: 8px;

    &.disabled {
        .Fields {
            @include readonlyInputStyles;
        }
        .Item {
            cursor: default!important;
            background: $colorNeutral040!important;
        }
        .Selected {
            background: $colorNeutral080!important;
        }
        .RemoveBtn {
            visibility: hidden;
        }
    }

    .Fields {
        display: block;
        width: 300px;
        height: 134px;
        overflow-y: auto;
        @include customScrollbar;
        color: $colorNeutral800;
        background-color: $colorNeutral000;
        background-image: none;
        border: solid 1px $colorNeutral080;
        border-radius: 4px;
        padding: 8px 0;
    }

    .PickButtons {
        position: absolute;
        right: 0;
        top: 9px;
    }

    .PickButton {
        margin: 0 0 8px 0;
    }
}

.Item {
    user-select: none;
    color: $colorNeutral800;
    padding: 6px 56px 6px 16px;

    &.Selected {
        background: $colorBlue010!important;
    }
}

.DragAvatar {
    position: absolute;
    z-index: $zIndexModal;
    pointer-events: none;
    user-select: none;
    color: $colorNeutral800;
    padding: 6px 56px 6px 16px;
}

.Spacer {
    display: block;
    cursor: default;
    user-select: none;
    height: 32px;
}

.SelectWrap {
    position: relative;

    &.HoverClass {
        &:hover {
            .Item {
                cursor: pointer;
                color: $colorNeutral800;
                background: $colorNeutral070;
            }

            & > * {
                opacity: 1 !important;
            }
        }
    }
}

.RemoveBtn {
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 8px;
    opacity: 0;
    @include transition(opacity);
    @include svgIcon(16px, 16px, $colorNeutral800);

    &:hover {
        @include svgIconColor($colorRed300);
    }
}

.Label {
    @include formLabel;
}

.Text {
    font-size: $fontSizeBody;
    line-height: $lineHeightBody;
}

.Input {
    @include stringInputStyles;
}

.Buttons {
    margin-top: 24px;
    text-align: right;

    & > * {
        margin-left: 8px;
    }
}
