@import "../../../basics-scss/mixins";

.Search{
  display: flex;
  flex-flow: row nowrap;
  width: 250px;
  margin: 4px 0;
  padding: 4px;
  border-bottom: 1px solid $colorNeutral800;
  &:hover{
    .Input{
      &::placeholder{
        color: $colorNeutral800;
      }
    }
    .IconSearch {
      width: 16px;
      height: 16px;
      cursor: pointer;
      @include svgIcon(16px,16px,$colorNeutral800);
    }
  }
}

.Input{
  width: 100%;
  padding: 0;
  border: 0;
  background-color: transparent;
  line-height: 24px;
  height: 24px;
  color: $colorNeutral800;
  &::placeholder {
    color: $colorNeutral090;
  }

}

.IconSearch {
  margin: 4px 0 4px 4px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  @include svgIcon(16px,16px,$colorNeutral800);
}