@import "../../../basics-scss/mixins";

.Container {
    margin: -8px 0 24px;
}

.MessageText {
    color: $colorRed500;
    font-size: $fontSizeCaption;
    line-height: $lineHeightCaption;
}

.Icon {
    display: inline-block;
    vertical-align: top;
    margin-right: 8px;
    @include svgIcon(16px,16px,$colorRed500);
}