@import "../../basics-scss/mixins";

.uiButton {
    display: inline-block;
    vertical-align: top;
}

.link {
    display: inline-block;
    color: inherit;
}