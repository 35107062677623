@import "../../basics-scss/mixins";

.all {
    position: relative;
}

.actions {
    margin-top: 60px;

    & > * {
        margin-right: 5px;
    }
}

.actionsTop {
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    .title {
        margin-right: 60px;
    }

    .button {
        margin-right: 8px;
    }
}

.bottom {
    max-width: 400px;
}
