@import "../../../../basics-scss/mixins";

.Decoration {
    padding: 0;
    margin-left: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: $fontSizeTitle;
    text-align: center;
    align-self: flex-start;
    &.hasPadding {
        padding: 0 6px;
    }
}