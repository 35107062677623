@import "../../../basics-scss/mixins";

.item {
    display: block;
    background: $colorNeutral000;
    padding: 24px;
    border-radius: 4px;
    position: relative;
    box-shadow: $boxShadowCardTaskboard;
    min-height: 144px;

    &:hover {
        box-shadow: $boxShadowCardHover;
    }

    @include forMedia(sm){
        padding: 16px;
        min-height: 64px;
    }
}

.header {
    display: flex;
    margin-bottom: 8px;

    @include forMedia(sm){
        margin-bottom: 0;
        align-items: center;
    }
}

.headerTitle {
    flex: auto;
    display: flex;
    align-items: center;
    margin-right: 16px;

    @include forMedia(sm){
        margin-right: 24px;
    }
}

.headerIcon {
    flex: 0 0 48px;

    @include forMedia(sm){
        flex-basis: 32px;
    }
}

.title {
    color: $colorNeutral800;
    font-family: $fontFamilyHeadline;
    font-weight: 600;
    font-size: $fontSizeH3;
    line-height: $lineHeightH3;

    @include forMedia(xs){
        font-family: $fontFamilyMain;
        font-size: $fontSizeH4;
        line-height: $lineHeightH4;
    }
}

.text {
    color: $colorNeutral600;
    font-size: $fontSizeBody;
    line-height: $lineHeightBody;

    @include forMedia(sm){
        display: none;
    }
}

.icon {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    svg, img {
        display: block;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }

    @include forMedia(sm){
        width: 32px;
        height: 32px;
    }
}

