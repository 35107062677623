@import "../../../basics-scss/mixins";

.Navigation {
    padding: 184px 0 24px 0;
    display: flex;
}

.NavigationIndex{

}

.NavigationNew {
    justify-content: space-between;
    width: 401px;
}

.NavigationOpen {
    justify-content: space-between;
    width: 56%;
}

.NavLink{
    font: 13px $fontFamilyMain;
    line-height: 18px;
    color: #6E728F;
    cursor: pointer;
    user-select: none;
}

.AboutNavLink{
    color: #0F8AF9;
    margin: 0 25px 0 0;
    &:hover {
        text-decoration: underline;
    }
}

.GoBackLink{
    font-weight: 600;
    margin: 0 0 0 4px;
}

.GoBackPanel {
    display: flex;
    align-items: center;
}

.GoBackIcon {
    width: 16px;
    height: 16px;
    cursor: pointer;
    @include svgIcon(16px,16px,#6E728F);
}

.MainTitle{
    padding: 24px 0 10px 0;
    font: 600 40px $fontFamilyHeadline;
    line-height: 49px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.01em;
    color: $colorNeutral800;
}

.WorkflowDescription{
    padding: 10px 0 24px 0;
    font: 15px $fontFamilyMain;
    line-height: 20px;
    text-align: center;
    color: $colorNeutral800;
}

.MainPageInstructions{
    padding: 24px 0 24px 0;
    font: 13px $fontFamilyMain;
    line-height: 18px;
    text-align: center;
    color: #6E728F;
}

.MainPageActionsPanel{
    display: flex;
    padding: 24px 0 24px 0;
    flex-flow: row nowrap;
}

.MainPageActionsButtonPrimary{
    height: 32px;
    background: #0F8AF9;
    color: $colorNeutral000;
    border-radius: 4px;
    margin: 0 4px;
    padding: 6px 16px;
    font: 14px $fontFamilyMain;
    font-weight: 600;
    line-height: 19px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.01em;
    cursor: pointer;
    user-select: none;
    &:hover {
         background: #0A6AFE;
         color: $colorNeutral000;
     }
}

.MainPageActionsButtonDefault{
    height: 32px;
    background: $colorNeutral000;
    color: #6E728F;
    border: 1px solid $colorNeutral080;
    border-radius: 4px;
    margin: 0 4px;
    padding: 6px 16px;
    font: 14px $fontFamilyMain;
    font-weight: 600;
    line-height: 19px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.01em;
    cursor: pointer;
    user-select: none;
    &:hover {
        border: 1px solid $colorNeutral090;
        color: $colorNeutral800;
    }
}

