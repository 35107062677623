@import "../../../basics-scss/mixins";

.messageContainer {
    margin-bottom: 8px;

    &:last-child {
        margin-bottom: 0;
    }
}

.message {
    padding: 16px;
    border-radius: 4px;
    width: 300px;
    position: relative;
    box-shadow: $boxShadowCard;
    background: $colorNeutral000;
}

.messageIcon {
    @include svgIcon(24px,24px);
    margin-right: 8px;
    flex: 0 0 24px;
    &.error {
        @include svgIconColor($colorRed500);
    }
    &.success {
        @include svgIconColor($colorGreen500);
    }
    &.info {
        @include svgIconColor($colorBlue500);
    }
    &.warning {
        @include svgIconColor($colorOrange500);
    }
}

.messageText {
    font-size: $fontSizeBody;
    line-height: $lineHeightBody;
    max-height: $lineHeightBody*3;
    word-break: break-word;
    overflow: hidden;
    flex: auto;
    color: $colorNeutral800;
    &.expanded {
        max-height: none;
    }
}

.messageContent {
    display: flex;
    align-items: center;
}

.messageControls {
    flex: 0 0 20px;
    margin-left: 8px;
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    &.offsetTop {
        margin-top: 2px;
    }
}

@keyframes slideInRight {
    from {
        transform: translate3d(100%, 0, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

.slideInRight {
    animation-name: slideInRight;
    animation-duration: .3s;
    animation-timing-function: ease-in;
}

@keyframes bounceOutRight {
    20% {
        opacity: 1;
        transform: translate3d(-20px, 0, 0);
    }

    to {
        opacity: 0;
        transform: translate3d(2000px, 0, 0);
    }
}

.bounceOutRight {
    animation-name: bounceOutRight;
    animation-duration: 1s;
}