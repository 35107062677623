@import "../../../../../basics-scss/mixins";

.Input {
    flex: 1;
    select {
        width: 100%;
        height: 32px;
        padding: 0 20px 0 8px;
        font-size: $fontSizeInput;
        color: $colorNeutral800;
        background: $colorNeutral010 url("/assets/img/select-arrow.svg?inline") no-repeat right 8px center;
        border: 1px solid $colorNeutral080;
        border-radius: 4px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        line-height: 30px;// чтобы не срезались буквы в chrome
        &::-ms-expand {
            display: none;
        }
        &[disabled] {
            color: #86888c;
            cursor: not-allowed;
            background-color: #dfe1e5;
            border-color: #dfe1e5;
        }
        &[multiple] {
            padding: 0;
            background: $colorNeutral000;
            color: #334d6e;
            @include customScrollbar;

            option {
                padding: 0 8px 0;
                &:hover {
                    background: #e8f0fe;
                    color: #334d6e;
                }
            }
        }
    }

    &.warningInput {
        button {
            border-color: $colorRed400;
        }
    }
}
