.wrap {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.defaultBg {
    background: #e85341; /* Old browsers */
    background: -moz-linear-gradient(-45deg,  #e85341 0%, #ba3862 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg,  #e85341 0%,#ba3862 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg,  #e85341 0%,#ba3862 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e85341', endColorstr='#ba3862',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
