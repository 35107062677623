@import "../../basics-scss/mixins";

.container {
    position: fixed;
    bottom: 8px;
    right: 8px;
    z-index: $zIndexMessages;
}

.hideAll {
    width: 100%;
    margin-bottom: 8px;
    box-shadow: $boxShadowCard;
    border: none;
}
