.cke {
    border-radius: 4px;
    border-color: #D5D8DD;
}
.cke .cke_inner {
    border-radius: 4px;
}
.cke .cke_top {
    border-radius: 4px 4px 0 0;
}
.cke .cke_bottom {
    border-radius: 0 0 4px 4px;
}
.cke .cke_toolbar {
    border: 1px solid #D5D8DD;
    margin: 0 4px 4px 0;
    padding: 2px;
}
.cke .cke_toolgroup {
    margin: 0;
    padding: 0;
}
.cke .cke_toolgroup a.cke_button:last-child:after {
    display: none;
}
.cke a.cke_button {

}
.cke a.cke_button_off:hover,
.cke a.cke_button_off:focus,
.cke a.cke_button_off:active {
    /*border-color: #e5e5e5;*/
}
.cke a.cke_button_on {
    /*border-color: #fff;*/
}
.cke a.cke_combo_button {
    margin-left: 0;
    padding: 1px;
}
.cke .cke_combo_text {
    line-height: 24px;
}
.cke .cke_toolbar_start+.cke_combo_on a.cke_combo_button,
.cke .cke_toolbar_start+.cke_combo_off a.cke_combo_button:hover,
.cke .cke_toolbar_start+.cke_combo_off a.cke_combo_button:focus,
.cke .cke_toolbar_start+.cke_combo_off a.cke_combo_button:active {
    margin-left: 0;
    padding: 0;
}
.cke .cke_toolbar_start+.cke_combo_off a.cke_combo_button:hover {
    /*border-color: #e5e5e5;*/
}
.cke .cke_toolbar .cke_combo+.cke_toolbar_end,
.cke .cke_toolbar .cke_combo+.cke_toolgroup {
    margin-left: 0;
}
.cke .cke_combo {
    margin: 0;
}
.cke .cke_combo:after {
    display: none;
}
