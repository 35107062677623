@import "../../basics-scss/mixins";

.Table {
    width: auto;
    text-align: left;
    user-select: auto;

    .rowSP {
        .fadeGradient {
            background: linear-gradient(to bottom, rgba(232, 240, 254, 0) 0%, #eff2f7 100%);
        }

        &:nth-child(odd) {
            background-color: #e4e9f1;

            .fadeGradient {
                background: linear-gradient(to bottom, rgba(232, 240, 254, 0) 0%, #e4e9f1 100%);
            }
        }
    }

    .rowRL {
        .fadeGradient {
            background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
        }
    }

    .fadeGradient {
        background: linear-gradient(to right, rgba(251, 252, 253, 0) 0%, rgba(251, 252, 253, 0.7) 50%, rgba(251, 252, 253, 1) 76%, rgba(251, 252, 253, 1) 100%);
    }

    tbody {
        tr {
            .fadeGradient {
                background: linear-gradient(to bottom, rgba(251, 252, 253, 0) 0%, $colorNeutral000 100%);
            }

            &:nth-child(odd) {
                background-color: $colorNeutral020;

                .fadeGradient {
                    background: linear-gradient(to bottom, rgba(239, 245, 250, 0) 0%, $colorNeutral020 100%);
                }
            }

            &.selectedRow td,
            &.selectedRow .hasHover {
                background-color: $colorBlue010;

                .fadeGradient {
                    background: linear-gradient(to bottom, rgba(232, 240, 254, 0) 0%, $colorBlue010 100%);
                }
            }
        }
    }

    thead tr + tr th {
        padding: 0 6px 10px;
    }

    td {
        vertical-align: top;
        padding: 10px 12px;
        border: 1px solid $colorNeutral050;

        &:first-child {
            border-left: none;
        }

        &.focus {
            box-shadow: inset 0 0 0 2px $colorBlue300;
        }

        .refIconLink {
            visibility: hidden;
        }

        &:hover {
            .refIconLink {
                visibility: visible;
            }
        }
    }

    th {
        vertical-align: top;
        padding: 14px 12px;
        color: $colorNeutral600;
        font-weight: 600;
        white-space: nowrap;
    }

    .hasHover {
        &:hover {
            background-color: $colorNeutral070;

            .fadeGradient {
                background: linear-gradient(to bottom, rgba(232, 240, 254, 0) 0%, $colorNeutral070 100%);
            }
        }
    }

    .tdCheckbox {
        padding-top: 12px;
        padding-right: 14px;
        line-height: 0;
        text-align: center;
    }

    .tdInfo {
        text-align: center;
        padding: 4px 1px;
        line-height: 0;
    }

    .thInfo {
        vertical-align: middle;
        text-align: center;
        padding: 0 1px 0;
    }

    .thSettings {
        vertical-align: middle;
        text-align: center;
        padding: 0 14px 0 0;
    }

    .RowNoHover {
        &:hover {
            background: transparent;
        }
    }

    .Link, a {
        text-decoration: none;
        position: relative;
        display: inline-block;
        vertical-align: top;
        @include linkStyles;
    }

    .BlackLink {
        color: $colorNeutral800;
    }

}


.tableWrap {
    margin: 0 -16px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    &.mainTable {
        background: $colorNeutral000;
        @include forMedia(sm){
            margin: 0 -16px;
            border-bottom: 1px solid $colorNeutral080;
            .Table {
                th, td {
                    &:first-child {
                        padding-left: 16px;
                    }

                    &:last-child {
                        padding-right: 16px;
                    }
                }
            }
        }
    }

    .Table {
        th, td {
            &:first-child {
                padding-left: 16px;
            }

            &:last-child {
                padding-right: 16px;
            }
        }
    }
}

.tableEnd {
    display: flex;
}

.BtnIcon {
    background-color: transparent;
    border-color: transparent;
}

.TableSort {
    margin-left: 4px;
    vertical-align: top;
    position: relative;
    top: 4px;
    width: 12px;
    height: 12px;
}

.TableSortIcon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    @include svgIcon(16px,16px,$colorNeutral800);
}

.TableInput input, .TableInput select {
    @include placeholder();
}

.TableInputWrap {
    position: relative;
    height: 32px;
    & > .TableInput {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
    }
}

.tableRefInput {
    input {
        padding: 0 15px;
        border-radius: 32px 0 0 32px !important;
        @include placeholder();
    }

    & > div {
        border-radius: 0;

        &:last-child {
            border-radius: 0 32px 32px 0 !important;

            svg {
                position: relative;
                left: -1px;
            }
        }
    }
}

.Control {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 5px;
    font-size: 12px;
    line-height: 24px;
    color: $colorNeutral090;

    select {
        width: 60px;
        margin: 0 0 0 10px;
    }
}

.tableTop {
    display: flex;
    align-items: center;

    .Control {
        justify-content: flex-start;
        margin-right: auto;
    }
}

.Pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 0 0 auto;

    &.mobile {
        margin: 0 auto;
        padding-top: 16px;
        padding-bottom: 0;
    }
}

.PaginationInput {
    display: inline-block;

    input {
        width: 48px;
        margin: 0 8px 0 0;
        border-color: $colorNeutral080;
        box-shadow: none;
        border-radius: 4px;
        background: $colorNeutral010;
        height: 32px;
        text-align: center;
        color: $colorNeutral800;
    }
}

.PaginationInputContainer {
    position: absolute;
    right: 100%;
    bottom: 100%;
    white-space: nowrap;
    visibility: hidden;

    //input size styles
    border: 1px solid #000000;
    min-width: 48px;
    padding: 0 8px;
    font-size: $fontSizeInput;
}

.PaginationText {
    color: $colorNeutral800;
    line-height: 25px;
    display: inline-block;
    vertical-align: top;
    margin: 0 8px;
    font-size: $fontSizeBody;

    div {
        display: inline-block;
    }
}

.PaginationTotalText {
    color: $colorNeutral600;
    display: block;
    padding: 5px 13px 5px 8px;
    font-size: $fontSizeCaption;
    line-height: $lineHeightCaption;
}

.ArrowsLeft, .ArrowLeft, .ArrowRight, .ArrowsRight {
    height: 7px;
    line-height: 1em;
    display: inline-flex;
    vertical-align: top;
    justify-content: center;
    align-items: center;
}

.ArrowsLeft {
    transform: rotate(180deg);

    & > * {
        &:last-child {
            margin-left: 2px;
        }
    }
}

.ArrowLeft {
    transform: rotate(180deg);
}

.ArrowRight {

}

.ArrowsRight {
    & > * {
        &:last-child {
            margin-left: 2px;
        }
    }
}


.Info {
    display: inline-flex;
    vertical-align: top;
    justify-content: center;
    align-items: center;
    @include svgIcon(18px,18px,#6e728f);
}


.RelativeLink {
    position: relative;
}


.ColSpacer {
    width: 32px;
}

.ForContextMenu {
    position: relative;
}

.ForContextMenuState {
    white-space: nowrap;
}

.headerSort {
    cursor: pointer;
}

.NodeHTML {
    position: absolute;
    bottom: 50%;
    left: 100%;
    transform: translate(-20px, 0);
}

.PopupHTML {
    width: 300px;
    word-wrap: break-word;
}

.preloader {
    position: relative;
    background: transparent url("/assets/img/page-loader.gif") no-repeat center;
    background-size: 64px 64px;
    height: 200px;
}

.stateBadge {
    height: 20px;
    line-height: 20px;
    white-space: nowrap;
    display: inline-block;
    vertical-align: top;
    border-radius: 4px;
    padding: 0 8px;
    font-weight: 600;

    &.gray, &.default {
        background: $colorNeutral080;
        color: $colorNeutral800;
    }

    &.orange {
        background: $colorOrange300;
        color: $colorNeutral800;
    }

    &.aqua {
        background: $colorTeal300;
        color: $colorNeutral800;
    }

    &.dark {
        background: $colorNeutral800;
        color: $colorNeutral000;
    }

    &.yellow {
        background: $colorOrange100;
        color: $colorNeutral800;
    }

    &.blue {
        background: $colorBlue500;
        color: $colorNeutral000;
    }

    &.purple {
        background: $colorPurple500;
        color: $colorNeutral000;
    }

    &.green {
        background: $colorGreen500;
        color: $colorNeutral000;
    }

    &.red {
        background: $colorRed500;
        color: $colorNeutral000;
    }
}

.fixedHead {
    position: fixed;
    background-color: $colorNeutral000;
    overflow: hidden;
    z-index: $zIndexFixedPanel;
    box-shadow: $boxShadowFixedHead;
    visibility: hidden;

    &.visible {
        visibility: visible;
    }

    .Table {
        th, td {
            &:first-child {
                padding-left: 16px;
            }

            &:last-child {
                padding-right: 16px;
            }
        }
    }

    .container {
        position: relative;
    }
}

.CursorPointer {
    cursor: pointer;
}

.NotSet {
    color: $colorNeutral090;
}

.HiddenRow {
    display: none;
}

.fixedHorizontalScroll {
    position: fixed;
    background-color: transparent;
    overflow-x: auto;
    z-index: $zIndexFixedPanel;
    bottom: 0;
    visibility: hidden;

    &.visible {
        visibility: visible;
    }

    div {
        height: 1px;
    }
}

.unavailableData {
    color: $colorNeutral090;
    &::after {
        content: '';
        display: block;
        clear: both;
    }
    .icon {
        float: left;
        width: 16px;
        position: relative;
        top: 2px;
        @include svgIcon(100%,auto,$colorNeutral090);
    }
    .text {
        margin-left: 24px;
    }
}

.tableMessage {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    line-height: 1;
    color: $colorNeutral800;
    .icon {
        display: inline-block;
        vertical-align: top;
        width: 16px;
        margin-right: 8px;
        position: relative;
        top: 1px;
        @include svgIcon(100%,auto,$colorNeutral800);
    }
}

.SelectButton {
    position: fixed;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    z-index: $zIndexMiddle;
}

.Tooltip {
    max-width: 300px;
    word-wrap: break-word;
}

