@import "../../../basics-scss/mixins";

.ZoomPanelsContainer {
    z-index: $zIndexLow;
    left: 41px;
    bottom: 31px;
    position: fixed;
}

.ZoomWithTools {
    left: 289px;
}

.ZoomPanel {
    display: inline-block;
    margin: 0 16px 0 0;
    padding: 8px;
    border-radius: 18px;
    border: solid 1px $colorNeutral080;
    background: $colorNeutral000;
}

.ZoomPanelText {
    display: inline-block;
    font-family: $fontFamilyMain;
    font-size: 15px;
    line-height: 20px;
    user-select: none;
    cursor: pointer;
    padding: 0 4px;
    vertical-align: top;
    color: $colorNeutral600;
}

.ZoomPanelRatio {
    min-width: 46px;
    text-align: center;
}

.Icon {
    @include svgIcon(16px, 16px, $colorNeutral600);
    padding: 2px 4px;
    display: inline-block;
    vertical-align: top;
    user-select: none;
    cursor: pointer;
}