@import "../../../../../basics-scss/mixins";

.OptionsInput {
    display: flex;
    position: relative;
    flex: 1;

    .input {
        flex: auto;
        position: relative;
    }

    .button {
        flex: 0 0 32px;
        margin-left: 4px;
    }
}

.Field {
    position: relative;
    padding: 3px 44px 3px 8px;
    background: $colorNeutral010;
    border: 1 solid $colorNeutral080;
    border-radius: 4px;
    display: block;
    height: 32px;

    &:hover {
        background-color: $colorNeutral060;
    }

    &.Warning {
        border-color: $colorRed500;

        &:hover {
            background-color: $colorNeutral060;
        }
    }
}

.MenuItem {
    padding: 4px 12px 5px;
    cursor: pointer;
    color: $colorNeutral800;

    &.active {
        background-color: $colorNeutral070;
    }

    &.selected {
        background-color: $colorBlue010;
    }
}
.Menu {
    @include dropDown;
    @include customScrollbar;
    max-height: 250px;
    overflow-y: auto;
    padding: 8px 0;
}

.DropDownArrow{
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    @include svgIcon(20px,20px,$colorNeutral800);
    border-radius: 0 4px 4px 0;
    padding: 6px 6px 6px 5px;
    border-left: 1px solid $colorNeutral080;
}

.FieldClear {
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 8px;
    @include svgIcon(16px,16px,$colorNeutral800);
    &.WithOpts{
        right: 40px;
    }
}

.StringInput{
    input {
        padding-right: 28px;
        &[readonly] {
            padding-right: 8px!important;
        }
    }
    &.WithOpts{
        input {
            padding-right: 60px;
        }
    }
}