@import "../../basics-scss/mixins";

.Panel {
    display: flex;
    flex-wrap: nowrap;
    background: $colorNeutral000;
    padding: 14px;
    right: 0;
    top: $headerHeight;
    left: $sidebarWidth;
    z-index: $zIndexFixedPanel;
    @include transition(left, $transDurationPopup);

    &.pinnedAside {
        left: $pinOffsetLeft;
    }

    @include forMedia(sm) {
        padding: 0 0 16px 0;
        margin: 0 -2px;
    }
}

.FixedPanel {
    position: fixed;
}


.PanelLeft {
    white-space: nowrap;

    & > * {
        margin: 2px;
    }
}

.PanelCenter {
    flex: auto;
}

.PanelRight {
    white-space: nowrap;
    display: flex;

    & > * {
        margin: 2px;
    }
}

.PanelTitle {
    display: inline-block;
    vertical-align: top;
    padding: 0 16px 0 3px;
    line-height: 32px !important;
    @include forMedia(sm) {
        padding: 6px 0 0 0;
        text-transform: uppercase;
        font-size: $fontSizeTitle!important;
        line-height: $lineHeightTitle!important;
        color: $colorNeutral600;
        font-family: $fontFamilyMain;
        margin-left: 6px;
        font-weight: 600;
        white-space: normal;
    }
}

.RelatedPanel {
    padding: 0 0 14px;
    margin: 0 -2px;
    background: transparent;
}

.Shadow {
    box-shadow: $boxShadowFixedHead;
}

.SmHidden {
    @include forMedia(sm){
        display: none;
    }
}