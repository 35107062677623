@import "../../../basics-scss/mixins";

.select {
    width: 100%;
    height: 24px;
    padding: 0 6px 0 2px;
    font-size: 12px;
    line-height: 1.42857;
    color: $colorNeutral800;
    background-color: $colorNeutral000;
    background-image: none;
    border: 1px solid $colorNeutral080;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    &[disabled] {
        cursor: not-allowed;
        background-color: #e6e8ea;
    }
    &.error {
        border-color: red;
    }
}
