@import "../../../basics-scss/mixins";

.StatePanel{
  display: flex;
  flex-flow: column nowrap;
  z-index: $zIndexLow;
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 16px;
}

.ListItem{
  margin: 4px;
  font-size: 12px;
  color: $colorNeutral800;
}