@import "../../../../basics-scss/mixins";

.Pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0;
  padding: 0;
}

.PageMore {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  color: $colorNeutral090;
  padding: 0 8px;
}

.PageControl {
  margin: 0 4px;

  &.Disabled {
    display: none;
  }
}

.PageText {
  @include forMedia(sm){
    display: none;
  }
}

.PageArrow {
  display: none;
  @include forMedia(sm){
    display: flex;
  }
}

.PageNumber {
  margin: 0 4px;
  padding: 0;
  width: 32px;

  &.Active {
    cursor: default;
    color: #334D6E;
  }

  @include forMedia(sm){

  }
}
