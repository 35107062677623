@import "../../basics-scss/mixins";

// COMMON STYLES
// for login page, reset page, signup page, and AuthFull widget

.Form {
    box-shadow: $boxShadowModal;
    border-radius: 4px;
    background-color: $colorNeutral000;
    width: 496px;
    position: relative;
    margin: 8px;
    padding: 80px 64px 24px;
    @include forMedia(xs){
        width: 100%;
        padding: 80px 16px 24px;
    }
}

.Logo {
    display: block;
    text-align: center;
    line-height: 0;
    margin-bottom: 40px;

    img {
        max-width: 272px;
        max-height: 56px;
        width: auto;
        height: auto;
    }

    @include forMedia(xs){
        margin-bottom: 32px;
        img {
            max-width: 218px;
            max-height: 32px;
        }
    }
}

.Field {
    margin-bottom: 24px;
    @include forMedia(xs){
        margin-bottom: 16px;
    }
}

.LinksField {
    display: flex;
    justify-content: space-between;
    line-height: $lineHeightBody;
    font-size: $fontSizeBody;

    &.alignCenter {
        justify-content: center;
    }

    @include forMedia(xs){
        display: block;
        text-align: center;

        & > div {
            margin-top: 16px;
            &:first-child {
                margin-top: 0;
            }
        }
    }
}

.Link {
    @include linkStyles;
}

.TextField {
    font-size: $fontSizeBody;
    line-height: $lineHeightBody;
    margin-bottom: 24px;
    color: $colorNeutral800;
    @include forMedia(xs){
        margin-bottom: 16px;
    }
}
