@import "../../../basics-scss/mixins";

.SettingsMenu {
	position: absolute;
	top: 27px;
	left: 0;
	width: 328px;
	background: $colorNeutral000;
	box-shadow: $boxShadowCard;
	border-radius: 4px;
	z-index: $zIndexHigh;

	&.SettingsMenuModal {
		position: relative;
		width: 100%;
		top: unset;
		left: unset;
	}
}

.SettingsScroll {
	overflow-x: hidden;
	overflow-y: scroll;
	max-height: 220px;
	@include customScrollbar;
}

.SettingsMenuBlock {
	padding: 8px 0;
}

.SettingsMenuItem {
	margin: 8px 16px;
}

.SettingsConfigure {
	border-top: 1px solid $colorNeutral080;
	padding: 8px;
}

.SettingsMenuItemCheckbox {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: row-reverse;
	margin-bottom: 0;

	> div:first-child {
		width: 100%;
	}
}

.SettingsMenuItemInput {
	line-height: 20px;
}

.SettingsMenuItemLabel {
	width: 100%;
	margin-bottom: 0;
	margin-left: 16px;
	font-weight: normal!important;
}

.CheckButton {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.CheckIcon {
	margin-right: 11px;
	@include svgIcon(18px, 18px, $colorBlue500);
}

.SettingsSearchContainer {
	padding: 16px;
	border-bottom: solid 1px $colorNeutral080;
}

.SettingsSelectAll {
	margin: 16px 0 0;
}

.SettingsSmallInputContainer {
	position: relative;
}

.SettingsSmallInput {
	width: 100%;
	height: 32px;
	padding: 0 38px 0 8px;
	font-size: $fontSizeInput;
	color: $colorNeutral800;
	background-color: $colorNeutral010;
	background-image: none;
	border: 1px solid $colorNeutral080;
	border-radius: 4px;
	@include placeholder();
	@include phLineHeight(30px);
	@include transition();

	&:hover {
		background-color: $colorNeutral060;
	}

	&:focus {
		background-color: $colorNeutral000;
		border-color: $colorBlue150;
		box-shadow: inset 0 0 1px 1px $colorBlue150;
	}
}

.SettingsSmallInputIcon {
	@include svgIcon(16px, 16px, $colorNeutral090);
	user-select: none;
	cursor: default;
	position: absolute;
	right: 8px;
	top: 8px;

	&.SettingsSmallInputIconHover {
		@include svgIconColor($colorNeutral800);
	}
}

.SettingsSmallInputClean {
	user-select: none;
	cursor: default;
	position: absolute;
	right: 8px;
	top: 8px;
	width: 16px;
	height: 16px;
	&.SettingsSmallInputCleanActive {
		cursor: pointer;
	}
}

.SettingsNoResults {
	padding: 16px 8px;
	text-align: center;
	color: $colorNeutral600;
	font-size: $fontSizeBody;
	line-height: $lineHeightBody;
}