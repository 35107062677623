@import "../../basics-scss/mixins";

.DropDown {
    @include dropDown;
}
.List {
    max-height: 250px;
    overflow-y: auto;
    padding: 8px 0;
    @include customScrollbar;
}
.ListItem {
    display: flex;
    justify-content: space-between;
    padding: 6px 16px;
    font-size: $fontSizeInput;
    line-height: 20px;
    color: $colorNeutral800;
    height: 32px;
    cursor: pointer;

    &.current {
        background-color: $colorNeutral070;
    }
}
.TextBlock {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.Preloader {
    height: 88px;
    background: url("/assets/img/page-loader.gif") no-repeat center;
    background-size: 40px 40px;
}
.NoResults {
    height: 88px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $colorNeutral600;
}

.NoResultsText {
    font-size: $fontSizeLabel;
    line-height: $lineHeightLabel;
    color: $colorNeutral600;
    padding: 14px 16px;
}

.NoResultsAdd {
    padding: 12px 20px;
    color: $colorBlue500;
    font-weight: 600;
    font-size: $fontSizeLabel;
    line-height: $lineHeightLabel;
    cursor: pointer;
    border-top: 1px solid $colorNeutral080;

    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.NoResultsAddIcon {
    @include svgIcon(24px, 24px, $colorBlue500);
    margin-right: 8px;
}

.DelegationIcon {
    display: flex;
    align-items: center;
    margin-left: 8px;
    @include svgIcon(16px, 16px, $colorPurple300);
}
