@import "../../../basics-scss/mixins";

.btn {
    width: 100%;
    height: 48px;
    line-height: 47px; 
    padding: 0;
    text-align: center;
    font-size: $fontSizeButton;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    font-weight: 600;
    @include transition();

    &[disabled] {
        background: $colorNeutral080;
        color: $colorNeutral600;
        cursor: default;
    }
    &:focus {
        border-color: $colorBlue150!important;
        box-shadow: inset 0 0 1px 1px $colorBlue150;
    }
}

.btnDefault {
    background: $colorNeutral000;
    border: 1px solid $colorNeutral080;
    color: $colorNeutral800;

    &:hover {
        border-color: $colorNeutral090;
        background-color: $colorNeutral050;
    }
    &:active {
        border-color: $colorNeutral090;
        background-color: $colorNeutral080;
    }
}

.btnPrimary {
    color: $colorNeutral000;
    background: $colorBlue300;
    &:hover:not([disabled]) {
        background-color: $colorBlue400;
    }
    &:active:not([disabled]) {
        background-color: $colorBlue500;
    }
}

.loading {
    overflow: hidden;
    position: relative;
    &, &:hover, &:active {
        box-shadow: none!important;
        border-color: $colorNeutral080!important;
        background: $colorNeutral080!important;
        color: $colorNeutral600!important;
        cursor: default!important;
    }
    &:after {
        @include animatedShadow;
    }
}

@keyframes Rotate {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}
