@import "../../../../basics-scss/mixins";

.Title {
    font-size: $fontSizeH3;
    font-family: $fontFamilyHeadline;
    font-weight: 600;
    line-height: $lineHeightH3;
    color: $colorNeutral800;
    margin: 0 0 8px 0;

    @include forMedia(sm){
        display: none;
    }
}

.Group {
    display: block;
    cursor: pointer;
    padding: 10px 28px;
    font-family: $fontFamilyMain;
    font-size: $fontSizeBody;
    line-height: $lineHeightBody;
    position: relative;

    &:hover {
        background: $colorNeutral070;
    }

    &:active {
        background: $colorBlue010;
    }

    .Name {
        color: $colorNeutral800;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .Bullet {
        position: absolute;
        border-radius: 50%;
        width: 4px;
        height: 4px;
        background: $colorNeutral800;
        left: 12px;
        top: 18px;
    }

    &.current {
        .Name {
            color: $colorBlue500;
        }
        .Bullet {
            background: $colorBlue500;
        }
    }

    &.disabled {
        cursor: default;

        .Name {
            color: $colorNeutral090;
        }
        .Bullet {
            background: $colorNeutral090;
        }
    }
}
