@import "../../../../basics-scss/mixins";

.Field {
  margin: 0 0 16px;
  width: 100%;
}

.Name {
  @include formLabel;
  height: 20px;
  position: relative;
  &.unavailable {
    color: $colorNeutral090;
    .Required {
      color: $colorNeutral090;
    }
  }
}

.NameText {
  white-space: nowrap;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-wrap: nowrap;
}

.Label {
  overflow: hidden;
  text-overflow: ellipsis;
}

.Input {
  line-height: 32px;
}

.Required {
  color: $colorRed500;
  margin-right: 5px;
}

.ContextMenu {
  background: $colorNeutral000;
  border-radius: 4px;
  box-shadow: $boxShadowModal;
  max-width: 250px;
  min-width: 200px;
  overflow-y: auto;
  overflow-x: hidden;

  li {
    position: relative;
    padding: 7px 30px 8px 20px;
    cursor: pointer;
    color: $colorNeutral800;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      background: $colorNeutral070;
    }
  }
}


.Checkbox {
  position: relative;
  .NameWrap {
    margin-left: 24px;
  }
  .Name {
    margin: 0;
  }
  .Input {
    line-height: 0;
    position: absolute;
    left: 0;
    top: 2px;
  }
}

.EnteredLength {
  position: absolute;
  right: 0;
  top: 0;
  color: $colorNeutral090;

  font-size: $fontSizeLabel;
  line-height: $lineHeightLabel;
}

.Warning {
  color: $colorRed500;
}


.ErrorInput {
  input {
    border: 2px solid $colorRed300;
    border-radius: 4px;
  }
}

.WarningInput {
  input {
    border: 2px solid $colorOrange300;
    border-radius: 4px;
  }
}

.BtnIcon {
  margin-left: 6px;
  margin-right: 2px;
}

.BtnIconWrapper{
  padding: 0;
}

.HintTitle{
  font-weight: 700;
}