.Loader {
  position: absolute;
  left: 50%;
  top: 50%;
  animation: LoaderFadeIn .1s ease-in;
  margin: -32px 0 0 -32px;
  width: 64px;
  height: 64px;
  background: url("/assets/img/page-loader.gif") no-repeat 0 0;
  background-size: 100% 100%;
}
