@import "../../../../basics-scss/mixins";

.menu {
    font-size: $fontSizeInput;
}

.linkWrap {
    position: relative;
    display: flex;
    align-items: center;

    &:hover {
        color: $colorNeutral800;
        background: $colorNeutral070;

        @include forMedia(sm){
            background: none;
        }

        .linkBtn {
            display: flex;
            @include forMedia(sm){
                display: none;
            }
        }
    }
}

.item {
    &.activeSubmenuItem > .linkWrap {
        background: $colorBlue010;
    }
    &.active {
        & > .linkCategory > .link {
            color: $colorNeutral800;
            font-weight: 600;

            .arrow {
                transform: rotate(90deg);
                @include svgIconColor($colorNeutral800);

                &.dynamicArrow:hover {
                    @include svgIconColor($colorBlue300);
                }
            }
        }

        & > .submenu {
            overflow: hidden;
            max-height: 9999px;
            transition: max-height 200ms cubic-bezier(1, 0, 1, 0);
        }
    }

    @include forMedia(sm){
        &.active > .linkWrap {
            .linkBtn {
                display: flex;
            }
        }
    }
}

.link {
    cursor: pointer;
    display: block;
    padding: 10px 16px 10px 0;
    line-height: 20px;
    color: $colorNeutral800;
    flex: auto;
}

.linkLabel {
    position: absolute;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.linkContainer {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    min-height: 20px;
}

.arrowContainer {
    position: relative;
    width: 32px;
    flex: 1 0 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 8px;
    padding-left: 10px;
}

.labelContainer {
    position: relative;
    width: 100%;
}

.linkBtn {
    cursor: pointer;
    flex: 0 0 16px;
    @include svgIcon(16px,16px,$colorNeutral090);
    display: none;

    &:last-child {
        margin-left: 8px;
        margin-right: 8px;
    }

    &.active {
        display: flex;
        @include svgIconColor($colorBlue300);
    }
}

.submenu {
    overflow: hidden;
    max-height: 0;
    transition: max-height 200ms cubic-bezier(0, 1, 0, 1);

    .arrowContainer {
        width: 56px;
        flex: 1 0 56px;
    }
    .SkeletonListItem {
        margin-left: 24px;
    }

    .item.active {
        & > .linkWrap:not(.linkCategory) {
            background: $colorBlue010;

            .link {
                color: $colorNeutral800;
                font-weight: 400;
            }
        }

        & > .submenu {
            overflow: hidden;
            max-height: 9999px;
            transition: max-height 200ms cubic-bezier(1, 0, 1, 0);
        }
    }

    & > .item > .submenu {
        .arrowContainer {
            width: 80px;
            flex: 1 0 80px;
        }
        .SkeletonListItem {
            margin-left: 40px;
        }

        & > .item > .submenu {
            .arrowContainer {
                width: 104px;
                flex: 1 0 104px;
            }
            .SkeletonListItem {
                margin-left: 56px;
            }
        }
    }
}

.arrow {
    @include svgIcon(16px,16px,$colorNeutral090);

    &.dynamicArrow:hover {
        @include svgIconColor($colorBlue300);
    }
}

.dot {
    border-radius: 50%;
    width: 2px;
    height: 2px;
    background: $colorNeutral800;
    margin-right: 7px;
}

.linkIcon {
    position: absolute;
    line-height: 0;
    left: -24px;
    top: 2px;
    width: 16px;
    height: 16px;
    display: flex;

    & > img {
        max-width: 100%;
        max-height: 100%;
        display: inline-block;
        margin: auto;
    }

    &.categoryLinkIcon {
        left: 0;
    }

    path {
        fill: $colorNeutral090;
    }
}

.mainLinkIcon {
    position: absolute;
    line-height: 0;
    left: 0;
    top: 2px;
    width: 16px;
    height: 16px;
    display: flex;
    & > img {
        max-width: 100%;
        max-height: 100%;
        display: inline-block;
        margin: auto;
    }
    path {
        fill: $colorNeutral090;
    }
}

.icon {
    @include svgIcon(16px, 16px, $colorNeutral800);
}


.noResults {
    color: $colorNeutral600;
    font-size: $fontSizeBody;
    line-height: $lineHeightBody;
    padding: 26px 16px;
}

.SkeletonListItem {
    margin-bottom: 10px;
    margin-top: 10px;
    display: flex;
}

.SkeletonTitleHolder {
    border-radius: 4px;
    height: 20px;
    background: $colorNeutral077;
    width: 100%;
}

.SkeletonListHolder {
    border-radius: 4px;
    height: 20px;
    background: $colorNeutral077;
    &:first-child {
        flex: 0 0 20px;
        margin-right: 4px;
    }
}

.SkeletonListHolderWidth {
    width: 75%;
}