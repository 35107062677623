@import "../../../../../basics-scss/mixins";

.Input {
    flex: 1;
    position: relative;
    input {
        @include stringInputStyles;
    }
    &.HasCleanBtn {
        input {
            padding-right: 28px;
        }
    }
    &.warningInput {
        input {
            border-color: $colorRed300;
        }
    }
}

.CleanBtn {
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    position: absolute;
    @include svgIcon(16px,16px,$colorNeutral800);
}
