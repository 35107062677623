@import "../../basics-scss/mixins";

.Hint {
    position: absolute;
    z-index: $zIndexPopover;
    background: $colorNeutral800;
    border-radius: 4px;
    color: $colorNeutral000;
    font-size: $fontSizeCaption;
    line-height: $lineHeightCaption;
    padding: 4px 8px;
    max-width: 288px;
    word-break: break-word;
    left: -9999px;
    @include transition(opacity, $transDurationPopup);
    opacity: 0;

    span {
        display: inline-block;
        vertical-align: top;
        background: $colorNeutral600;
        border-radius: 4px;
        margin: 0 2px;
        padding: 0 4px;
    }
}