@import "../../../../basics-scss/mixins";

.Field {
    margin-bottom: 16px;
}

.FieldTitle {
    @include formLabel;
}

.FieldFlex {
    display: flex;
    & > *:first-child {
        flex: auto;
    }
    & > *:last-child {
        flex: 0 0 32px;
        margin-left: 4px;
    }
}