@import "../../basics-scss/mixins";

.RecordField {
    margin: 40px 0 15px;
}
.NoPaddingTop {
    padding-top: 0;
    @include forMedia(sm){
        padding-top: 16px;
    }
}
.FormLabels {
    margin: 0 10px 10px 0;
}
.WideButtonsXs {
    & > div{
        margin: 0 2px;
        &:first-child {
            margin-left: 0;
        }
    }

    @include forMedia(xs){
        & > div {
            margin: 0 0 16px;
            display: block;
        }
        button {
            width: 100%;
        }
    }
}

.Heading {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include forMedia(sm){
        white-space: normal;
    }
    &.Hidden{
        color: $colorNeutral090;
    }
}
