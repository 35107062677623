@import "../../../../basics-scss/mixins";

.ResizeContainer {
  position: absolute;
  bottom: 1px;
  left: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 97%;
  height: 14px;
  cursor: ns-resize;

  .Icon {
    @include svgIcon(16px, 6px, $colorNeutral090);
  }

  &:hover {
    .Icon {
      @include svgIconColor($colorNeutral800 );
    }
  }
}