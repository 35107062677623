@import "../../../basics-scss/mixins";

.Base {
    display: flex;
    align-items: flex-end;
}
.Wrapper {
    position: relative;
    overflow: hidden;
    flex: auto;
    margin: 0;

    &.hasMargins {
        margin: 0 32px;
    }
}
.List {
    white-space: nowrap;
    position: relative;
    display: inline-block;
    vertical-align: top;
    padding:0;
    width: 100%;
}
.Arrow {
    width: 32px;
    height: 32px;
    cursor: pointer;
    user-select: none;
    flex: 0 0 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include svgIcon(20px,20px,$colorNeutral090);
    &:hover {
        @include svgIconColor($colorNeutral800);
    }
}
.TransitionLeft {
    transition: left .5s ease-in;
}
