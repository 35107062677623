@import "../../../../../basics-scss/mixins";

.DocumentId {
    position: relative;
}

.DocumentIdOverlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: $zIndexLow;
}

.DocumentIdInput {
    display: flex;
    flex: 1;

    .Field {
        flex: auto;
    }

    .button {
        flex: 0 0 32px;
        margin-left: 4px;
    }

    &.readOnly {
        .Field {
            @include readonlyInputStyles;
        }
        .FieldOverlay {
            cursor: default;
        }
    }
}

.DocumentIdWindow {
    position: relative;
    border: 1px solid rgba(0, 0, 0, .2);
    @include dropDown;
}

.DocumentIdDropdown {
    min-width: 300px;
    max-width: 520px;
    position: relative;
    z-index: $zIndexMiddle;
}

.DocumentIdHeader {
    margin: 0;
    padding: 12px 24px;
    background-color: $colorNeutral010;
    border-bottom: 1px solid $colorNeutral080;
    border-radius: 4px 4px 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.DocumentIdClose {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    @include svgIcon(18px, 18px, $colorNeutral090);

    &:hover {
        background-color: #ebedf0;
        @include svgIconColor($colorNeutral800);
    }

    :active {
        @include svgIconColor(#334d6e);
    }
}

.DocumentIdBody {
    padding: 24px;
}

.DocumentIdButtons {
    margin-top: 24px;

    & > button {
        margin-right: 8px;
    }
}

.HiddenSm {
    @include forMedia(sm) {
        display: none;
    }
}

.Field {
    position: relative;
    padding: 3px 44px 3px 8px;
    background: $colorNeutral010;
    border: 1px solid $colorNeutral080;
    border-radius: 4px;
    display: block;
    height: 32px;
    @include transition();

    &:hover {
        background-color: $colorNeutral060;
        //border-color: $colorNeutral090;
    }

    &.Warning {
        border-color: $colorRed300;

        &:hover {
            background-color: $colorNeutral060;
            //border-color: $colorNeutral090;
        }
    }
}

.FieldReadonly {
    border-radius: 4px;
    display: block;
    height: 32px;
    @include readonlyInputStyles;
    flex: auto;
}

.FieldOverlay {
    cursor: text;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.FieldClear {
    cursor: pointer;
    position: absolute;
    right: 7px;
    top: 7px;
    @include svgIcon(16px, 16px, $colorNeutral800);
}

.Badge {
    display: block;
    height: 24px;
    width: auto;
    line-height: 20px;
    background: $colorBlue070 !important;
    border: 1px solid $colorBlue100 !important;
    box-shadow: none !important;
    color: $colorNeutral800;
    padding: 0 8px;
    border-radius: 4px;
    position: absolute;
    left: 0;
    top: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    &.disabled {
        background: $colorNeutral050!important;
        border-color: $colorNeutral080!important;
        cursor: default!important;
    }
}

.BadgeWrap {
    position: relative;
}

.BadgeEye {
    display: inline-block;
    vertical-align: -3px;
    margin-right: 8px;
    @include svgIcon(16px,16px,$colorNeutral800);
}
