@import "../../../basics-scss/mixins";

.CommentsTabsHeadWrap {
  position: relative;
  margin-right: 40px;
  height: 33px;
}

.CommentsTabsHeadContainer {
  position: absolute;
  left: 0;
  right: 0;
}

.CommentsTabsHead {
  position: relative;
  z-index: $zIndexLow;
  background: $colorNeutral050;
  border-radius: 4px 4px 0 0;
  border: 1px solid $colorNeutral080;
  border-bottom: none;
  overflow: hidden;
}

.CommentTabHeadItem {
  height: 32px;
  padding: 0 16px;
  cursor: pointer;
  background: transparent;
  color: $colorNeutral800;
  font-size: $fontSizeCaption;
  line-height: 32px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;

  &:first-child {
    border-radius: 4px 0 0 0;
  }

  &:last-child:not(:first-child) {
    border-radius: 0 4px 0 0;
  }

  &:hover {
    background: $colorNeutral075;
  }
}

.CommentTabHeadItemActive {
  @extend .CommentTabHeadItem;
  background: $colorNeutral010!important;
  color: $colorBlue500;
  font-weight: 600;
  cursor: default;
}

.CommentTabIsRequired {
  color: $colorRed500;
}

.CommentsTabsSliderArrow {
  flex: 0 0 20px;
  height: 32px;
}
