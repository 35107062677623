@import "../../../../../../basics-scss/mixins";

.Popup {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8);
}
.PopupContainer {
    display: table;
    width: 100%;
    height: 100%;
    position: relative;
}
.PopupContent {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    padding: 16px;
}
.PopupChild {
    margin: 0 auto;
    max-width: 1030px;
}
.PopupOverlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}
.PopupClose {
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: fixed;
    right: 40px;
    top: 40px;
    @include svgIcon(100%,auto,#dfe1e5);

    @include forMedia(sm){
        display: none;
    }
}