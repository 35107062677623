@import "../../../basics-scss/mixins";

.WrapperAgent{
    background: $colorNeutral000;
    border: 1px solid $colorNeutral080;
    border-radius: 4px;
}

.FormContainerAgent {
    padding: 16px 16px 0;
}

.HeadingAgent{
    display: flex;
    flex-flow: row nowrap;
    padding: 16px 16px 0;
}

.HeadingAgentClosed{
    padding-bottom: 16px;
}

.HideButton{
    flex: 0 0 20px;
    margin-right: 8px;
    @include svgIcon(20px, 20px, $colorNeutral800);
    cursor: pointer;
    user-select: none;
}

.HeadingText{
    font-family: $fontFamilyMain;
    font-weight: 600;
    font-size: $fontSizeH4;
    line-height: $lineHeightH4;
    color: $colorNeutral800;
}

.SaveButtonWrapper{
    margin-top: 32px;
}