@import "../../../basics-scss/mixins";

.ButtonsWrap {
    @include forMedia(xs) {
        & > div {
            display: block;
        }
        button {
            width: 100%;
        }
    }
}

.Heading {
    display: flex;
    justify-content: space-between;
}

.OtherUser {
    margin-left: 8px;
}