@import "../../../basics-scss/mixins";

.Badges {
    display: flex;
}
.BadgesContainer {
    line-height: 0;
}
.BadgesContent {
    flex: auto;
    overflow: hidden;
    height: 24px;
}
.BadgesTotal {
    border-radius: 4px;
    position: relative;
    height: 24px;
    min-width: 24px;
    line-height: 22px;
    background: $colorBlue070;
    color: $colorNeutral800;
    text-align: center;
    padding: 0 1px;
    border: 1px solid $colorBlue100;
    margin-left: 16px;
    &:after {
        content: '...';
        line-height: 1;
        display: block;
        position: absolute;
        bottom: 0;
        right: 100%;
        padding-right: 4px;
    }
}
.Badge {
    display: inline-block;
    vertical-align: top;
    height: 24px;
    line-height: 22px;
    background: $colorBlue070;
    border: 1px solid $colorBlue100;
    color: $colorNeutral800;
    font-size: $fontSizeBody;
    padding: 0 31px 0 7px;
    border-radius: 4px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 4px;
    max-width: 100%;
    &.noCross {
        padding: 0 7px;
    }
}
.BadgeCross {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
    cursor: pointer;
    top: 3px;
    right: 7px;
    @include svgIcon(16px,16px,$colorNeutral800);
}
