@import "../../../../basics-scss/mixins";

.User {
    position: relative;
}

.Menu {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: $zIndexLow;
    display: none;
    float: left;
    min-width: 160px;
    padding: 8px 0;
    margin: 8px 0 0;
    list-style: none;
    text-align: left;
    @include dropDown;

    @include forMedia(sm) {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: $zIndexModal;
        border-radius: 0;
        box-shadow: none;
        margin: 0;
        padding: 0;
        overflow-y: auto;
    }

    &.active {
        display: block;
        @include animateFadeIn();
    }

}

.MenuItem > * {
    cursor: pointer;
    display: block;
    padding: 0 16px;
    line-height: 32px;
    height: 32px;
    color: $colorNeutral800;
    white-space: nowrap;
    position: relative;

    &:hover {
        text-decoration: none;
        color: $colorNeutral800;
        background-color: $colorNeutral070;
    }

    @include forMedia(sm) {
        height: auto;
        padding: 22px 16px;
        border-bottom: 1px solid $colorNeutral080;
        font-size: $fontSizeButton;
        line-height: $lineHeightBody;
        color: $colorNeutral800;
        font-weight: 600;
    }
}

.MenuSplit {
    background-color: $colorNeutral080;
    height: 1px;
    margin: 8px 0;

    @include forMedia(sm) {
        display: none;
    }
}

.MenuIcon {
    display: inline-block;
    vertical-align: top;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    position: relative;
    top: 8px;
    @include svgIcon(16px, 16px, $colorNeutral800);

    @include forMedia(sm) {
        display: none;
    }
}

.Arrow {
    position: absolute;
    right: -1px;
    top: 15px;
    @include svgIcon(12px, auto, $colorNeutral800);
}

.Info {
    white-space: nowrap;
    cursor: pointer;
}

.Text {
    display: inline-block;
    vertical-align: top;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $colorNeutral800;
    font-size: $fontSizeBody;
    line-height: 32px;
    position: relative;

    @include forMedia(md) {
        max-width: 120px;
    }

    @include forMedia(sm) {
        display: none;
    }
}

.Elevate {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    position: absolute;
    bottom: -2px;
    right: -2px;
    background: $colorNeutral000;
    @include svgIcon(100%, auto, $colorNeutral090);

    &.active {
        @include svgIconColor($colorBlue300);
    }

    @include forMedia(sm) {
        width: 12px;
        height: 12px;
        border: 2px solid $colorNeutral000;
        background: $colorNeutral090;
        bottom: -2px;
        right: -2px;
        svg {
            display: none;
        }
        &.active {
            background: $colorBlue300;
        }
    }
}

.Ava {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background: no-repeat center;
    background-size: cover;
    position: relative;
    overflow: hidden;
    @include svgIcon(32px, 32px);

    @include forMedia(sm) {
        width: 24px;
        height: 24px;
        @include svgIcon(24px, 24px);
    }
}

.AvaWrap {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-right: 8px;

    @include forMedia(sm) {
        margin-right: 0;
    }
}

.MobileHeader {
    padding: 14px 16px 13px;
    border-bottom: 1px solid $colorNeutral080;
    display: none;

    @include forMedia(sm) {
        display: flex;
    }
}

.MobileTitle {
    text-transform: uppercase;
    font-size: $fontSizeTitle;
    line-height: $lineHeightTitle;
    color: $colorNeutral600;
    font-family: $fontFamilyMain;
    font-weight: 600;
    padding: 6px 0 0;
    margin: 2px 2px 2px 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.MobileClose {
    margin: 2px 2px 2px auto;
}

.Freeze {
    overflow: hidden;
}