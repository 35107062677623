@import "/basics-scss/mixins";
.wrap {
    position: absolute;
    left: 24px;
    top: 24px;
    @include forMedia(xs){
        left: 16px;
        top: 16px;
    }
}

.container {
    position: relative;
}

.input {
    border: none;
    line-height: 20px;
    color: $colorNeutral800;
    background-color: transparent;
    padding: 0;
    font-weight: 600;
}
.menuItem {
    padding: 4px 12px 5px;
    cursor: pointer;
    color: $colorNeutral800;
    &.active {
        background-color: $colorNeutral070;
    }
}

.menu {
    @include dropDown;
    @include customScrollbar;
    max-height: 250px;
    overflow-y: auto;
    padding: 8px 0;
    min-width: 136px;
}

.arrow {
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
    &.active {
        transform: rotate(180deg);
    }
    @include svgIcon(20px,20px,$colorNeutral800);
}