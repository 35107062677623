@import "../../../../basics-scss/mixins";

.Menu {
  @include dropDown;
  min-width: 160px;
  padding: 8px 0;

  &.Active {
    display: block;
  }

  &.widthSmall {
    width: 244px;
  }

  &.widthMiddle {
    width: 344px;
  }

  &.widthDefault {
    width: 464px;
  }
}

.Element {
  padding: 6px 16px;
  line-height: $lineHeightBody;
  cursor: pointer;

  &.Active {
    background-color: $colorBlue010;
  }

  &:hover {
    text-decoration: none;
    background-color: $colorNeutral070;
  }

  .Arrow {
    @include svgIcon(16px, 16px);
    margin-left: auto;
    align-self: flex-start;
    position: relative;
    top: 3px;
  }
}

.ElementDescription {
  display: flex;
  align-items: center;
  width: 100%;
}

.ElementBlockDecoration {
  display: flex;
  align-items: center;
  align-self: baseline;
}

.ElementTitle {
  font-size: $fontSizeBody;
  color: $colorNeutral800;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.Decoration {
  margin-left: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: $fontSizeTitle;
  line-height: $lineHeightTitle;
  text-align: center;
}
