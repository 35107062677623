@import "../../../../../../basics-scss/mixins";

.CondSelectButton {
    width: 100%;
    height: 32px;
    padding: 6px 20px 6px 8px;
    font-size: $fontSizeInput;
    line-height: 1;
    color: $colorNeutral800;
    background-color: $colorNeutral010;
    background-image: none;
    border: 1px solid $colorNeutral080;
    border-radius: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    text-align: left;
    display: block;

    &:after {
        content: '';
        display: block;
        position: absolute;
        background: url("/assets/img/select-arrow.svg?inline") no-repeat 0 0;
        background-size: 100% 100%;
        width: 16px;
        height: 16px;
        top: 50%;
        margin-top: -8px;
        right: 8px;
    }

    &:disabled {
        @include readonlyInputStyles;

    }
}

.CondSelectBlock {
    visibility: visible;
    position: absolute;
    border-radius: 4px;
    background: $colorNeutral000;
    box-shadow: $boxShadowModal;
    z-index: $zIndexMiddle;
    left: 0;
    top: 33px;
    overflow: hidden;
}

.CondSelectBlockHead {
    border-bottom: 1px solid $colorNeutral080;
}

.CondSelectBlockFilter {
    padding: 12px;

    .input {
        flex: auto;
        width: 100%;
        height: 32px;
        padding: 0 30px 0 8px;
        font-size: $fontSizeInput;
        color: $colorNeutral800;
        background: $colorNeutral010 url("/assets/img/icons/search.svg?inline") no-repeat right 8px center;
        background-size: 18px auto;
        border: 1px solid $colorNeutral080;
        border-radius: 4px;
    }
}

.CondSelectBlockBody {
    line-height: 0;
}

.CondSelectBlockList {
    width: 100%;
    max-height: 320px;
    overflow-y: auto;
    display: inline-block;
    vertical-align: top;
    background: $colorNeutral000;
    @include customScrollbar;

    li {
        line-height: 28px;
        height: 28px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 30px 0 12px;
        cursor: pointer;
        position: relative;
        color: $colorNeutral800;

        &.Selected {
            color: $colorNeutral800;
            font-weight: 600;
        }

        &:hover {
            background: $colorNeutral070;
        }
    }
}

.ConditionField {
    position: relative;
}
