@import "../../basics-scss/mixins";


.Tooltip {
    position: absolute;
    background: $colorNeutral000;
    border-radius: 4px;
    box-shadow: $boxShadowCard;
    z-index: $zIndexPopover;
    padding: 16px;
    color: $colorNeutral800;
    font-size: $fontSizeBody;
    line-height: $lineHeightBody;
    max-width: 288px;
    min-width: 160px;
    word-break: break-word;
    @include animateFadeIn();
}

.Triangle {
    position: absolute;
    @include tooltip-triangle('left');
    z-index: $zIndexPopover;
    @include animateFadeIn();

    &.left {
        @include tooltip-triangle('left');
    }

    &.right {
        @include tooltip-triangle('right');
    }

    &.top {
        @include tooltip-triangle('top');
    }

    &.bottom {
        @include tooltip-triangle('bottom');
    }
}
