@import "../../basics-scss/mixins";

@keyframes opacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.quickMessage {
  position: absolute;
  z-index: $zIndexModal;
  box-shadow: $boxShadowModal;
  padding: 4px 12px;
  background: $colorNeutral000;
  animation: opacity 500ms;
}
