@import "../../basics-scss/mixins";

.Activities {
    margin: 0 0 16px;
}

.ActivitiesLabel {
    @include formLabel;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ActivitiesLabelWrap {
    position: relative;
    padding-right: 34px;
}

.ActivitiesList {
    @include forMedia(sm){
        display: none;
        &.active {
            display: block;
        }
    }
}

.ActivitiesToggle {
    visibility: hidden;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    &.active {
        transform: translateY(-50%) rotate(180deg);
    }

    @include forMedia(sm){
        visibility: visible;
    }
}

.ActivitiesItem {
    margin-bottom: 16px;
    font-size: $fontSizeCaption;
    line-height: $lineHeightCaption;
    position: relative;
    border: solid 1px $colorNeutral080;
    border-radius: 4px;
    padding: 16px;
    overflow: hidden;
    background-color: $colorNeutral000;
}

.ActivitiesBar {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 4px;
    z-index: $zIndexLow;

    &.typeAdditional {
        background: $colorPurple300;
    }

    &.typeWorkNotes {
        background: $colorNeutral800;
    }

    &.typeDefault {
        background: $colorBlue300;
    }
}

.ActivitiesHead {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 12px;

    @include forMedia(sm){
        display: block;
        position: relative;
        margin-bottom: 20px;
        padding-bottom: 16px;
        &:before {
            content: '';
            display: block;
            position: absolute;
            left: -16px;
            right: -16px;
            bottom: 0;
            height: 1px;
            background-color: $colorNeutral080;
        }
    }
}

.ActivitiesHeadPart {
    &:first-child {
        margin-right: auto;
        @include forMedia(sm){
            margin: 0 0 8px;
        }
    }
}

.ActivitiesInfo {
    color: $colorNeutral600;
}

.ActivitiesUser {
    display: flex;
    align-items: center;
    color: inherit;
}

.ActivitiesUserName {
    @include formLabel;
    margin: 0 0 0 8px;
    white-space: nowrap;
}

.ActivitiesUserAva {
    width: 24px;
    height: 24px;
    position: relative;
}

.ActivitiesUserPic {
    background-image: url("/assets/img/user-ava.svg?inline");
    background-size: cover;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
}

.ActivitiesUserStatus {
    width: 10px;
    height: 10px;
    position: absolute;
    right: -5px;
    top: 50%;
    margin-top: -5px;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    border-color: $colorNeutral000;
    border-image: initial;
    background-color: rgb(113, 226, 121);
}

.ActivitiesTable {
    display: table;

    @include forMedia(sm){
        display: block;
    }
}

.ActivitiesTableRow {
    display: table-row;
    @include forMedia(sm){
        display: block;
        margin-bottom: 24px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.ActivitiesTableCol {
    color: $colorNeutral800;
    text-align: left;
    padding: 4px 0 4px 14px;
    white-space: pre-line;
    position: relative;
    display: table-cell;
    @include forMedia(sm){
        display: block;
        padding: 0;
    }

    &:first-child {
        color: $colorNeutral600;
        text-align: right;
        padding: 4px 10px 4px 0;
        @include forMedia(sm){
            text-align: left;
            padding: 0;
            margin-bottom: 8px;
        }
    }
}

.BreakWord {
    word-break: break-word;
    white-space: pre-line;
}