@import "../../../basics-scss/mixins";

.ListBox {
    position: fixed;
    top: $headerHeight;
    bottom: 0;
    width: 360px;
    right: -370px;
    @include transition(right, $transDurationPopup);
    background: $colorNeutral000;
    box-shadow: $boxShadowFixedAside;
    z-index: $zIndexAside;
    display: flex;
    flex-direction: column;

    @include forMedia(sm){
        top: 0;
        left: 0;
        transition: none;
        display: none;
        z-index: $zIndexModal;
        width: 100%;

        &.active {
            display: flex;
        }
    }

    &.active {
        right: 0;
    }

    &.workflow {
        border-top: 2px solid $colorNeutral080;
        box-shadow: none;
        top: $headerHeightWorkflow;
    }
}

.ListBoxContent {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    @include customScrollbar;
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-direction: column;
}

.ListBoxContainer {
    flex: auto;
    position: relative;
}

.ListBoxOverlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: $zIndexAside;
}

.Headline {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $colorNeutral080;
    @include forMedia(sm){
        padding: 0 16px;
        height: 56px;
        flex: 0 0 56px;
    }
}

.HeadlineText {
    @include forMedia(sm){
        font-size: $fontSizeTitle!important;
        line-height: $lineHeightTitle!important;
        font-family: $fontFamilyMain!important;
        color: $colorNeutral600;
        text-transform: uppercase;
    }
}

.Block {
    padding: 0 16px 8px;
    border-bottom: 1px solid $colorNeutral080;
    //&:last-child {
    //    border-bottom: none;
    //}
}

.BlockTitle {
    font-weight: 600;
    font-size: $fontSizeTitle;
    line-height: $lineHeightTitle;
    color: $colorNeutral600;
    text-transform: uppercase;
    margin: 24px 0 16px;
}

.BlockVersion {
    padding: 16px;
    font-size: $fontSizeCaption;
    line-height: $lineHeightCaption;
    color: $colorNeutral600;
    margin-top: auto;
    display: flex;
    justify-content: space-between;
}

.Link {
    margin-right: 10px;
    @include linkStyles;
}

.Freeze {
    overflow: hidden;
}

.WrapLink {
    font-size: $fontSizeCaption;
    line-height: $lineHeightCaption;
    color: $colorNeutral600;
    margin-bottom: 16px;
}
