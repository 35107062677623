@import "../../../../basics-scss/mixins";

.ImageResizer {
  display: block;
  width: 8px;
  height: 8px;
  position: absolute;
  background-color: $colorBlue300;
  border: 1px solid $colorNeutral000;
}

.imageResizerNe {
  top: -6px;
  right: -6px;
  cursor: ne-resize;
}

.imageResizerSe {
  bottom: -6px;
  right: -6px;
  cursor: nwse-resize;
}

.imageResizerSw {
  bottom: -6px;
  left: -6px;
  cursor: sw-resize;
}

.imageResizerNw {
  top: -6px;
  left: -6px;
  cursor: nw-resize;
}

.FocusedImage {
  outline: 1px solid $colorBlue300;
  user-select: none;
}
