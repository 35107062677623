@import "../../../../../basics-scss/mixins";

.DateTimePickerInput {
  position: relative;
  flex: 1;
}

.Group {
  display: flex;

  .button {
    flex: 0 0 32px;
    margin-left: 4px;
  }
}