@import "../../../basics-scss/mixins";

.breadcrumb {
  font-size: $fontSizeCaption;
  line-height: $lineHeightCaption;
  text-transform: uppercase;
  color: $colorNeutral600;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -4px;

  @include forMedia(sm) {
    flex-wrap: nowrap;
  }
}

.item {
  position: relative;
  margin: 0 24px 4px 0;
  color: $colorNeutral600;
  white-space: nowrap;
  max-width: 312px;
  cursor: pointer;

  &:last-child {
    cursor: default;
    margin-right: 0;
  }

  @include forMedia(sm) {
    max-width: none;
  }
}

.itemText {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.arrow {
  cursor: default;
  position: absolute;
  right: -20px;
  top: -1px;
  @include svgIcon(16px,16px,$colorNeutral600);
}