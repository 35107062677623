@import "../../basics-scss/grid";

.basic_row {
    display: flex;
}

.basic_column {
    flex: 1;
}

.FixedContainer {
    max-width: 1320px;
    margin: 0 auto;
}
