$colorTeal500: #00BBCC;
$colorTeal400: #00D2E6;
$colorTeal300: #00EAFF;
$colorTeal200: #B2F9FF; 
$colorTeal100: #CCFBFF; 
$colorTeal070: #E5FDFF; 

$colorPurple500: #6800B2; 
$colorPurple400: #7700CC; 
$colorPurple300: #8600E5; 
$colorPurple200: #BF66FF; 
$colorPurple100: #D499FF; 
$colorPurple070: #F4E5FF; 

$colorOrange500: #E68600; 
$colorOrange400: #FF9500; 
$colorOrange300: #FF9F19; 
$colorOrange200: #FFBF66; 
$colorOrange100: #FFD599; 
$colorOrange070: #FFF4E5; 

$colorRed500: #B21F00; 
$colorRed400: #CC2200; 
$colorRed300: #E52600; 
$colorRed200: #FF7F66; 
$colorRed100: #FFAA99; 
$colorRed070: #FFE9E5; 

$colorGreen500: #008035; 
$colorGreen400: #009940; 
$colorGreen300: #00B24A; 
$colorGreen200: #B3FFD3; 
$colorGreen100: #CCFFE1; 
$colorGreen070: #E5FFF0; 

$colorBlue500: #0068B3; 
$colorBlue400: #0077CC; 
$colorBlue300: #0086E5; 
$colorBlue200: #66BFFF; 
$colorBlue150: #83B5FC;
$colorBlue100: #99D5FF; 
$colorBlue010: #E7F0FE;
$colorBlue070: #E5F4FF; 

$colorNeutral800: #2E3238;
$colorNeutral600: #5C6470;
$colorNeutral090: #ABB1BA;
$colorNeutral080: #D5D8DD;
$colorNeutral075: #E3E5E8;
$colorNeutral077: #E3E3E3;
$colorNeutral070: #F2F2F2;
$colorNeutral060: #F0F2F4;
$colorNeutral050: #F1F2F3;
$colorNeutral040: #F7F7F8;
$colorNeutral020: #F5F9FE;
$colorNeutral010: #FCFCFD;
$colorNeutral000: #FFFFFF;


$colorOverlay: rgba(11, 12, 14, 0.4);