@import "../../../../../basics-scss/mixins";

.Input {
    flex: 1;
    position: relative;
    width: 100%;
    height: 32px;
    padding: 0 8px;
    font-size: $fontSizeInput;
    color: $colorNeutral800;
    background-color: $colorNeutral010;
    background-image: none;
    border: 1px solid $colorNeutral080;
    border-radius: 4px;
    display: block;
    -moz-appearance:textfield;
    overflow: hidden;
    cursor: pointer;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    @include transition();

    &:hover:not(.readonlyInput) {
        background-color: $colorNeutral060;
    }

    &[disabled],
    &[readonly] {
        @include readonlyInputStyles;
    }

    &.warningInput {
        border-color: $colorRed300;
    }

    &.readonlyInput {
        @include readonlyInputStyles;
        background-color: $colorNeutral040!important;
        .Mask {
            @include readonlyInputStyles;
        }
    }

    ::selection {
        background-color: transparent;
    }
}

.HasFocused {
    background-color: $colorNeutral000!important;
    border-color: $colorBlue150;
    box-shadow: inset 0 0 1px 1px $colorBlue150;
}

.Mask {
    position: absolute;
    left: 8px;
    right: 8px;
    top: 50%;
    height: 20px;
    transform: translateY(-50%);
    font-size: $fontSizeLabel;
    line-height: $lineHeightLabel;
    overflow: hidden;
    white-space: nowrap;
}

.TabMask {
    color: $colorNeutral090;
}

.Active {
    background-color: $colorBlue010;
}

.HiddenInput {
    position: absolute;
    width: 20px;
    left: -30px;
}

.SelectionOn {
    & > * {
        background-color: $colorBlue010;
    }
}