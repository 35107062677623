@import "../../basics-scss/mixins";

.Container {
  position: relative;
  flex: 1;
  display: grid;
  grid-template-columns: 32px auto 32px;
  //justify-content: space-between;
  height: 32px;
  background-color: $colorNeutral010;
  border: 1px solid $colorNeutral080;
  border-radius: 4px;

  &:hover {
    background-color: $colorNeutral060;

    input {
      background-color: $colorNeutral060;
    }

    .EditIcon {
      @include svgIcon(16px, 16px, $colorNeutral800);
    }
  }

  &.Focus {
    background-color: $colorNeutral000;
    border-color: $colorBlue150;
    box-shadow: inset 0 0 1px 1px $colorBlue150;

    input {
      background-color: $colorNeutral000;
    }
  }

  .FocusIcon {
    cursor: pointer;
    @include svgIcon(16px, 16px, $colorNeutral800);
  }

  input {
    width: 100%;
    margin: 3px 3px 3px 0;
    font-size: $fontSizeInput;
    line-height: $lineHeightBody;
    color: $colorNeutral800;
    display: block;
    border: none;
    @include placeholder();
    @include phLineHeight(30px);
  }

  .Link {
    padding: 5px 5px 5px 0;
    height: 30px;
    width: 100%;

    font-size: $fontSizeInput;
    line-height: $lineHeightBody;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    display: inline-block;

    &a:hover {
      text-decoration: underline;
    }
  }
}

.Icon {
  width: 32px;
  height: 32px;
  padding: 8px;
  @include svgIcon(16px, 16px, $colorNeutral090);
}

