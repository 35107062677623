.pivot  {
    width: 100%;
    margin-top: 32px;

    table {
        font-size: 14px;
        margin:auto;
        border-collapse: separate;
        width: min-content;

        tbody {
            tr {
                height: 63px;
                td {
                    padding: 16px;
                    color: #17181d;
                    vertical-align: middle;
                    text-align: left;
                    white-space: nowrap;
                }

                &:nth-child(2) {
                    th {
                        &:nth-child(2) {
                            border-top-left-radius: 4px;
                        }
                        &:nth-last-child(1) {
                            border-top-right-radius: 4px;
                        }
                    }
                }
                &:nth-child(2n+3) {
                    background-color: #eff5fa;
                }
            }
        }
    }
}

.colsFieldName, .rowsFieldName {
    font-size: 14px;
    color: #334d6e;
    border:none;
    vertical-align: middle;
    text-align: center;
}
.colsFieldName {
    padding: 0 24px 24px 24px;
}
.rowsFieldName {
    padding: 24px 24px 24px 0;
}
.rowsFieldName span {
    white-space: nowrap;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
}

.rowHeader, .colHeader {
    padding: 16px;
    color: #97a0af;
    font-weight: normal;
    vertical-align: middle;
    text-align: left;
}

.rowHeader {
    border-left: #dfe1e5 solid 1px;
    border-right: #dfe1e5 solid 1px;
}

.colHeader {
    border-top: #dfe1e5 solid 1px;
    border-bottom: #dfe1e5 solid 1px;
}
.rowHeader span, .colHeader span {
    white-space: nowrap;
    display: block;
}

.rowTotal, .colTotal {
    color: #17181d;
    font-weight: bold;
}

.rowTotal {
    border-top: #dfe1e5 solid 1px;
    border-bottom: #dfe1e5 solid 1px;
    &:nth-child(1) {
        text-align: right;
        border-bottom-left-radius: 4px;
    }
    &:nth-last-child(1) {
        border-bottom-right-radius: 4px;
    }
}

.colTotal {
    border-right: #dfe1e5 solid 1px;
}


