@import "../../../../../basics-scss/mixins";

.URL {
    flex: 1;
    display: flex;

    .linkWrap {
        flex: auto;
        height: 32px;
        line-height: 33px;
        position: relative;
        border-radius: 4px;
        &.readOnly {
            background: $colorNeutral040;
        }
    }
    .link {
        position: absolute;
        left: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
        display: inline-block;
        vertical-align: top;
        padding: 0 0 0 9px;
    }
    .button {
        flex: 0 0 32px;
        margin-right: 4px;
    }
}