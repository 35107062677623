@import "../../../basics-scss/mixins";

.ModalWrapper {
  position: absolute;
  z-index: $zIndexModal;
  right: 0;
  top: 65px;
  width: 512px;
  height: 100%;
  display: block;
}

.ModalWindow {
  position: relative;
  z-index: $zIndexLow;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 0 4px 0 rgba(0, 0, 0, 0.25);
  background-color: $colorNeutral000;
}

.ModalHeader {
  position: relative;
  padding: 24px 24px 18px;
  //background-color: #fbfcfd;
  border-bottom: 1px solid $colorNeutral080;
  border-radius: 4px 4px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 22px;
  line-height: 1.55;
  color: $colorNeutral800;
  font-family: $fontFamilyHeadline;
  font-weight: 600;

  .ModalText {
    max-width: 90%;
  }

  .ModalCloseIco {
    cursor: pointer;
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
    line-height: 0;
    color: #6e728f;
    & > * {
      width: 24px;
      height: 24px;
    }
  }
}

.ModalBody {
  padding: 20px;
}
