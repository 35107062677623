@import "../../basics-scss/mixins";

.ReportName {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}

.highchart {
    padding-top: 32px;
    overflow: auto;
    @include customScrollbar;

  .List {
    overflow: hidden;
    border-radius: 4px;
    border: solid 1px #dfe1e5;
    background-color: $colorNeutral000;

    .TableWrapper {
      margin: 0 0 50px 0;
      background: $colorNeutral000;
    }

    .Header {
      border-bottom: 1px solid $colorNeutral080;
      th {
        height: 55px;
        padding: 0 0 0 14px;
        vertical-align: middle;
        color: #97a0af;
        font-size: 14px;
      }
    }

    .Body {
      min-height: 1px;
      td {
        padding: 12px 14px;
        vertical-align: middle;
      }
    }

    .InfoCell {
      vertical-align: middle;
      padding: 0;
    }

    .FixedHorizontalScroll {
      bottom: 49px;
      height: 20px;
    }

    .NonFixedHorizontalScroll {
      position: inherit;
      bottom: 49px;
      height: 20px;
    }
  }
}

.chart {
    position: relative;

    .title {
        position: absolute;
        z-index: $zIndexLow;
        max-width: 100%;
       .text {
            font-size: 15px;
            overflow: hidden;
            text-overflow: ellipsis;
       }
    }
}

.Digit {
    font-family: 'Montserrat', sans-serif;
    font-size: 80px;
    font-weight: 800;
    text-align: center;
}

.FixedListFooter {
  z-index: $zIndexLow;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 50px;
  background: $colorNeutral000;
}

.ListFooter {
  position: inherit;
  width: 100%;
  height: 50px;
  background: $colorNeutral000;
}

.SelectGroupBy {
  width: 400px;
}

.PointsLimitMessage {
  text-align: center;
  margin-bottom: 10px;
  color: $colorRed200;
}
