/**
 * копипаста с bootstrap
 */

/**
xs (for phones - screens less than 768px wide)
sm (for tablets - screens equal to or greater than 768px wide)
md (for small laptops - screens equal to or greater than 992px wide)
lg (for laptops and desktops - screens equal to or greater than 1200px wide)
 */
$grid-columns: 12;
$grid-breakpoints: (
    xs: 0,
    sm: 768px,
    md: 992px,
    lg: 1200px,
);

.Row {
    display: flex;
    flex-wrap: wrap;

    > * {
        flex-shrink: 0;
        width: 100%;
        max-width: 100%;
    }
}

.Columns {
    flex: 0 0 auto;

    ol {
        display: block;
        list-style-type: decimal;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 20px;

        li {
            display: list-item;
        }
    }
}

@each $breakpoint in map-keys($grid-breakpoints) {
    $min: map-get($grid-breakpoints, $breakpoint);

    @media (min-width: $min) {
        @for $size from 1 through $grid-columns {
            .Columns-#{$breakpoint}-#{$size} {
                width: percentage($size / $grid-columns);
            }
        }
    }
}

