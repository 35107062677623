@import "../../../basics-scss/mixins";

.PropertiesPanel {
  display: flex;
  flex-flow: column nowrap;
  margin: 42px 0 0 0;
  box-sizing: border-box;
  background: $colorNeutral000;
  position: fixed;
  z-index: $zIndexHigh;
  right: 0;
  width: 480px;
  border-left: 1px solid #DFE1E5;
  height: calc(100% - 42px);
}

.Tabs{
  display: flex;
  justify-content: space-around;
  flex-flow: row nowrap;
  padding: 12px 4px 6px 4px;
  border-top: 1px solid $colorNeutral080;
  border-bottom: 1px solid $colorNeutral080;
  width: 100%;
  height: 49px;
}

.Tab{
  display: flex;
  margin: 0 8px 0 8px;
  cursor: pointer;
  user-select: none;
  color: #A9ACBE;
  justify-content: center;
  align-items: center;
}

.ActiveTab{
  color: $colorNeutral800;
}

.CloseButton{
  position: absolute;
  top: 7px;
  right: 20px;
  height: 10px;
  width: 10px;
  cursor: pointer;
  user-select: none;
}

.Header{
  padding: 9px 16px 12px 16px;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  height: 41px;
  border-top: 1px solid $colorNeutral080;
  border-bottom: 1px solid $colorNeutral080;
}

.Form{
  display: flex;
  flex: 100 0 0;
  flex-flow: column nowrap;
  justify-content: flex-start;
  padding: 12px 16px;
  overflow: auto;
  min-width: 360px;
}

.ButtonsPanel{
  display: flex;
  position: relative;
  flex: 1 0 auto;
  justify-content: center;
  flex-flow: row nowrap;
  align-items: flex-end;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.01em;
  padding: 14px 0;
  height: 68px;
}

.ButtonCancel{
  display: flex;
  width: 136px;
  height: 32px;
  margin: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.ButtonSave{
  display: flex;
  width: 136px;
  height: 32px;
  margin: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.Scroll::-webkit-scrollbar {
  width: 13px;
  height: 13px;
}

.Scroll::-webkit-scrollbar-track {
  background-color: #F4F7FD;
}

.Scroll::-webkit-scrollbar-button{
  display: none;
}

.Scroll::-webkit-scrollbar-thumb {
  background-color: rgba(110, 114, 143, 0.5);
  border-radius: 6px;
  border: 3px solid transparent;
  background-clip: content-box;
}

.Line{
  width: 100%;
  border: 1px solid $colorNeutral080;
  transform: rotate(180deg);
}

.Group{
  padding: 6px 16px;
}

.Label{
  padding: 4px 0;
  font-size: 12px;
  line-height: 16px;
  color: $colorNeutral800;
  width: 100%;
}

.Text{
  padding: 4px 0;
  width: 100%;
  line-height: 18px;
  color: $colorNeutral800;
}

.Info{
  display: flex;
  flex: 100 0 0;
  flex-flow: column nowrap;
  justify-content: flex-start;
  overflow: auto;
  min-width: 360px;
  padding: 9px 0 0 0;
}
