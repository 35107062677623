@import "../../basics-scss/mixins";

.DropdownContainer {
  width: 328px;
  @include dropDown;
  position: relative;
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;

  &.Shadow {
    position: relative;
    z-index: $zIndexHigh;
    box-shadow: $boxShadowFixedHead;
  }
}

.SubTitle {
  font-size: $fontSizeLabel;
  line-height: $lineHeightLabel;
  color: $colorNeutral090;
  margin-left: 8px;
}

.PrepareIcon {
  @include svgIconColor($colorBlue500);
}

.LoadedIcon {
  @include svgIconColor($colorGreen500);
}

.NotFoundContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 32px;
}

.NotFoundText {
  font-size: $fontSizeBody;
  line-height: $lineHeightBody;
  font-weight: 400;
  color: $colorNeutral090;
}

.NotFoundIcon {
  @include svgIcon(40px, 40px, $colorNeutral090);
  margin-bottom: 8px;
}

.ContentContainer {
  overflow-y: auto;
  @include customScrollbar;

  max-height: 432px;
  padding: 0 16px 16px 16px;

  &.ColumnCards {
    .CardsContainer {
      flex-direction: column;
    }

    .CardContainer {
      &:first-child {
        margin-top: 8px;
      }
    }
  }

  &.RowCards {
    max-height: none;
    padding: 0;
    overflow: visible;

    .CardsContainer {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      margin-top: 8px;
    }
  }

  .ContentControls {
    display: flex;
    justify-content: flex-start;
    gap: 4px;
  }

  .CardsContainer {
    display: flex;
    gap: 8px;
  }
}

.MobileDropdownContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $colorNeutral000;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.MobileHeader {
  padding: 14px 16px 13px;
  border-bottom: 1px solid $colorNeutral080;
  display: flex;
}

.MobileTitle {
  text-transform: uppercase;
  font-size: $fontSizeTitle;
  line-height: $lineHeightTitle;
  color: $colorNeutral600;
  font-family: $fontFamilyMain;
  font-weight: 600;
  padding: 6px 0 0;
  margin: 2px 2px 2px 0;
}

.MobileClose {
  margin: 2px 2px 2px auto;
}

.MobileMenu {
  .ColumnCards {
    padding-top: 16px;
    max-height: calc(100vh - 64px);
  }
}

.Loader {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    border: 3px solid $colorBlue500;
    border-radius: 50%;
    animation: loader 1.2s linear infinite;
    border-color: $colorBlue500 transparent transparent transparent;
  }

  & span::before, & span::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0;
    border-radius: 50%;
    border: 3px solid $colorNeutral080;
  }

  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.PendingData {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  gap: 4px;
}

.PendingIcon {
  @include svgIcon(16px, 16px, $colorNeutral090);

  &:hover {
    @include svgIconColor($colorNeutral800);
  }
}

.ErrorText {
  color: $colorRed500;
}

.FullModal {
  width: 832px;

  @include forMedia(864px) {
    width: 100%;
  }
}

.ModalSubTitle {
  color: $colorNeutral090;
  margin-left: 8px;
}

.IconStatus {
  display: flex;
  align-items: center;
  @include svgIcon(24px, 24px, $colorNeutral800);
}

.IconStatusError {
  @extend .IconStatus;
  @include svgIconColor($colorRed500);
}

.IconStatusDone {
  @extend .IconStatus;
  @include svgIconColor($colorGreen500);
}

.CardContainer {
  padding: 16px;
  outline: 1px solid $colorNeutral080;
  border-radius: 4px;
  height: 84px;
  background: $colorNeutral010;
  position: relative;
  @include transition();

  &:hover {
    background: $colorNeutral060;

    .CardControls {
      display: flex;
      @include animateFadeIn();
    }
  }

  &.CardPointer {
    cursor: pointer;
  }

  &.CardSelected {
    outline: 2px solid $colorBlue300;
  }
}

.CardContent {
  display: flex;
}

.CardText {
  display: flex;
  flex-direction: column;
  flex: auto;
  margin-left: 8px;
  overflow: hidden;
}

.WrapCardText {
  position: relative;

  > span {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.CardTitle {
  @extend .WrapCardText;
  color: $colorNeutral800;
  font-size: $fontSizeLabel;
  line-height: $lineHeightLabel;
  font-weight: 600;
  height: 20px;
}

.CardDescription {
  @extend .WrapCardText;
  color: $colorNeutral600;
  font-size: $fontSizeCaption;
  line-height: $lineHeightCaption;
  font-weight: 400;
  height: 16px;
}

.CardControls {
  @include dropDown;
  position: absolute;
  top: 4px;
  right: 4px;
  display: none;
  outline: 1px solid $colorNeutral080;
  padding: 4px;

  @include forMedia(sm) {
    display: flex;
  }
}

.CardCheckbox {
  display: flex;
  flex: 0 0 16px;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  margin: 8px;
  border-radius: 4px;
  border: 2px solid $colorNeutral080;
  cursor: pointer;
  background: $colorNeutral010;
  @include svgIcon(8px, 6px, $colorNeutral000);

  & > * {
    visibility: hidden;
  }

  &:hover {
    background: $colorNeutral060;
  }

  &:active {
    background: $colorNeutral050;
    border-color: $colorNeutral090;
  }

  &.ActiveCheckbox {
    background: $colorBlue300;
    border-color: $colorBlue500;

    & > * {
      visibility: visible;
    }
  }
}

.WarningModal {
  width: 576px;

  @include forMedia(sm) {
    width: 100%;
  }
}

.WarningModalText {
  font-size: $fontSizeBody;
  line-height: $lineHeightBody;
  font-weight: 400;
  color: $colorNeutral800;
}

.WarningModalButtons {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
