@import "../../../../../basics-scss/mixins";

.Text {
    color: $colorNeutral090;
    line-height: 31px;
    height: 32px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.Icon {
    display: inline-block;
    width: 12px;
    vertical-align: middle;
    margin-right: 5px;
    top: -1px;
    position: relative;
    @include svgIcon(100%,auto,$colorNeutral090);
}