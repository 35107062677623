@import "../../../basics-scss/mixins";

.ModalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include forMedia(sm) {
        display: none;
    }
}

.ModalHeaderTitle {
    color: $colorNeutral800;
    font-size: $fontSizeH3;
    line-height: $lineHeightH3;
    font-family: $fontFamilyHeadline;
}

.ModalHeaderMobile {
    display: none;
    justify-content: space-between;
    align-items: center;

    @include forMedia(sm) {
        display: flex;
    }
}

.ModalFooterMobile {
    display: none;

    @include forMedia(sm) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.ModalHeaderMobileTitle {
    font-size: $fontSizeTitle;
    line-height: $lineHeightTitle;
    color: $colorNeutral600;
    text-transform: uppercase;
    font-weight: 600;
}

.ModalHeaderH2 {
    margin-top: 24px;
    color: $colorNeutral800;
    font-weight: 600;
    font-size: $fontSizeH2;
    line-height: $lineHeightH2;
    font-family: $fontFamilyHeadline;

    @include forMedia(sm) {
        display: none;
    }
}

.ModalHeaderMobileH2 {
    display: none;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 16px;

    @include forMedia(sm) {
        display: flex;
    }
}

.ModalHeaderMobileH2Title {
    font-size: $fontSizeH3;
    font-weight: 600;
    line-height: $lineHeightH3;
    font-family: $fontFamilyHeadline;
}

.ModalWindow {
    width: 100%;
    max-width: 800px;
    padding: 0;

    @include forMedia(sm) {
        max-width: none;
        height: 100%;
    }
}

.ModalWindowHeader {
    @include forMedia(sm) {
        padding: 0;
        border-bottom: 1px solid $colorNeutral080;
    }
}

.ModalHeaderAttach {
    margin-left: 4px;
}

.ModalHeaderBtn {
    margin-left: 4px;

    @include forMedia(sm) {
        width: 100%;
        margin-left: 0;
        margin-top: 16px;

        &:first-child {
            margin-top: 0;
        }
    }
}

.CancelBtn {
    @include forMedia(sm) {
        border: 0;
    }
}
