@import "../../../../../basics-scss/mixins";

.Input {
    background-color: #dfe1e5;
    height: 32px;
    border-radius: 4px;
}

.Loader {
    background-color: #dfe1e5;
    max-width: 160px;
    height: 16px;
    border-radius: 4px;
}

.Wrapper {
    position: relative;
    overflow: hidden;
}

.Shadow {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    &:after {
        @include animatedShadow(2s,88px);
    }
}