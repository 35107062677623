@import "../../../../../basics-scss/mixins";

.Reference {
    display: flex;
    position: relative;
    flex: 1;

    .input {
        flex: auto;
        position: relative;
    }

    .button {
        flex: 0 0 32px;
        margin-left: 4px;
    }

    &.readOnly {
        .FieldWrap {
            @include readonlyInputStyles;
        }
        .FieldOverlay {
            cursor: default;
        }
    }
}

.Info {
    @include svgIcon(14px, 14px);
}


.Paperclip {
    justify-content: center;
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    @include svgIcon(18px, 18px, $colorNeutral800);
}

.ReferenceWrap {
    display: flex;
}

.ListInputWrap {
    position: relative;
    margin: -4px 0 -6px -4px;
}

.ListInput {
    input {
        background: transparent !important;
        border: none !important;
        padding: 0 0 0 8px !important;
        box-shadow: none !important;
        display: none;
    }
}

.ListPlus {
    position: absolute;
    top: -1px;
    right: -37px;
}

.ListSearch {
    position: absolute;
    top: -1px;
    right: -37px;

    &.CanCreate {
        right: -73px;
    }
}

.Field {
    position: relative;
    padding: 3px 44px 3px 3px;
    display: block;
    height: 30px;

    &.readonly {
        padding-right: 3px;
    }
}

.FieldWrap {
    background: $colorNeutral010;
    border: 1px solid $colorNeutral080;
    border-radius: 4px;
    @include transition();

    input {
        border: none;
        background: transparent;
        box-shadow: none;
        height: 30px;
    }

    &:hover {
        background-color: $colorNeutral060;
        //border-color: $colorNeutral090;
    }

    &.focus {
        background-color: $colorNeutral000;
        border-color: $colorBlue150;
        box-shadow: inset 0 0 1px 1px $colorBlue150;
    }

    &.Warning {
        border-color: $colorRed300;
    }
}

.FieldReadonly {
    display: block;
    height: 30px;
}

.FieldOverlay {
    cursor: text;
    position: absolute;
    left: -1px;
    top: -1px;
    right: -1px;
    bottom: -1px;
    background: transparent;
    border: 1px solid transparent;
    border-radius: 4px;

    &:focus {
        border-color: $colorBlue150!important;
        box-shadow: inset 0 0 1px 1px $colorBlue150!important;
    }
}

.FieldClear {
    cursor: pointer;
    position: absolute;
    right: 7px;
    top: 7px;
    @include svgIcon(16px,16px,$colorNeutral800);
}

.FieldSearch {
    position: absolute;
    right: 8px;
    top: 6px;
    @include svgIcon(18px,18px,$colorNeutral800);
}

.Hidden {
    display: none;
}

.Badge {
    display: block;
    height: 24px;
    width: auto;
    line-height: 20px;
    background: $colorBlue070 !important;
    border: 1px solid $colorBlue100 !important;
    box-shadow: none !important;
    color: $colorNeutral800;
    padding: 0 8px;
    border-radius: 4px;
    position: absolute;
    left: 0;
    top: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;

    &.cursorDefault {
        cursor: default!important;
    }

    &.disabled {
        background: $colorNeutral050!important;
        border-color: $colorNeutral080!important;
        cursor: default!important;
    }
}

.BadgeWrap {
    position: relative;
}

.DropDownArrow{
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    @include svgIcon(20px,20px,$colorNeutral800);
    border-radius: 0 4px 4px 0;
    padding: 5px;
    border-left: 1px solid #D5D8DD;
}

.menuItem {
    padding: 4px 12px 5px;
    cursor: pointer;
    color: $colorNeutral800;

    &.active {
        background-color: $colorNeutral070;
    }

    &.selected {
        background-color: $colorBlue010;
    }
}
.menu {
    @include dropDown;
    @include customScrollbar;
    max-height: 250px;
    overflow-y: auto;
    padding: 8px 0;
}

.FieldClearOpt {
    cursor: pointer;
    position: absolute;
    right: 40px;
    top: 7px;
    @include svgIcon(16px,16px,$colorNeutral800);
}

.SelectedOption {
    display: block;
    height: 24px;
    width: auto;
    line-height: 20px;
    color: $colorNeutral800;
    padding: 2px 0;
    position: absolute;
    left: 0;
    top: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    cursor: default;
}


.BadgeEye {
    display: inline-block;
    vertical-align: -3px;
    margin-right: 8px;
    @include svgIcon(16px,16px,$colorNeutral800);
}

.BadgeDelegation {
    @extend .BadgeEye;

    @include svgIconColor($colorPurple300);
}