@import "../../basics-scss/mixins";

.Menu {
    @include dropDown;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 8px 0;
    min-width: 163px;
    max-height: 220px;
    @include customScrollbar;

    & > * {
        height: 27px;
        line-height: 27px;
        cursor: pointer;
        color: $colorNeutral800;
        padding: 0 12px;
        &:hover {
            background: $colorNeutral070;
        }
    }
}

.Mobile {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $colorNeutral000;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.MobileHeader {
    padding: 14px 16px 13px;
    border-bottom: 1px solid $colorNeutral080;
    display: flex;
}

.MobileTitle {
    text-transform: uppercase;
    font-size: $fontSizeTitle;
    line-height: $lineHeightTitle;
    color: $colorNeutral600;
    font-family: $fontFamilyMain;
    font-weight: 600;
    padding: 6px 0 0;
    margin: 2px 2px 2px 0;
}

.MobileClose {
    margin: 2px 2px 2px auto;
}

.MobileMenu {
    & > * {
        padding: 22px 16px;
        border-bottom: 1px solid $colorNeutral080;
        font-size: $fontSizeButton;
        line-height: $lineHeightBody;
        color: $colorNeutral800;
        font-weight: 600;
    }
}

.Freeze {
    overflow: hidden;
}