@import "../../basics-scss/mixins";

// не надо сюда навешивать цвет фона, тени и прочее!
.Dropdown {
    z-index: $zIndexDropDown;
    @include animateFadeIn();
    max-width: 98%;

    // начальные стили
    // нужны чтобы избежать неправильных расчётов и скачков экрана
    position: fixed;
    top: -9999px;
    left: -9999px;
}
