@import "../../../basics-scss/mixins";

.TabsBlock {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  .Tabs {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: -8px;

    .Tab {
      background: $colorNeutral040;
      padding: 2px 16px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: $colorNeutral800;
      margin-right: 8px;
      margin-bottom: 8px;
      cursor: pointer;
      border-radius: 4px;

      &:hover {
        background: $colorNeutral060;
      }

      &:active {
        background: $colorBlue100;
      }

      &.TabActive {
        background: $colorBlue070;
      }

    }
  }

  .Settings {
    position: relative;

    .SettingsMore {
      width: 24px;
      height: 24px;
      @include svgIcon(20px,20px,$colorNeutral800);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @include transition();

      &.SettingsActiveMore {
        background: $colorBlue010;
        &:hover {
          background: $colorBlue010;
        }
      }

      &:hover {
        background: $colorNeutral070;
      }
    }
  }
}
