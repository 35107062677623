@import "../../basics-scss/mixins";

.Wrap {
  position: relative;
}

.ElementsBlock {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  width: fit-content;

  @include svgIcon(20px, 20px, $colorNeutral800);
}

.ElementsText {
  font-weight: 400;
  font-size: $fontSizeTitle;
  line-height: $lineHeightTitle;

  color: $colorNeutral600;
  padding-left: 4px;
}

.Menu {
  @include dropDown;
  @include customScrollbar;
  max-height: 250px;
  overflow-y: auto;
  padding: 8px 0;
  max-width: 250px;
}

.MenuItem {
  padding: 6px 16px;
  cursor: pointer;
  color: $colorNeutral800;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: $fontSizeBody;
  line-height: $lineHeightBody;
  display: flex;
  align-items: center;

  &.Focus {
    background-color: $colorNeutral070;
  }
}

.MenuItemIcon {
  margin-left: 25px;

  @include svgIcon(16px, 16px, $colorGreen300);
}