@import "../../../basics-scss/mixins";

.LinkContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .TextContent {
    flex: 1;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.BaseIcon {
  display: flex;
  align-items: center;
  padding: 0 4px;

  @include svgIcon(16px,16px);
}