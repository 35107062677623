@import "../../../basics-scss/mixins";

.List{
  padding: 0px;
}

.Workflows{
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  width: 100%;
  height: 100%;
  margin: 12px 0px;
  overflow-y: scroll;
}

.Workflows::-webkit-scrollbar {
  width: 13px;
  height: 13px;
}

.Workflows::-webkit-scrollbar-track {
  //background-color: #F4F7FD;
}

.Workflows::-webkit-scrollbar-button{
  display: none;
}

.Workflows::-webkit-scrollbar-thumb {
  background-color: rgba(110, 114, 143, 0.5);
  border-radius: 6px;
  border: 3px solid transparent;
  background-clip: content-box;
}

.Workflow{
  position: relative;
  width: 251px;
  height: 76px;
  margin: 0 12px 12px 0;
  background: $colorNeutral000;
  border: 1px solid $colorNeutral080;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  &:hover{
    border: 1px solid #5927D3;
  }
}

.Name{
  position: absolute;
  left: 48px;
  top: 28px;
  width: 191px;
  height: 20px;
  line-height: 18px;
  color: $colorNeutral800;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.Table{
  position: absolute;
  left: 48px;
  top: 52px;
  width: 191px;
  height: 12px;
  font-size: 9px;
  line-height: 12px;
  color: #6E728F;
}


.IconCheckedOut {
  position: absolute;
  left: 14px;
  top: 28px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  @include svgIcon(20px,20px,#FDAC41);
}

.IconPublished {
  position: absolute;
  left: 14px;
  top: 28px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  @include svgIcon(20px,20px,#00CFDD);
}

.User{
  position: absolute;
  left: 48px;
  top: 12px;
  font-size: 9px;
  line-height: 12px;
  letter-spacing: 0.01em;
  color: #6E728F;
}

.Date{
  position: absolute;
  left: 163px;
  top: 12px;
  width: 76px;
  height: 12px;
  font-size: 9px;
  line-height: 12px;
  text-align: right;
  letter-spacing: 0.01em;
  color: #6E728F;
}

.SearchPanel{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.FilterButtons{
  display: flex;
  flex-flow: row wrap;
  margin: 0 25px 0 0;
}

.IconButton{
  width: 32px;
  height: 32px;
  cursor: pointer;
  user-select: none;
  margin: 0 0 0 8px;
  background: $colorNeutral000;
  border: 1px solid $colorNeutral080;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px;
  @include svgIcon(16px,16px,$colorNeutral090);
  &:hover{
    border: 1px solid $colorNeutral090;
    @include svgIconColor($colorNeutral800);
  }
}

.IconButtonSelected{
  border: 1px solid $colorNeutral800;
  @include svgIcon(16px,16px,$colorNeutral800);
}