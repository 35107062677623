@import "../../../basics-scss/mixins";

.content {
    display: flex;
    min-width: 60px;
    max-width: 280px;
}

.container {
    display: inline-block;
    white-space: nowrap;
}

.containerWrap {
    position: relative;
    overflow: hidden;
}

.gradient {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 13px;
    z-index: $zIndexLow;
}

.tooltip {
    max-width: 300px;
    word-wrap: break-word;
}
