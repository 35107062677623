@import "../../../basics-scss/mixins";

.Element {
  display: flex;
  justify-content: flex-start;
  position: relative;
  padding: 4px 16px;
  cursor: pointer;
  width: 100%;

  &.Active {
    background-color: $colorBlue010;
  }

  &:hover {
    text-decoration: none;
    background-color: $colorNeutral070;
  }

  &:active {
    background-color: $colorBlue010;
  }

  &.NonClick {
    &:hover {
      background-color: inherit;
    }
  }
}

.ElementAvatar {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.ElementBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ElementUserTitle {
  font-weight: 400;
  font-size: $fontSizeTitle;
  line-height: $lineHeightTitle;
  color: $colorNeutral800;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.ElementUserProf {
  font-weight: 400;
  font-size: $fontSizeCaption;
  line-height: $lineHeightCaption;
  color: $colorNeutral090;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Ava {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: no-repeat center;
  background-size: cover;
  overflow: hidden;
  @include svgIcon(32px,32px);
}