@import "../../../../basics-scss/mixins";

.ToolsPanel {
    width: 100%;
    height: 100%;
    padding: 16px 18px 16px 12px;
    overflow-y: auto;
}

.ToolsPanel::-webkit-scrollbar {
    width: 13px;
}

.ToolsPanel::-webkit-scrollbar-track {
    background-color: #F4F7FD;
}

.ToolsPanel::-webkit-scrollbar-button {
    display: none;
}

.ToolsPanel::-webkit-scrollbar-thumb {
    background-color: rgba(110, 114, 143, 0.5);
    border-radius: 6px;
    border: 3px solid transparent;
    background-clip: content-box;
}

.ToolsPanelMenu {
    user-select: none;
}

.ToolsPanelMenuTitle {
    display: flex;
    flex-flow: row nowrap;
    padding: 8px 0;
    line-height: 18px;
    color: $colorNeutral800;
    cursor: pointer;
    user-select: none;
}

.ArrowRight {
    padding: 4px 10px 4px 0;
    @include svgIcon(12px, 12px, #cacfdd);
    cursor: pointer;
    user-select: none;
}

.ArrowDown {
    padding: 4px 10px 4px 0;
    @include svgIcon(12px, 12px, #17181d);
    cursor: pointer;
    user-select: none;
}

.ToolsPanelMenuText {
    font-family: $fontFamilyMain;
    font-size: 13px;
    line-height: 18px;
    color: #17181D;
}

.ToolsPanelSubmenu {
    padding: 0 0 0 24px;
}

.ToolsPanelSubmenuItem{
    display: flex;
    flex-flow: row nowrap;
    padding: 8px 0;
    cursor: pointer;
    user-select: none;
    color: $colorNeutral800;
    &:hover {
        color: #004AAD;
    }
}

.Icon {
    padding: 0 12px 0 0;
    @include svgIcon(18px, 18px, $colorNeutral800);
}

.UserIcon {
    margin: 0 12px 0 0;
    width: 18px;
    height: 18px;
}

.ToolsPanelSubmenuText {
    font-family: $fontFamilyMain;
    font-size: 13px;
    line-height: 18px;
}
