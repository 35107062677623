@import "../../../basics-scss/mixins";

.Skeleton {
    padding: 14px 16px 17px;
    position: relative;
    overflow: hidden;

    &.mobile {
        padding: 16px;
    }

    &.list {
        padding: 0;
    }
}
.Placeholder {
    background: $colorNeutral077;
    border-radius: 4px;
    height: 32px;
}
.Panel {
    display: flex;
    margin-bottom: 16px;
}
.PanelTitle {
    width: 244px;
    margin-right: 32px;
}
.PanelBtn {
    width: 88px;
    margin-right: auto;
}
.PanelBtnRight {
    width: 100px;
}
.Pagination {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
}
.PaginationTitle {
    margin-right: auto;
    height: 20px;
    width: 100px;
}
.Filter {
    display: flex;
    margin-bottom: 24px;
}
.FilterTitle {
    width: 180px;
    margin-right: auto;
}
.FilterPag {
    width: 232px;
}
.Table {
    display: flex;
}
.TablePlaceholder {
    background: $colorNeutral077;
    border-radius: 4px;
    height: 24px;
}
.TableTitle {
    //max-width: 112px;
    width: 40%;
    margin-bottom: 16px;
}
.TableSearch {
    margin-bottom: 32px;
}
.TableCont {
    //max-width: 144px;
    width: 55%;
    margin-bottom: 24px;
}
.TableCol {
    flex: 1;
    margin-right: 24px;
    &:last-child {
        margin-right: 0;
    }
    &:first-child {
        flex: 0 1 48px;
        .TablePlaceholder {
            width: 100%;
        }
        .TableSearch {
            visibility: hidden;
        }
    }
}
.MobCol {
    flex: 1;
    margin-right: 24px;
    &:last-child {
        margin-right: 0;
    }
}
@keyframes animateShadow {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(100%);
    }
}
.SkeletonShadow {
    animation: animateShadow 2s ease-in infinite;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: -50px;
        bottom: -50px;
        left: 50%;
        width: 88px;
        opacity: .5;
        transform: rotate(10deg) translateX(-50%);
        background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 40%, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%);
        background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 40%,rgba(255,255,255,1) 60%,rgba(255,255,255,0) 100%);
        background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 40%,rgba(255,255,255,1) 60%,rgba(255,255,255,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=1 );
    }
}