@import "../../basics-scss/mixins";

.Badge {
    height: 20px;
    line-height: 20px;
    white-space: nowrap;
    display: inline-block;
    vertical-align: top;
    border-radius: 4px;
    padding: 0 8px;
    font-weight: 600;
    font-family: $fontFamilyMain;
    font-size: $fontSizeBody;
    background: $colorNeutral080;
    color: $colorNeutral800;
    text-align: center;
}