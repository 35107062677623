.disabled .cm-scroller {
    background-color: #F7F7F8;
    cursor: default;
}

.cm-focused{
    outline: none !important;
}

.cm-editor {
    border: 1px solid #D5D8DD;
    border-radius: 4px;
    overflow: hidden;
}

.cm-theme-light .disabled {
    border-color: #F7F7F8;
}