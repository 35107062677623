@import "../../../../../../basics-scss/mixins";

.Badges {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -4px;
    line-height: 0;
}
.Badge {
    display: inline-block;
    vertical-align: top;
    height: 24px;
    width: auto;
    line-height: 20px;
    background: $colorBlue070!important;
    border: 1px solid $colorBlue100 !important;
    box-shadow: none!important;
    color: $colorNeutral800;
    padding: 0 28px 0 8px;
    border-radius: 4px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 4px 4px 0;
    max-width: 100%;

    &.cursorDefault {
        cursor: default!important;
    }

    &.hasTwoIcons {
        padding-left: 32px;
    }

    &.disabled {
        background: $colorNeutral050!important;
        border-color: $colorNeutral080!important;
        cursor: default!important;
    }

    &.readOnly {
        padding-right: 8px;
    }
}
.BadgeCross {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
    cursor: pointer;
    top: 3px;
    right: 8px;
    @include svgIcon(16px,16px,$colorNeutral800);
}

.BadgeEye {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
    top: 3px;
    left: 8px;
    @include svgIcon(16px,16px,$colorNeutral800);
}

.BadgeDelegation {
    @extend .BadgeEye;

    @include svgIconColor($colorPurple300);
}

.PureBadge {
    display: flex;
    align-items: center;
    font-size: $fontSizeBody;
    line-height: $lineHeightBody;
    color: $colorNeutral800;
    margin: 0 8px 4px 0;

    @include forMedia(sm){
        flex-wrap: wrap;
    }


    .PureBadgeEye {
        @include svgIcon(16px,16px,$colorNeutral090);
        margin-right: 4px;

        @include forMedia(sm){
            margin-top: 3px;
        }
    }

    .UnavailableText {
        color: $colorNeutral090;
    }
}

