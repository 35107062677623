@import "../../../basics-scss/mixins";

.Control{
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  width: 250px;
  margin: 4px 25px 4px 0;
  border-bottom: 1px solid $colorNeutral800;
  justify-content: space-between;
  align-items: center;
  .Choice{
    margin: 4px;
    padding: 0;
    border: 0;
    background-color: transparent;
    line-height: 18px;
    height: 18px;
    width: 100%;
    color: $colorNeutral800;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .IconArrow {
    margin: 8px 4px 8px 8px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    @include svgIcon(16px,16px,$colorNeutral800);
  }
  .List{
    position: absolute;
    top: 40px;
    @include dropDown();
    width: 250px;
    padding: 8px 0;
    z-index: $zIndexLow;
    .Item{
      padding: 4px 12px 5px;
      width: 100%;
      cursor: pointer;
      user-select: none;
      color: $colorNeutral800;
      font-size: $fontSizeBody;
      line-height: $lineHeightBody;
      &:hover{
        background-color: $colorNeutral070;
      }
    }
  }
}