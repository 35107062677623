@import '../../basics-scss/mixins';

.RadioButtons {

}

.Button {
    width: 16px;
    height: 16px;
    border: 1px solid $colorNeutral080;
    background: $colorNeutral010;
    border-radius: 50%;
    position: relative;
    top: 2px;
    display: block;
    float: left;
    padding: 0;
    @include transition();

    &:after {
        content: '';
        width: 8px;
        height: 8px;
        display: block;
        border-radius: 50%;
        background: $colorNeutral000;
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -4px 0 0 -4px;
        visibility: hidden;
    }

    &:hover {
        background: $colorNeutral060;
    }

    &:active {
        background: $colorNeutral050;
        border-color: $colorNeutral090;
    }

    &:focus:not(:active) {
        border: 2px solid $colorBlue150;
    }

    &.checked {
        background: $colorBlue300;
        border-color: $colorBlue500;

        &:after {
            visibility: visible;
        }
    }

    &[disabled] {
        background: $colorNeutral080 !important;
        border-color: $colorNeutral080 !important;

        &:after {
            background: $colorNeutral600 !important;
        }
    }
}

.Text {
    font-size: $fontSizeBody;
    line-height: $lineHeightBody;
    color: $colorNeutral800;
    margin-left: 24px;

    &.disabled {

    }
}

.Option {
    @include clearFix;
    margin-top: 8px;
}