@import "../../../basics-scss/mixins";

.List {
  font-size: 14px;
  line-height: 1;
  text-align: left;
  td {
    padding: 10px 8px;
    &:first-child {
      padding-left: 0;
    }
  }
}
.TransparentList {
  background: transparent;

  .Text {
    color: $colorNeutral800;
    &.strong {
      color: #e31450;
      font-weight: 600;
    }
  }

  .Date {
    color: $colorNeutral090;
  }
}
.WhiteList {
  background: #FFFFFF;
  box-shadow: 0 4px 10px rgba(1, 1, 1, 0.08);
  border-radius: 16px;
  padding: 40px;

  .Text {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #0050C8;
    margin-bottom: 8px;
  }

  .Date {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #334D6E;
  }
}

.SmallList {
  width: 648px;
}

.BigList {
  width: 920px;
}

.SeparatedRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;

  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
}

.Wrap {
  margin-bottom: 76px;
}

.State {
  width: 8px;
  height: 8px;
  border-radius: 50%;

  &.green { background-color: #7fb93c; }
  &.yellow { background-color: #d2ad34; }
  &.red { background-color: #e31450; }
  &.blue { background-color: #28aad4; }
  &.orange { background-color: #ff8801; }
  &.dark { background-color: $colorNeutral600; }
}

.FullState {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.FullStateText {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.01em;

  &.green { color: #7fb93c; }
  &.yellow { color: #d2ad34; }
  &.red { color: #e31450; }
  &.blue { color: #28aad4; }
  &.orange { color: #ff8801; }
  &.dark { color: #6e728f; }
}

.ColorBlock {
  color: #FFFFFF;
  height: 24px;
  border-radius: 16px;
  padding: 4px 16px;
  font-size: 12px;

  &.green { background: #7fb93c; }
  &.yellow { background: #d2ad34; }
  &.red { background: #e31450; }
  &.blue { background: #28aad4; }
  &.orange { background: #ff8801; }
  &.dark { background: #6e728f; }
}

.Icon {
  margin-left: 15px;
  width: 22px;
  height: 22px;

  img {
    margin: auto;
    width: 100%;
  }
}

.HeadlineText {
  font-weight: 600;
  font-size: $fontSizeH2;
  line-height: $lineHeightH2;
  color: $colorNeutral800;
  font-family: $fontFamilyHeadline;

  @include forMedia(sm){
    font-size: $fontSizeH3;
    line-height: $lineHeightH3;
    word-break: break-word;
  }
}

.HeadlineLink {
  font-size: $fontSizeBody;
  line-height: 28px;
  color: $colorBlue500;
  margin-left: 16px;

  a {
    color: $colorBlue500;
  }

  @include forMedia(sm){
    display: none;
  }
}

.AnnounceFooter {
  font-size: $fontSizeBody;
  line-height: $lineHeightBody;
  color: $colorBlue500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: none;
  text-align: center;
  margin-top: 10px;

  a {
    color: $colorBlue500;
  }

  @include forMedia(sm) {
    display: block;
  }
}

.BtnLink {
  display: block;
  height: 40px;
  line-height: 40px;
  background-color: rgba(228, 233, 241, 0.5);
  color: #737c8b;
  font-size: 15px;
  padding: 0 17px;
  margin-left: 21px;
  border-radius: 40px;
  font-weight: 600;
  &:hover {
    color: #17181d;
    background-color: #e4e9f1;
  }
}

.Link {
  color: inherit;
  word-break: break-word
}

.Dot {
  display: inline-block;
  background: #334D6E;
  width: 4px;
  height: 4px;
  border-radius: 4px;
  margin: 0 7px;
  vertical-align: middle;
}

.AnnounceList {
  font-size: $fontSizeBody;
  line-height: 1;
}

.AnnounceRow {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 8px;
  height: 24px;

  @include forMedia(sm){
    align-items: flex-start;
    flex-wrap: wrap;
    height: auto;
    position: relative;
  }
}

.AnnounceDate {
  flex: 0 0 172px;
  text-align: right;

  @include forMedia(sm){
    text-align: left;
    order: 3;
    flex-basis: 100%;
    padding-left: 16px;
  }
}

.AnnounceState {
  margin: 0 8px 0 24px;
  flex: 0 0 8px;

  @include forMedia(sm){
    margin: 0;
    position: absolute;
    top: 6px;
    left: 0;
  }
}

.AnnounceText {
  flex: auto;
  position: relative;
  height: 24px;
  font-size: $fontSizeBody;
  line-height: $lineHeightBody;

  .Text {
    color: $colorNeutral800;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.red { color: #e31450; }
  }

  @include forMedia(sm){
    height: auto;
    margin: 0 0 4px 16px;

    .Text {
      position: relative;
      top: 0;
      transform: none;
      white-space: normal;
      max-height: 40px;
    }
  }
}

.AnnounceHeadline {
  margin: 0 0 8px 196px;
  display: flex;
  align-items: flex-end;

  @include forMedia(sm){
    margin: 0 0 8px 0;
  }
}
