@import "../../../basics-scss/mixins";

.CommentItemBlock {

  .CommentItem {
    padding: 0 16px;
    margin-top: 16px;
    background: $colorNeutral000;

    .CommentTitleHead {
      color: $colorBlue500;
      font-size: $fontSizeCaption;
      line-height: $lineHeightCaption;
      font-weight: 600;
      padding: 4px;
      border-radius: 4px;
      margin-left: auto;
      text-align: right;
      white-space: nowrap;
      align-self: center;

      .CommentIcon {
        display: inline-block;
        vertical-align: top;
        @include svgIcon(16px, 16px, $colorBlue500);
        margin-right: 4px;
        @include forMedia(sm){
          margin-right: 0;
        }
      }

      .CommentTitle {
        display: inline;
        @include forMedia(sm){
          display: none;
        }
      }
    }

    .CommentText {
      margin: 0;
      padding-left: 40px;
      max-height: 100px;
      overflow: hidden;
      position: relative;

      textarea {
        font-size: $fontSizeBody;
        line-height: $lineHeightBody;
        color: $colorNeutral800;
        background: transparent;
        border: 0;
        padding: 0;
        min-height: 20px;
        box-shadow: none;
      }

      &.opened {
        max-height: none;
        &:after {
          display: none;
        }
      }
    }

    .OverflowGradient {
      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        height: 30%;
        background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
      }
    }

    .CommentUser {
      display: flex;
      justify-content: flex-start;

      .CommentContent {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }

      .CommentDate {
        font-size: $fontSizeCaption;
        line-height: $lineHeightCaption;
        color: $colorNeutral600;
      }

      .CommentUserTitle {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: $colorNeutral800;
        margin-right: 4px;
      }

      .CommentUserAvatar {
        width: 32px;
        height: 32px;
        margin-right: 8px;
        border-radius: 50%;
        background: no-repeat center;
        background-size: cover;
        flex: 0 0 32px;

        @include svgIcon(32px, 32px);
      }
    }

    .BottomLine {
      display: flex;
      justify-content: space-between;

      .SubCommentsButton {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: $colorNeutral600;
        cursor: pointer;

        .IconCommentSquare {
          @include svgIcon(20px, 20px, $colorNeutral600);
          margin-right: 10px;
        }
      }
    }
  }

  .SubComments {
    border-top: 1px solid $colorNeutral080;

    .SubCommentsHeader {
      margin: 16px 0;
      display: block;
      text-align: center;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      align-items: center;
      letter-spacing: 0.01em;
      color: #CACFDD;
    }

    .SubComment {
      margin-bottom: 24px;
      padding: 0 32px;
      display: flex;

      .SubCommentUserAvatar {
        width: 32px;
        height: 32px;
        margin-right: 8px;
      }

      .SubCommentBlock {
        width: 100%;
        background: #F6F7F9;
        border-radius: 16px 16px 16px 0;
        padding: 8px 16px;

        .SubCommentHeader {
          display: flex;
          justify-content: space-between;

          .SubAuthorHeader {
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: 0.01em;
            color: #334D6E;
          }

          .SubCommentUpdateDate {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            text-align: right;
            letter-spacing: 0.01em;
            color: #CACFDD;
          }
        }

        .SubCommentText {
          margin-top: 8px;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.01em;
          color: #6E728F;
        }
      }
    }
  }

  .SendSubCommentBlock {
    border-top: 1px solid $colorNeutral080;
    padding: 24px 24px 12px 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .SendSubCommentText {
      margin-bottom: 6px;

      textarea {
        border: 1px solid $colorNeutral080;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 8px 16px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.01em;
        min-height: 40px;
      }
    }

    .SendSubCommentButton {
      margin-left: 16px;
			cursor: pointer;
    }
  }

  .CommentShowAllButton {
    font-style: normal;
    font-weight: normal;
    font-size: $fontSizeBody;
    line-height: $lineHeightBody;
    display: block;
    text-align: left;
    color: $colorBlue500;
    padding-left: 56px;
    margin-top: 8px;

    span {
      cursor: pointer;
    }
  }

  .CommentShowAllChevron {
    display: inline-block;
    vertical-align: top;
    @include svgIcon(16px,16px,$colorBlue500);
    padding-left: 4px;
    cursor: pointer;
    position: relative;
    top: 2px;
  }
}

.CommentHead {
  position: relative;
  display: flex;
  margin-bottom: 8px;
}
