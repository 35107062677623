@import "../../../../../basics-scss/mixins";

.Script {
    flex: 1;
    position: relative;

    &.warningInput {
        input {
            border-color: $colorRed300;
        }
    }
}

.FullScreen {
    position: fixed !important;
    border: none!important;
    border-radius: 0!important;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: $zIndexModal;
}

.Freeze {
    overflow: hidden;
}

.FSButton {
    position: absolute;
    top: 16px;
    right: 24px;
    z-index: $zIndexLow;

    &.fixed {
        position: fixed;
        z-index: $zIndexModal;
    }
}