@import "../../../basics-scss/mixins";

.Digit {
    width: 360px;
    height: 360px;
    margin: 8px auto 0;
    border-radius: 50%;
    font: 600 96px/117px 'Montserrat', 'Helvetica', sans-serif;
    text-align: center;
    background-color: #e31450;
    color: $colorNeutral000;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-print-color-adjust: exact;
    @include transition(font-size);

    &:hover {
        font-size: 100px;
    }
}

.SmallDigit {
    font: 600 40px/48px 'Montserrat', 'Helvetica', sans-serif;

    &:hover {
        font-size: 48px;
    }
}
