@import "../../basics-scss/mixins";

.table {
    box-shadow: $boxShadowCard;
    border-collapse: separate;
    border-left: 1px solid $colorNeutral080;
    border-top: 1px solid $colorNeutral080;
    td {
        border: 1px solid $colorNeutral080;
        border-left: 1px solid $colorNeutral000;
        border-top: 1px solid $colorNeutral000;

        &.red {
            background: #fc0204;
        }
        &.green {
            background: #94d253;
        }
        &.yellow {
            background: #fcc204;
        }
        &.gray {
            background: ghostwhite;
        }
        &:hover {
            .hint {
                opacity: 1;
            }
        }
    }
}

.tBody {
    td {
        text-align: left;
        vertical-align: top;
        cursor: pointer;
    }
}

.tHead {
    td {
        text-align: center;
        vertical-align: middle;
        padding: 5px;
    }
}

.hint {
    transition: opacity 200ms;
    opacity: 0;
}

.placeholder {
    padding: 5px;
    min-width: 92px;
    min-height: 65px;
    box-sizing: border-box;
}

.titleCol {
    padding: 5px;
    min-width: 130px;
    text-align: center;
    box-sizing: border-box;
}

.diagonalCol {
    position: relative;
    overflow: hidden;
    border-left: none!important;
    border-top: none!important;
    padding: 5px 10px!important;
    .line {
        position: absolute;
        left: 0;
        top: 0;
    }
    .content {
        position: relative;
        div {
            text-align: left;
            &:first-child {
                text-align: right;
            }
        }
    }
}

.legendsBlock {
    flex: 0 0 200px;
    margin-left: 15px;
}

.legend {
    text-align: center;
    padding: 12px 0;
    line-height: 1;
    color: $colorNeutral000;
    border: 1px solid $colorNeutral080;
    box-shadow: $boxShadowCard;
    font-size: 17px;
    margin-bottom: 15px;
    &.red {
        background: #fc0204;
    }
    &.green {
        background: #94d253;
    }
    &.yellow {
        background: #fcc204;
    }
    &.gray {
        background: lightgrey;
    }
}
.main {
    display: flex;
}
