@import "../../../basics-scss/mixins";

.Menu {
  background: $colorNeutral000;
  box-shadow: $boxShadowCard;
  border-radius: 4px;
  min-width: 224px;
  padding: 8px 0;
  max-width: 464px;

  &.Active {
    display: block;
  }

  &.widthSmall {
    width: 244px;
  }

  &.widthMiddle {
    width: 344px;
  }

  &.widthDefault {
    width: 464px;
  }
}

.Section {
  border-top: 1px solid $colorNeutral080;
  padding: 8px 0;

  &:first-child {
    border-top: 0;
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

.Element {
  padding: 6px 16px;

  line-height: $lineHeightBody;
  cursor: pointer;

  &.Active {
    background-color: $colorBlue010;
  }

  &:hover {
    text-decoration: none;
    background-color: $colorNeutral070;
  }

  &:active {
    background-color: $colorBlue010;
  }

  .Arrow {
    @include svgIcon(16px, 16px);
    margin-left: auto;
    align-self: flex-start;
    position: relative;
    top: 3px;
  }
}

.ElementDescription {
  display: flex;
  align-items: center;
  width: 100%;
}

.ElementBlockDecoration {
  display: flex;
  align-items: center;
  align-self: baseline;
}

.ElementTitle {
  font-size: $fontSizeBody;
  color: $colorNeutral800;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.Decoration {
  margin-left: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: $fontSizeTitle;
  line-height: $lineHeightTitle;
  text-align: center;
}

.InlineBlock {
  display: inline-block;
  vertical-align: top;
}
