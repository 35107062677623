/*Внутренние классы редактора*/

.other h2 {
    font-size: 18px;
    color: #444;
    margin-bottom: 7px;
}

.other a {
    color: #777;
    text-decoration: underline;
    font-size: 14px;
}

.other ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

h1 {
    font-size: 24px;
    color: #333;
}

.ltr {
    text-align: left;
}

.rtl {
    text-align: right;
}

.editor-text-bold {
    font-weight: bold;
}

.editor-text-italic {
    font-style: italic;
}

.editor-text-underline {
    text-decoration: underline;
}

.editor-text-strikethrough {
    text-decoration: line-through;
}

.editor-text-underlineStrikethrough {
    text-decoration: underline line-through;
}

.editor-text-code {
    background-color: rgb(240, 242, 245);
    padding: 1px 0.25rem;
    font-family: Menlo, Consolas, Monaco, monospace;
    font-size: 94%;
}

.editor-link {
    color: #0068B3;
    text-decoration: underline;
}

.tree-view-output {
    display: block;
    background: #222;
    color: #fff;
    padding: 5px;
    font-size: 12px;
    white-space: pre-wrap;
    max-height: 250px;
    position: relative;
    border-radius: 4px;
    overflow: auto;
    line-height: 14px;
}

.editor-code {
    background-color: rgb(240, 242, 245);
    font-family: Menlo, Consolas, Monaco, monospace;
    display: block;
    padding: 8px 8px 8px 52px;
    line-height: 1.53;
    font-size: 13px;
    margin: 8px 0;
    tab-size: 2;
    overflow-x: auto;
    position: relative;
}

.editor-code:before {
    content: attr(data-gutter);
    position: absolute;
    background-color: #eee;
    left: 0;
    top: 0;
    border-right: 1px solid #ccc;
    padding: 8px;
    color: #777;
    white-space: pre-wrap;
    text-align: right;
    min-width: 25px;
}
.editor-code:after {
    content: attr(data-highlight-language);
    top: 0;
    right: 3px;
    padding: 3px;
    font-size: 10px;
    text-transform: uppercase;
    position: absolute;
    color: rgba(0, 0, 0, 0.5);
}

.editor-tokenComment {
    color: slategray;
}

.editor-tokenPunctuation {
    color: #999;
}

.editor-tokenProperty {
    color: #905;
}

.editor-tokenSelector {
    color: #690;
}

.editor-tokenOperator {
    color: #9a6e3a;
}

.editor-tokenAttr {
    color: #07a;
}

.editor-tokenVariable {
    color: #e90;
}

.editor-tokenFunction {
    color: #dd4a68;
}

.editor-paragraph {
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 8px;
    position: relative;
}

.editor-paragraph:last-child {
    margin-bottom: 0;
}

.editor-heading-h1 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    color: #2E3238;
    padding: 24px 0 0 0;
    margin: 0 0 16px 0;
}

.editor-heading-h2 {
    font-family: 'Open Sans', serif;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    color: #2E3238;
    padding: 12px 0 0 0;
    margin: 0 0 8px 0;
}

.editor-quote {
    margin: 0 0 8px;
    font-size: 15px;
    border-left-color: rgb(206, 208, 212);
    border-left-width: 4px;
    border-left-style: solid;
    padding-left: 8px;
}

.editor-list-ol {
    padding: 0;
    margin: 0 0 8px 24px;
    list-style: auto;
}

.editor-list-ul {
    padding: 0;
    margin: 0 0 8px 24px;
    list-style: disc;
}

.editor-listitem {
    margin: 0;
}

.editor-nested-listitem {
    list-style-type: none;
}

pre::-webkit-scrollbar {
    background: transparent;
    width: 10px;
}

pre::-webkit-scrollbar-thumb {
    background: #999;
}


#block-controls button:hover {
    background-color: #efefef;
}

#block-controls button:focus-visible {
    border-color: blue;
}

#block-controls span.block-type {
    background-size: contain;
    display: block;
    width: 18px;
    height: 18px;
    margin: 2px;
}

span.editor-image {
    cursor: default;
    display: inline-flex;
    position: relative;
    user-select: none;
}

.editor-image img {
    max-width: 100%;
}