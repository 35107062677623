@import "../../assets/fonts/Montserrat";
@import "../../assets/fonts/OpenSans";

$fontFamilyMain: 'Open Sans', sans-serif;
$fontFamilyHeadline: 'Montserrat', sans-serif;

$fontSizeBody: 14px;
$lineHeightBody: 20px;

$fontSizeCaption: 12px;
$lineHeightCaption: 16px;

$fontSizeLabel: 14px;
$lineHeightLabel: 20px;

$fontSizeTitle: 14px;
$lineHeightTitle: 20px;

$fontSizeH1: 32px;
$lineHeightH1: 40px;

$fontSizeH2: 24px;
$lineHeightH2: 32px;

$fontSizeH3: 20px;
$lineHeightH3: 24px;

$fontSizeH4: 16px;
$lineHeightH4: 20px;

$fontSizeInput: 14px;
$fontSizeButton: 14px;
