@import "../../../basics-scss/mixins";

.RecentWorkflows{
  display: flex;
  padding: 52px 0 0 0;
  flex-flow: column nowrap;
  align-items: flex-start;
}

.RecentWorkflowsText{
  padding: 16px 0;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  color: $colorNeutral800;
}

.RecentWorkflowsList{
  display: flex;
  padding: 8px 0px 0px 0px;
  flex-flow: row nowrap;
}

.RecentWorkflow{
  padding: 4px 24px 0px 0px;
}

.RecentWorkflowImage{
  margin: 4px 0 8px 0;
  border: 1px solid $colorNeutral080;
  box-sizing: border-box;
  border-radius: 4px;
  width: 112px;
  height: 112px;
  background: $colorNeutral000 url("/assets/img/workflow/workflow.jpg") no-repeat center center;
  cursor: pointer;
  user-select: none;
  &:hover{
    border: 1px solid #5927D3;
  }
}

.RecentWorkflowName{
  margin: 4px 0 8px 0;
  font-size: 12px;
  line-height: 16px;
  align-items: center;
  color: $colorNeutral800;
  cursor: pointer;
  user-select: none;
  width: 112px;
  height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.RecentWorkflowDate{
  margin: 4px 0 8px 0;
  font-size: 12px;
  line-height: 16px;
  color: #6E728F;
  cursor: pointer;
  user-select: none;
}