@import "../../basics-scss/mixins";

.OverlayLoader {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: $colorOverlay;
}

.Loader {
    position: absolute;
    left: 50%;
    top: 50%;
    animation: LoaderFadeIn .1s ease-in;
    margin: -32px 0 0 -32px;
    width: 64px;
    height: 64px;
    background: url("/assets/img/page-loader.gif") no-repeat 0 0;
    background-size: 100% 100%;
}

.LoaderHide {
    display: none;
}

.Overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: $colorOverlay;
}

.Wrapper {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
}

.Window {
    position: relative;
    min-width: 512px;
    border-radius: 4px;
    box-shadow: $boxShadowModal;
    background-color: $colorNeutral000;
    display: inline-block;
    vertical-align: top;
    text-align: left;
    @include animateMoveIn($transDurationPopup);

    @include forMedia(sm) {
        min-width: 100%;
    }
}

.AlignContainer {
    display: table;
    width: 100%;
    height: 100%;
    position: relative;
}

.AlignContent {
    display: table-cell;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    text-align: center;
    padding: 16px;
}

.Header {
    padding: 24px 24px;

    @include forMedia(sm) {
        padding: 16px 16px;
    }
}

.HeaderContent {
    position: relative;
    min-height: 32px;
    padding-right: 32px;
}

.Body {
    padding: 0 24px 24px;

    @include forMedia(sm) {
        padding: 0 16px 16px;
    }
}

.ModalTitle {
    @include forMedia(sm) {
        padding-top: 4px;
    }
}

.CloseIco {
    position: absolute;
    right: 0;
    top: 0;
}

.FullScreenMobile {
    @include forMedia(sm) {
        .Window {
            position: absolute !important;
            left: 0 !important;
            right: 0 !important;
            bottom: 0 !important;
            top: 0 !important;
            border-radius: 0 !important;
            box-shadow: none !important;
            overflow: auto !important;
            width: 100% !important;
            height: 100% !important;
        }

        .Header {
            padding: 16px;
            border-bottom: 1px solid $colorNeutral080;
        }

        .HeaderContent {
            min-height: 32px;
            padding-right: 32px;
        }

        .CloseIco {

        }

        .Body {
            padding: 16px;
        }

        .ModalTitle {
            font-weight: 600;
            font-size: $fontSizeTitle;
            line-height: $lineHeightTitle;
            font-family: $fontFamilyMain;
            color: $colorNeutral600;
            text-transform: uppercase;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-top: 6px;
        }
    }
}
