@import "../../basics-scss/mixins";

.MainContainer {
    display: flex;
    flex-direction: row;
    position: relative;

    &.AdditionalEditor {
        // Хак для того чтоб сделать полный экран на форме (убрать паддинги)
        width: calc(100% + 32px);
        margin-left: -16px;
        height: 100%;

        @include forMedia(sm) {
            width: 100%;
            margin: 0;
        }

        .EditorInner {
            border-left: inherit;
            border-bottom-left-radius: initial;
            border-bottom-right-radius: initial;
            height: calc(100% - 40px);

            @include forMedia(sm) {
                border: 1px solid $colorNeutral080;
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }
    }


    &.FullSize {
        @include animateFadeIn($transDurationPopup);
        z-index: $zIndexModal;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        width: 100vw;
        height: 100vh;
        background: $colorNeutral000;

        .EditorInner {
            border-left: inherit;
            border-bottom-left-radius: initial;
            border-bottom-right-radius: initial;
            max-height: initial;
            height: calc(100% - 24px);

            @include forMedia(sm) {
                border: 1px solid $colorNeutral080;
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }

        @include forMedia(sm) {
           padding: 16px;
        }
    }
}

.EditorContainer {
    width: 100%;
    color: $colorNeutral800;
    position: relative;
    line-height: 20px;
    font-weight: 400;
    text-align: left;

    .Placeholder {
        color: $colorNeutral090;
        position: absolute;
        top: 8px;
        left: 8px;
        font-size: $fontSizeInput;
        user-select: none;
        display: inline-block;
        pointer-events: none;
    }

    &:hover {
        .EditorInner {
            background: #F0F2F4;
        }
    }

    .EditorInner {
        position: relative;
        background: $colorNeutral010;
        border: 1px solid $colorNeutral080;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        max-height: 582px;
        min-height: 62px;
        overflow: auto;
        transition: background-color .1s ease-out,border-color .1s ease-out;
        @include customScrollbar;

        &:focus-within {
            border-color: $colorBlue150;
            box-shadow: inset 0 0 1px 1px $colorBlue150;
            background: $colorNeutral000;
        }

        @include forMedia(sm) {
            max-height: 282px;
        }
    }

    .EditorInput {
        min-height: 58px;
        height: 100%;
        font-size: 15px;
        position: relative;
        tab-size: 1;
        outline: 0;
        padding: 8px 8px 14px;
        caret-color: #444;
    }
}

.DisabledAdditionalEditor {
    .EditorInner {
        background: $colorNeutral040 !important;
    }

    &:hover {
        background: $colorNeutral040;
    }
}

.DisabledEditor {
    border: none;

    .EditorInner {
        border-radius: 4px;
        background: $colorNeutral040 !important;
    }

    &:hover {
        background: $colorNeutral040;
    }
}