@import "../../../basics-scss/mixins";

.Field {
    width: 100%;
    height: 32px;
    padding: 5px 8px 0;
    font-size: $fontSizeInput;
    color: $colorNeutral800;
    background-color: $colorNeutral010;
    background-image: none;
    border: 1px solid $colorNeutral080;
    border-radius: 4px;
    display: block;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &:hover {
        background-color: $colorNeutral060;
    }

    &.focus {
        background-color: $colorNeutral000;
        border-color: $colorBlue150;
        box-shadow: inset 0 0 1px 1px $colorBlue150;
    }

    &[disabled],
    &[readonly] {
        @include readonlyInputStyles;
    }

    &:not(:focus):not(.empty) {
        .Item {
            color: $colorNeutral800;
        }
    }

}

.empty {}

.Content {
    white-space: nowrap;
    display: block;
}

.TextRight {
    text-align: right;
}

.TextLeft {
    text-align: left;
}

.Item {
    display: inline-block;
    vertical-align: top;
    color: $colorNeutral090;
    line-height: $lineHeightBody;
    margin-right: 6px;

    &:last-child {
        margin-right: 0;
    }

    &.active {
        color: $colorNeutral800;
    }
}

.Count {
    &.active {
        background: $colorBlue010;
    }
}

.HiddenInput {
    position: absolute;
    width: 20px;
    left: -30px;
}