@import "../../../basics-scss/mixins";

.AllItems {
  font-weight: 600;
  font-size: $fontSizeBody;
  line-height: $lineHeightBody;
  display: flex;
  align-items: center;
  color: $colorNeutral800;

  @include forMedia(sm){
    padding: 0 16px;
  }
}

.List {
  font-size: $fontSizeBody;
  line-height: $lineHeightBody;
  width: 100%;
  text-align: left;
}

.ListWrap {
  overflow: hidden;
  background: $colorNeutral000;
  border-radius: 4px;
  box-shadow: $boxShadowCardTaskboard;

  @include forMedia(xs){
    border-radius: 0;
  }
}

.ListContainer {
  overflow-x: auto;
}

.ListScrollbar {
  z-index: $zIndexFixedPanel;
  bottom: 0;
  visibility: hidden;
  position: fixed;
  overflow-x: auto;

  div {
    height: 1px;
  }

  &.active {
    visibility: visible;
  }
}

.ListRow {
  .ListGradient {
    background: linear-gradient(to bottom, rgba(251, 252, 253, 0) 0%, $colorNeutral000 100%);
  }

  &:nth-child(odd){
    background: $colorNeutral020;

    .ListGradient {
      background: linear-gradient(to bottom, rgba(239, 245, 250, 0) 0%, $colorNeutral020 100%);
    }
  }
}

.ListItem {
  padding: 8px;
  vertical-align: top;

  &:first-child {
    padding-left: 16px;
  }

  &:last-child {
    padding-right: 16px;
  }

  .StateBadge {
    height: 20px;
    line-height: 20px;
    white-space: nowrap;
    display: inline-block;
    vertical-align: top;
    border-radius: 4px;
    padding: 0 8px;
    font-weight: 600;

    &.gray, &.default {
      background: $colorNeutral080;
      color: $colorNeutral800;
    }

    &.orange {
      background: $colorOrange300;
      color: $colorNeutral800;
    }

    &.aqua {
      background: $colorTeal300;
      color: $colorNeutral800;
    }

    &.dark {
      background: $colorNeutral800;
      color: $colorNeutral000;
    }

    &.yellow {
      background: $colorOrange100;
      color: $colorNeutral800;
    }

    &.blue {
      background: $colorBlue500;
      color: $colorNeutral000;
    }

    &.purple {
      background: $colorPurple500;
      color: $colorNeutral000;
    }

    &.green {
      background: $colorGreen500;
      color: $colorNeutral000;
    }

    &.red {
      background: $colorRed500;
      color: $colorNeutral000;
    }
  }
}

.NotSet {
  color: $colorNeutral090;
}

.Link {
  &:hover {
    text-decoration: underline;
  }
}

.BadgeEye {
  display: inline-block;
  vertical-align: -3px;
  margin-right: 8px;
  @include svgIcon(16px,16px,$colorNeutral090);
}