@import "../../../basics-scss/mixins";

.Control{
  display: flex;
  flex-flow: column nowrap;
  padding: 4px 0;
}

.Label{
  padding: 4px 4px;
  font-size: 12px;
  color: $colorNeutral800;
  line-height: 18px;
  height: 24px;
}

.StringInput{
  display: flex;
  height: 26px;
  flex-flow: row nowrap;
  padding: 4px 4px;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.Input{
  padding: 0;
  border: 0px;
  background-color: transparent;
  line-height: 18px;
  height: 18px;
  width: 100%;
  color: $colorNeutral800;
  &::placeholder {
    color: #A9ACBE;
  }
}

.InputActive{
  &::placeholder {
    color: $colorNeutral800;
  }
}

.IconX {
  width: 16px;
  height: 16px;
  cursor: pointer;
  @include svgIcon(16px,16px,$colorNeutral800);
}

.Line{
  margin: 3px 0 4px 0;
  width: 100%;
  height: 1px;
  border: 0px;
  background-color: $colorNeutral800;
}


