@import "../../../basics-scss/mixins";

.Preview {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
}

.Content {
    position: absolute;
    top: 112px;
    bottom: 136px;
    right: 136px;
    left: 136px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        transition: opacity .2s ease-in;
        opacity: 0;
    }
    &.loaded {
        img {
            opacity: 1;
        }
    }

    @include forMedia(sm){
        top: 64px;
        bottom: 16px;
        right: 16px;
        left: 16px;
    }
}

.Loader {
    position: absolute;
    left: 50%;
    top: 50%;
    animation: LoaderFadeIn .1s ease-in;
    margin: -32px 0 0 -32px;
    width: 64px;
    height: 64px;
    background: url("/assets/img/page-loader.gif") no-repeat 0 0;
    background-size: 100% 100%;
}

@keyframes LoaderFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.Header {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    padding: 40px;
    display: flex;
    align-items: center;
    white-space: nowrap;

    @include forMedia(sm){
        padding: 16px;
    }
}

.Title {
    color: $colorNeutral000;
    font-size: $fontSizeH3;
    font-family: $fontFamilyHeadline;
    line-height: $lineHeightH3;
    flex: auto;
    height: $lineHeightH3;
    position: relative;

    @include forMedia(sm){
        font-family: $fontFamilyMain;
        font-size: $fontSizeTitle;
    }

    .Icon {
        margin-right: 8px;
    }
}

.TitleContainer {
    position: absolute;
    overflow: hidden;
    text-overflow: ellipsis;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.Buttons {
    .Icon {
        margin-left: 8px;
        width: 32px;
        height: 32px;
        @include svgIcon(20px,20px,$colorNeutral000);
    }
}

.Icon {
    display: inline-flex;
    vertical-align: top;
    align-items: center;
    justify-content: center;
    @include svgIcon(24px,24px,$colorNeutral000);
    cursor: pointer;

    &.noHover {
        cursor: default;
    }
}

.Arrow {
    position: absolute;
    top: 136px;
    bottom: 136px;
    width: 96px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    @include svgIcon(20px,20px,$colorNeutral000);
    &:hover {
        background: $colorOverlay;
    }
    &.right {
        right: 0;
    }
    &.left {
        left: 0;
    }

    @include forMedia(sm){
        top: 64px;
        bottom: 16px;
        width: 60px;
        &:hover {
            background: none;
        }
    }
}