@import "../../../../basics-scss/mixins";

.Text {
    font-size: $fontSizeBody;
    line-height: $lineHeightBody;
    color: $colorNeutral600;
    margin-top: 8px;
    max-height: 40px;
    overflow: hidden;
    word-wrap: break-word;
    text-overflow: ellipsis;
    position: relative;

    @include forMedia(sm){
        max-height: 78px;
    }
}

.Gradient {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 18px;
    background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
}