@import "../../basics-scss/mixins";


.ToggleWrap {
    @include clearFix;

    display: inline-block;
}

.Toggle {
    width: 48px;
    height: 24px;
    border: 2px solid $colorNeutral080;
    background: $colorNeutral080;
    border-radius: 16px;
    position: relative;
    float: left;
    cursor: pointer;
    display: block;
    padding: 0;
    -webkit-appearance: none;
    transition-duration: 300ms;

    &:hover {
        background-color: $colorNeutral090;
        border-color: $colorNeutral090;

        .ToggleIconOff {
            border-color: $colorNeutral080;
        }
    }

    &:focus {
        border-color: $colorBlue150;
    }

    .ToggleIconOn {
        transform: translateX(-26px);
    }

    &.checked {
        background-color: $colorBlue300;
        border-color: $colorBlue300;

        .ToggleButton {
            left: 26px;
        }

        .ToggleIconOff {
            transform: translateX(26px);
        }

        .ToggleIconOn {
            transform: translateX(0);
        }

        &:hover {
            background-color: $colorBlue400;
            border-color: $colorBlue400;
        }

        &:focus {
            border-color: $colorBlue150;
        }
    }

    &.disabled,
    &.disabled:hover,
    &.disabled:focus {
        background-color: $colorNeutral050;
        border-color: $colorNeutral080;
        cursor: default;

        .ToggleButton {
            background-color: $colorNeutral080;
            box-shadow: none;
        }

        .ToggleIconOn {
            background-color: $colorNeutral080;
        }

        .ToggleIconOff {
            border-color: $colorNeutral080;
        }
    }
}

.ToggleText {
    padding-top: 2px;
    margin-left: 56px;
    color: $colorNeutral800;
    font-size: $fontSizeBody;
    line-height: $lineHeightBody;

    &.BoldText {
        font-weight: 600;
    }
}

.ToggleButton {
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background: $colorNeutral000;
    box-shadow: $boxShadowCard;
    position: absolute;
    left: 2px;
    top: 50%;
    margin-top: -8px;
    transition: left .2s;
}

.ToggleIcons {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
}

.ToggleIconOn {
    width: 2px;
    height: 8px;
    background: $colorNeutral000;
    position: absolute;
    border-radius: 4px;
    left: 9px;
    top: 50%;
    margin-top: -4px;
    transition: transform .2s;
}

.ToggleIconOff {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 2px solid $colorNeutral090;
    position: absolute;
    right: 6px;
    top: 50%;
    margin-top: -4px;
    transition: transform .2s;
}