@import "../../basics-scss/mixins";

.Wrapper {
    position: relative;
    height: 32px;
}
.Input {
    position: absolute;
    left: 0;
    width: 100%;
    height: 32px;
    padding: 3px 31px 3px 3px;
    font-size: $fontSizeInput;
    color: $colorNeutral800;
    background: $colorNeutral010 url("/assets/img/select-arrow.svg?inline") no-repeat right 8px center;
    border: 1px solid $colorNeutral080;
    border-radius: 4px;
    text-align: left;
    cursor: default;
    @include transition();

    &:hover {
        background-color: $colorNeutral060;
    }

    &:focus {
        background-color: $colorNeutral000;
        border-color: $colorBlue150;
        box-shadow: inset 0 0 1px 1px $colorBlue150;
    }


    &[disabled], &[readonly] {
        @include readonlyInputStyles;
    }
}
.Option {
    padding: 4px 16px;
    display: flex;
    flex-wrap: nowrap;
    cursor: default;
}
.OptionText {
    color: $colorNeutral800;
    line-height: $lineHeightBody;
    font-size: $fontSizeBody;
    padding: 0 0 0 8px;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.List {
    max-height: 244px;
    overflow-y: auto;
    padding: 4px 0;
    @include customScrollbar;
}
.Checkbox {
    justify-content: center;
    align-items: center;
    cursor: pointer;
    display: flex;
    border-radius: 4px;
    border: 1px solid $colorNeutral080;
    width: 16px;
    height: 16px;
    flex: 0 0 16px;
    line-height: 0;
    background: $colorNeutral010;
    position: relative;
    top: 2px;
    @include svgIcon(10px, 8px, $colorNeutral000);
    @include transition();
    & > * {
        visibility: hidden;
    }
    &:hover {
        background: $colorNeutral060;
    }
    &:active {
        background: $colorNeutral050;
        border-color: $colorNeutral090;
    }
    &.active {
        background: $colorBlue300;
        border-color: $colorBlue500;
        & > * { visibility: visible; }
    }
}
.Filter {
    position: relative;
    input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 32px;
        padding: 0 32px 0 8px;
        font-size: $fontSizeInput;
        color: $colorNeutral800;
        background-color: $colorNeutral010;
        background-image: none;
        border: 1px solid $colorNeutral080;
        border-radius: 4px;
        @include placeholder();
        @include phLineHeight(30px);
        @include transition();

        &:hover {
            background-color: $colorNeutral060;
        }

        &:focus {
            background-color: $colorNeutral000;
            border-color: $colorBlue150;
            box-shadow: inset 0 0 1px 1px $colorBlue150;
        }
    }
}
.Body {
    @include dropDown;
}
.Header {
    border-bottom: 1px solid $colorNeutral080;
    padding: 16px;
}
.Icon {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    @include svgIcon(16px,16px,$colorNeutral090);
    &.hover {
        @include svgIconColor($colorNeutral800);
    }
    &.disabled {
        @include svgIconColor($colorNeutral090);
    }
}
.IconBtn {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    width: 16px;
    height: 16px;
    cursor: pointer;
    &.disabled {
        cursor: default;
    }
}
.NoResults {
    padding: 16px 8px;
    text-align: center;
    color: $colorNeutral600;
    line-height: $lineHeightBody;
    font-size: $fontSizeBody;
}
.SelectAll {
    margin: 16px 0 0;
    display: flex;
    cursor: default;
}
.Badge {
    display: inline-block;
    vertical-align: top;
    height: 20px;
    line-height: 20px;
    background: #eff5fa;
    color: #334d6e;
    padding: 0 4px;
    border-radius: 4px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 4px 4px 0;
    max-width: 100%;
}
.Badges {
    line-height: 0;
    margin-bottom: -4px;
}
.Placeholder {
    display: block;
    height: 30px;
    line-height: 30px;
    font-size: $fontSizeInput;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    top: -3px;
    color: $colorNeutral090;
    padding-left: 4px;
}
