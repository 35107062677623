@import "../../basics-scss/mixins";

.Wrapper {
    display: flex;
    height: 100vh;
    margin-top: -$headerHeight;
    padding-top: $headerHeight;
    background: $colorNeutral000;

    .FieldWrapper {
        margin-bottom: 16px;
    }

    .Label {
        @include formLabel;
        text-align: left;
    }

    .FieldWrapperBoolean {
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
    }

    .LabelBoolean {
        margin: 0 0 0 10px;
        flex: 1;
        color: $colorNeutral800;
        text-align: left;
    }

    .InputBoolean {
        flex: 0;
        width: 20px;
        height: 26px;
    }
}

.DisabledImg {
    filter: grayscale(90);
}

.LoadingViewText {
    margin: auto;
    max-width: 282px;
    width: 282px;
}

.LinkFilter {
    @include linkStyles;
}

.Setting {
    position: fixed;
    bottom: 0;
    top: $headerHeight;
    width: 400px;
    border-right: 1px solid $colorNeutral080;
    overflow: hidden;
    z-index: $zIndexMiddle;
    display: flex;
    flex-direction: column;
    background: $colorNeutral000;
}

.SettingData {
    padding: 32px 24px;
}

.SettingTypes {
    padding: 32px 24px;
}

.SettingConfig {
    padding: 20px 24px;
}

.SettingStyle {
    padding: 0;
}

.SettingFooter {
    flex: 0 0 60px;
    height: 60px;
    border-top: 1px solid $colorNeutral080;
    text-align: center;
}

.Required {
    display: inline-block;
    vertical-align: top;
    color: #f73422;
    margin-right: 5px;
}

.Input,
.InputBoolean {
    input {
        font-size: $fontSizeInput;
        line-height: 32px;
        color: $colorNeutral800;
    }
}

.SettingTabs {
    display: flex;
    flex: 0 0 51px;
    height: 51px;
}

.SettingTabsItem {
    position: relative;
    flex: 1;
    padding: 0 23px;
    border-bottom: 2px solid $colorNeutral080;
    text-align: center;
    cursor: pointer;
    font-size: $fontSizeLabel;
    line-height: 51px;
    color: $colorNeutral090;

    &.SettingTabsEnabled {
        color: $colorNeutral800;
    }

    &.SettingTabsItemActive {
        color: $colorBlue500;
        border-bottom: 2px solid $colorBlue300;
    }
}

.IconChevronRight {
    position: absolute;
    right: -10px;
    top: 16px;
    display: block;
    @include svgIcon(20px, 20px, $colorNeutral090);
}

.SettingBlock {
    overflow: hidden;
    flex: auto;
    position: relative;
}

.SettingBlockContent {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    @include customScrollbar;
}

.SettingFooterButton {
    &.NextButton {
        float: right;
        margin: 13px 35px 0px 4px;
    }

    margin: 13px 4px 0;
    width: 160px;
    height: 32px;
}

.CategoryField {
    margin-bottom: 24px;
}

.CategoryName {
    @include formLabel;
}

.CategoryDescription {
    margin: 0 0 12px 0;
    color: $colorNeutral800;
}

.Types {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .OneType {
        p {
            line-height: 2.5;
        }

        .TypeLoaded {
            &:hover {
                border: solid 1px $colorNeutral090;
            }
        }

        .TypeItemsActive {
            border: solid 1px $colorBlue300;
        }
    }
}

.TypeItems {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 166px;
    height: 90px;
    margin: 0 0 10px 0;
    border-radius: 4px;
    border: solid 1px $colorNeutral080;
    text-align: center;
}

.Form {
    margin: 12px 0 0 0;
}

.View {
    flex: 1;
    padding: 0 0 0 400px;
    width: 100%;
}

.hide {
    position: absolute;
    visibility: hidden;
    overflow: hidden;
    width: 0;
    height: 0;
}

.StylesBlock {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    border-bottom: 1px solid $colorNeutral080;
}

.StylesTab {
    position: relative;
    border-top: 1px solid $colorNeutral080;
    padding: 0 24px 0;
    width: 400px;
    height: 46px;
    line-height: 46px;
    color: $colorNeutral800;
    cursor: pointer;

    &:first-child {
        border-top: none;
    }
}

.StylesActiveTab {
    @extend .StylesTab;

    .StylesIconChevronRight {
        transform: rotate(90deg);
    }
}

.StylesIconChevronRight {
    position: absolute;
    right: 20px;
    top: 17px;
    @include svgIcon(11px, 11px, $colorNeutral800);
}

.StylesForm {
    border-top: 1px solid $colorNeutral080;
    padding: 24px 21px 10px;
    width: 100%;
}

.ReportView {
    padding: 29px 24px 0 24px;
}

.Button {
    margin: 0 10px 0 0;
}

.ViewHeader {
    display: flex;
}

.ViewTitle {
    margin: 0 5px;
    font-family: $fontFamilyHeadline;
    font-size: 22px;
    line-height: 32px;
    color: $colorNeutral800;
}

.ViewTableName {
    width: 240px;
    color: #a9acbe;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 8px 0 22px 0;
}

.ViewHeaderRightSide {
    margin: 0 15px 0 auto;
}

.Report {
    margin: 96px -24px 0;
    padding: 0 24px 0;
    height: calc(100vh - 233px);
}

.FixedHeader {
    position: fixed;
    top: $headerHeight;
    right: 0;
    left: 456px;
    height: 100px;
    padding: 29px 0 0 20px;
    background: $colorNeutral000;
    z-index: $zIndexMiddle;

    &.pinnedAside {
        left: 736px;
    }
}
